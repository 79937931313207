import Vue from 'vue'
import VueRouter from 'vue-router'
import index from '../views/404'
import TabHomePage from '../components/homePage/TabHomePage'

Vue.use(VueRouter)

const routes = [
  {
    path: '/',
    name: 'TabHomePage',
    component: TabHomePage,
    // redirect: '/mainHomePage',
    children: [
      {
        path: '/mainHomePage',
        name: 'mainHomePage',
        component:  () => import('@/views/main/mainHomePage'),
        meta: {title: '首页',}
      },
      {
        path: '/ductTPM',
        name: 'ductTPM',
        component:  () => import('@/views/duct/ductTPM'),
        meta: {title: 'TPM营销管理平台',}
      },
      {
        path: '/ductDMS',
        name: 'ductDMS',
        component:  () => import('@/views/duct/ductDMS'),
        meta: {title: 'DMS经销商管理',}
      },
      {
        path: '/ductSFA',
        name: 'ductSFA',
        component:  () => import('@/views/duct/ductSFA'),
        meta: {title: 'SFA移动访销系统',}
      },
      {
        path: '/ductDIS',
        name: 'ductDIS',
        component:  () => import('@/views/duct/ductDIS'),
        meta: {title: 'DIS',}
      },
      {
        path: '/ductKMS',
        name: 'ductKMS',
        component:  () => import('@/views/duct/ductKMS'),
        meta: {title: 'KMS',}
      },
      {
        path: '/ductDistribution',
        name: 'ductDistribution',
        component:  () => import('@/views/duct/ductDistribution'),
        meta: {title: '齐道分销系统',}
      },
      {
        path: '/customer',
        name: 'customer',
        component:  () => import('@/views/cust'),
        meta: {title: '客户',}
      },
      //  解决方案
      {
        path: '/Alcohol',
        name: 'Alcohol',
        component:  () => import('@/views/solve/Alcohol'),
        meta: {title: '酒类解决方案',}
      },
      {
        path: '/Milk',
        name: 'Milk',
        component:  () => import('@/views/solve/Milk'),
        meta: {title: '乳类解决方案',}
      },
      {
        path: '/Food',
        name: 'Food',
        component:  () => import('@/views/solve/Food'),
        meta: {title: '食品解决方案',}
      },
      {
        path: '/aboutUs',
        name: 'customer',
        component:  () => import('@/views/aboutUs'),
        meta: {title: '关于我们',}
      },
      {
        path: '/JoinUs',
        name: 'JoinUs',
        component:  () => import('@/views/JoinUs'),
        meta: {title: '加入我们',}
      },
      {
        path: '/detailsWork',
        name: 'detailsWork',
        component:  () => import('@/views/JoinUs/detailsWork'),
        meta: {title: '职位详情',}
      }
    ]
  },
  // {
  //   path: '/about',
  //   name: 'about',
  //   // route level code-splitting
  //   // this generates a separate chunk (about.[hash].js) for this route
  //   // which is lazy-loaded when the route is visited.
  //   component: () => import(/* webpackChunkName: "about" */ '../views/AboutView.vue')
  // }
]

const router = new VueRouter({
  mode: 'history',
  base: process.env.BASE_URL,
  routes
})

export default router
