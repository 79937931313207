import Vue from 'vue'
import App from './App.vue'
import './registerServiceWorker'
import router from './router'
import store from './store'
import '../public/reset.css'
import '../src/assets/font/fontFamily.css';
import 'muse-ui/lib/styles/base.less';
import Helpers from 'muse-ui/lib/Helpers';
// fade/zoom 等
import 'element-ui/lib/theme-chalk/base.css';
// collapse 展开折叠
import CollapseTransition from 'element-ui/lib/transitions/collapse-transition';



import {
  Button,
  Carousel,
  BottomNav,
} from 'muse-ui';

import {
  Menu,
  Submenu,
  MenuItem,
  MenuItemGroup,
  Tabs,
  TabPane,
} from 'element-ui';
import 'muse-ui/lib/styles/theme.less';
Vue.use(Button);
Vue.use(Carousel);
Vue.use(BottomNav);
Vue.use(Helpers);
Vue.use(Tabs);
Vue.use(TabPane);
Vue.use(Menu);
Vue.use(Submenu);
Vue.use(MenuItem);
Vue.use(MenuItemGroup);
Vue.config.productionTip = false
Vue.component(CollapseTransition.name, CollapseTransition)

new Vue({
  el: '#app',
  router,
  store,
  render: h => h(App)
}).$mount('#app')

//路由跳转回到顶部
router.afterEach((to,from,next) => {
  window.scrollTo(0,0);
});
