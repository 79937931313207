<template>
  <div class="wrapper">
    <div class="banner">
      <div class="w1240" style="color: #fff">
        <h1 style="margin: 0">齐道分销系统</h1>
        <p style="line-height: 1.75rem">粉丝变渠道，人人帮卖货 </p>
        <p style="line-height: 1.75rem"> 帮助品牌、供货商、网红自媒体搭建分销渠道裂变分销括销路</p>
        <div class="btn">即刻体验</div>
      </div>
    </div>
    <div style="margin: 30px 0">
      <h2 style="text-align: center;font-size: 20px;font-weight: bold;font-family: YuYangT3">分销模式，简单高效起量快</h2>
      <p style="text-align: center;font-size: 12px;">
        齐道分销系统打造独特的“微商城+三级分销”多层级一体化分销交易平台，支持无限级代理、多级分销、自动结算，搭配多种营销模式，适合全场景分销</p>
      <div class="w1240"
           style="display: flex;flex-direction: column;justify-content: center;align-items: center;padding: 40px">
        <div style="position: relative">
          <div class="butionMode-box">分销商</div>
          <div style="position: absolute;transform: rotateZ(20deg);top: 60px;right: -190px">
          <div class="arrow" style="transform: rotatez(180deg)">
            <p style="transform: rotatez(180deg)">注册成为分销员</p>
          </div>
          </div>
          <div style="position: absolute;transform: rotateZ(20deg);top: 88px;right: -190px">
            <div class="arrow">
              <p>推广商品 招募下线</p>
            </div>
          </div>
        </div>
        <div style="display: flex;gap: 570px">
          <div style="position: relative">
            <div class="butionMode-box">厂家/品牌</div>
            <div style="position: absolute;transform: rotateZ(160deg);top: 86px;left: 480px">
              <div class="arrow" style="transform: rotatez(180deg)">
                <p style="position: relative;top: -26px">分销成果</p>
              </div>
            </div>
            <div style="position: absolute;transform: rotateZ(160deg);top: 116px;left: 490px">
              <div class="arrow">
                <p style="position: relative;top: -26px;transform: rotatez(180deg)">购买商品成为分销员</p>
              </div>
            </div>
          </div>
          <div style="position: relative">
            <div class="butionMode-box">员工、导购 粉丝、客户</div>
            <div style="position: absolute;transform: rotateZ(160deg);top: -34px;left: -525px">
              <div class="arrow" style="transform: rotatez(180deg)">
                <p style="position: relative;top: -40px;line-height: 1rem">入住分销市场/开通分销员功能 招募分销商</p>
              </div>
            </div>
            <div style="position: absolute;transform: rotateZ(160deg);top: 0px;left: -525px">
              <div class="arrow">
                <p style="position: relative;top: -26px;transform: rotatez(180deg)">获得分销资格，选货上架</p>
              </div>
            </div>
          </div>
        </div>
        <div style="position: relative">
          <div class="butionMode-box">消费者</div>
          <div style="position: absolute;transform: rotateZ(195deg);top: 44px;left:-194px">
            <div class="arrow" style="transform: rotatez(180deg)">
              <p>统一管理、代发货、售后服务</p>
            </div>
          </div>
          <div style="position: absolute;transform: rotateZ(195deg);top: 14px;left:-194px">
            <div class="arrow">
              <p style="transform: rotatez(180deg)">浏览、关注、购买、转发分享</p>
            </div>
          </div>
        </div>
      </div>

      <!--    <div class="arrow" style="transform: rotatez(180deg)"></div>-->

    </div>
    <div style="padding: 30px 0;background-color: #F3F6FB">
      <h2 style="text-align: center;font-size: 20px;font-weight: bold;font-family: YuYangT3">多种分销模式组合，贴合不同模式需求</h2>
      <div class="w1240" style="display: flex;justify-content: center;gap: 66px;margin-top: 10px">
        <div v-for="(item,index) in butionMode" :key="index" class="butionModeList-box">
          <div class="butionMode-title-box">
            <div class="butionMode-title">{{item.title}}</div>
            <div class="butionMode-title-p"><p v-for="(item,index) in item.titleList">{{item}}</p></div>
          </div>
          <div class="butionMode-text-box">
            <div class="text-title">{{item.textTitle}}</div>
            <p>{{item.text}}</p>
          </div>
        </div>
      </div>
    </div>
    <div style="padding: 30px 0">
      <h2 style="text-align: center;font-size: 20px;font-weight: bold;font-family: YuYangT3">无缝连接商家、代理、网红和消费者裂变式分销，流量高效变现</h2>
      <p style="text-align: center">一键上架、全网分销，销量蹭蹭上涨</p>
      <div style="display: flex;justify-content: center;gap: 24px;margin-top: 10px">
        <div v-for="(item,index) in seamless" :key="index" class="seamless-box">
          <div class="seamless-title-box">
            <img :src="item.icon">
            <p>{{item.title}}</p>
          </div>
          <div class="seamless-text-box">
            <p v-for="(item,index) in item.list">{{item}}</p>
          </div>
        </div>
      </div>
    </div>
    <div style="padding: 30px 0;background-color: #F3F6FB">
      <h2 style="text-align: center;font-size: 20px;font-weight: bold;font-family: YuYangT3">齐道分销，更好用的分销系统</h2>
      <div style="display: flex;justify-content: center;gap: 100px;margin-top: 20px">
        <div v-for="(item,index) in butionSystem" :key="index">
          <div>
            <img :src="item.icon" style="margin-bottom: 10px">
            <div>
              <p style="font-weight: bold" v-for="(item,index) in item.list" :key="index">{{item}}</p>
            </div>
          </div>
        </div>
      </div>
    </div>
    <div style="background-color:#F3F6FB ">
      <div class="button-wrapper">
        <mu-button large color="primary">即刻体验</mu-button>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  name: "ductDistribution",
  data() {
    return {
      butionMode:[
        {
          title:'分销员',
          titleList:['二级裂变','佣金排行','海报推广'],
          textTitle:"粉丝帮你分销",
          text:'招募粉丝，不断裂变，通过佣金激励粉丝将你的商品分享到微信群、朋友圈，实现社群传播，产生订单。'
        },
        {
          title:'员工分销',
          titleList:['员工管理','业绩考核','激励体系'],
          textTitle:"员工帮你分销",
          text:'为企业（加盟连锁）量身定制的分销售解决方案，发动企业员工、代理商、高级分销员来分销。'
        },
      ],
      seamless:[
        {
          icon:require('../../../../public/image/gonghuo.svg'),
          title:'供货商',
          list:['帮你对接百万代理和网红','全网分销，自动结算','价格统一，不怕乱价','合同模版，账期提醒']
        },
        {
          icon:require('../../../../public/image/daili.svg'),
          title:'代理商',
          list:['帮你对接品牌厂家','源头好货，全网底价','一件代发，无痕分销']
        },
        {
          icon:require('../../../../public/image/kol.svg'),
          title:'网红/KOL',
          list:['帮你对接品牌商家','一键上架，全网卖货','不怕比价，售后无优']
        },
        {
          icon:require('../../../../public/image/pinpai.svg'),
          title:'品牌方',
          list:['帮你在全网推广商品',' 帮你搭建分销体系','粉丝员工导购，全员分销']
        },
      ],
      butionSystem:[
        {
          icon:require('../../../../public/image/daifa.svg'),
          list:['一键代发','无需囤货'],
        },
        {
          icon:require('../../../../public/image/wuhen.svg'),
          list:['无痕分销','隐匿来源'],
        },
        {
          icon:require('../../../../public/image/zidong_.svg'),
          list:['自动分账','售后无忧'],
        },
        {
          icon:require('../../../../public/image/duoyang.svg'),
          list:['多样营销','灵活运营'],
        },
        {
          icon:require('../../../../public/image/chixu.svg'),
          list:['持续供货','海量优质'],
        },
        {
          icon:require('../../../../public/image/jiage.svg'),
          list:['价格统一','不怕乱价'],
        },
      ]
    }
  }
}
</script>

<style scoped lang="less">
.wrapper{
  background-color: #fff;
  width: 100%;
  margin: 0 auto;
}
.banner {
  width: 100%;
  height: 360px;
  background-image: linear-gradient(145deg, #45a1f5 20%, #2196f3 80%);
  padding-top: 80px;
  box-sizing: border-box;
}

.btn {
  width: 146px;
  height: 44px;
  border-radius: 4px;
  border: 1px solid #fff;
  cursor: pointer;
  text-align: center;
  line-height: 44px;
  color: #fff;
  font-size: 22px;
  margin-top: 20px;
}

//箭头
arrow-box {
  position: absolute;
  z-index: 99999;
}

.arrow {
  width: 200px;
  height: 2px;
  background-color: #0D36A8;
  position: relative;
  left: 30px;

  p {
    line-height: 1.75rem;
    text-align: center;
  }
}

.arrow::before {
  content: '';
  display: block;
  position: absolute;
  border-top: 10px solid transparent;
  border-right: 10px solid #0D36A8;
  border-bottom: 10px solid transparent;
  border-left: 10px solid transparent;
  left: -20px;
  top: -8px;
}

//分销模式
.butionMode-box {
  width: 100px;
  height: 100px;
  background: #0d36a8;
  font-weight: 700;
  text-align: left;
  color: #ffffff;
  line-height: 20px;
  border-radius: 50%;
  display: flex;
  align-items: center;
  justify-content: center;
  position: relative;
  box-sizing: border-box;
  padding: 15px;
}

.arrow-top:nth-child(1) {
  top: 0;
}
//分销模式组合
.butionModeList-box{
  width: 300px;
  height: 240px;
  border-radius: 4px;
  background-color: #fff;
  .butionMode-title-box{
    width: 100%;
    height: 90px;
    background-color: #0D36A8;
    color: #fff;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    box-sizing: border-box;
    border-radius: 4px 4px 0 0;
    .butionMode-title{
      font-size: 18px;
    }
    .butionMode-title-p{
      display: flex;
      gap: 22px;
      p{
        font-size: 12px;
        padding: 2px 11px;
        border: 1px solid #fff;
        border-radius: 120px;
      }
    }
  }
  .butionMode-text-box{
    box-sizing: border-box;
    padding: 25px;
    .text-title{
      text-align: center;
      margin-bottom: 10px;
    }
  }
}
//无缝连接
.seamless-box{
  width: 240px;
  height: 240px;
  box-sizing: border-box;
  border-radius: 4px;
  .seamless-title-box{
    width: 100%;
    height: 120px;
    background-color: #0D36A8;
    display: flex;
    align-items: center;
    justify-content: center;
    flex-direction: column;
    gap: 10px;
    border-radius: 4px 4px 0 0;
    p{
      font-size: 18px;
      color: #fff;
    }
  }
  .seamless-text-box{
    display: flex;
    flex-direction: column;
    box-sizing: border-box;
    border: 1px solid #e5e5e5;
    border-top: none;
    height: 120px;
    padding: 0px 40px;
    justify-content: center;
    gap: 4px;
  }
}
</style>
