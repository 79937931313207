<template>
  <div class="flex" style="flex-wrap: wrap;column-gap: 344px;row-gap: 40px;justify-content: center;padding-top: 10px">
    <div class="wrapper-box" v-for="(item,index) in dataList" :key="index">
      <div class="wrapper-lin" style="font-size: 16px;font-weight: 700">{{ item.lin }}</div>
      <p class="wrapper-p" style="text-align: justify;font-size: 14px">{{ item.p }}</p>
      <div class="wrapper-title">{{item.title}}</div>
    </div>
  </div>
</template>

<script>
export default {
  name: "handleKMS",
  props: {
    dataList: {
      type: Array,
    },
  },
  data() {
    return {}
  }
}
</script>

<style scoped lang="less">
.wrapper-box{
  width: 320px;
  position: relative;
  padding-top: 20px;
  .wrapper-title{
    position: absolute;
    width: 96px;
    height: 96px;
    box-sizing: border-box;
    border-radius: 50%;
    background: #0d36a8;
    display: flex;
    align-items: center;
    justify-content: center;
    font-size: 18px;
    color: #fff;
    font-weight: bold;
  }
}
.wrapper-box:nth-of-type(2n-1) .wrapper-lin{
  text-align: right;
}
.wrapper-box:nth-of-type(2n-1) .wrapper-title{
  top: 0px;
  right: -136px;
}
.wrapper-box:nth-of-type(2n) .wrapper-title{
  top: 0px;
  left: -136px;
}
</style>