<template>
  <div class="w100">
    <div class="w1000" style="padding-top: 32px">
      <h2 class="wrapper-h2">{{jobVO.job}}</h2>
      <div class="flex" style="justify-content: space-between">
        <div class="wrapper-work">
          <p>社会招聘</p>
          <p>全职</p>
          <div>
            <img src="../../../../public/image/join/dw.webp">
            <span style="vertical-align: middle;margin-left: 4px">成都</span>
          </div>
        </div>
        <p style="font-size: 16px;color: #666">2022-09-27发布</p>
      </div>
    </div>
    <div class="wrapper-br"></div>
    <div class="w1000">
      <div class="job-title">工作职责:</div>
      <div class="job-text">
        <p v-for="(item,index) in jobVO.dutyList">{{item}}</p>
      </div>
      <div class="job-title">任职要求:</div>
      <div class="job-text">
        <p v-for="(item,index) in jobVO.requirement">{{item}}</p>
      </div>
    </div>
    <div class="button-wrapper">
      <mu-button large color="primary">立即投递</mu-button>
    </div>
  </div>
</template>

<script>
export default {
  name: "index",
  data(){
    return{
      jobVO:{},
      product:{
        job:'产品经理-成都',
        dutyList:[
            '1.负责公司CRM产品升级迭代，独立完成需求分析、功能及流程设计;',
            '2.根据业务需求，按规范独立输出产品相关原型设计、PRD等文档;',
            '3.在项目开发过程中，负责协同技术开发人员，驱动软件研发，跟踪研发进度、验收成果、版本管理、评审发布，并配合部署上线、客户培训等相关工作;',
            '4..上线后跟进各类指标、监控产品数据，进行分析和统计,制定相应的产品优化迭代方案; ',
            '5.跟进快销行业动向，了解行业政策知识，积极寻求行业发展的各种创新思路和产品方案。'
        ],
        requirement:[
            '1.本科及以上学历，具有计算机、设计等相关专业优先;',
            '2.有较强的合作能力，善于表达，责任心强，积极主动，乐观开朗，团队协抗压能力强，能够适应快节奏和多项目的压力;',
            '3.对用户体验、交互设计、用户界面设计有深刻理解;',
            '4.熟练产出PRD等产品文档，熟练使用产品设计类软件,如:墨刀、axure、 蓝湖、sketch、 xmind/ mindmanager、visio;',
            '5.具备优秀的原型设计能力和文档编写能力，清晰明了地表达架构意图;',
            '6.熟悉软件产品升级迭代流程，了解业务调研、需求分析、业务流程设计、UI设计、功能研发、服务测试、发布上线全过程；',
            '7.能接受出差；',
        ]
      }
    }
  },
  created() {
    this.jobVO = this.product
  }
}
</script>

<style scoped lang="less">
@import "detailsWork";
</style>