<template>
  <div>
    <div style="background-color:#F3F6FB;padding: 50px 0">
      <div class="w1200">
        <h1 style="font-size: 28px;color: #0D36A8;margin:0">DMS经销商管理</h1>
        <p style="font-size: 18px;width: 980px">
          齐道CRM通过上下游企业协同能力，实现了品牌商对经销商的数据洞察，同时经销商也可以轻松获取品牌商的产品信息、实时政策，⾃我数字化管理亦可通过齐道CRM平台实现。</p>
        <div class="DMS-lin-title">
          <div>渠道直连</div>
          <div>降成本 提效率</div>
          <div>管理可视化</div>
          <div>赋能价值提升</div>
        </div>
        <div style="display: flex;justify-content: center;margin: 75px">
          <DMSJXSGuanli></DMSJXSGuanli>
        </div>
      </div>
    </div>

    <!--  客户管理-->
    <div class="w100" style="background-color: #fff"><div class="w1200" style="padding: 50px 0">
      <div class="flex-sb" style="gap: 150px;justify-content: space-evenly">
        <div>
          <p style="font-size: 18px;margin-bottom: 20px;font-weight: bold">客户管理</p>
          <p>齐道DMS通过上下游企业协同能力，实现了对客户的精致化管理，</p>
          <div class="kh-title">
            <div>客户分类</div>
            <div>数据清晰</div>
            <div>管理可视化</div>
            <div>赋能价值提升</div>
          </div>
        </div>
        <div class="kh-right-box">
          <p class="kh-right-box-title">DMS客户</p>
          <div class="wil-mei">
            <div>
              <p>可购清单</p>
              <p>销售人员</p>
              <p>客户组织</p>
              <p>客户档案</p>
              <p>发货地点</p>
            </div>
            <div>
              <p>线路管理</p>
              <p>销售组织</p>
              <p>分级分类</p>
              <p>收货地址</p>
              <p>联系信息</p>
            </div>
          </div>
        </div>
      </div>
    </div></div>
    <!--  产品管理-->
    <div style="padding: 50px 0;background-color: #F3F6FB">
      <div class="w1200">
        <div class="flex-sb" style="gap: 80px">
          <div>
            <p style="font-size: 18px;margin-bottom: 20px;font-weight: bold">产品管理</p>
            <p style="font-size: 16px">齐道DMS通过分类与产品分级管理，实现，产品多维度管理。</p>
            <div class="kh-title">
              <div>渠道直连</div>
              <div>降成本 提效率</div>
              <div>管理可视化</div>
              <div>赋能价值提升</div>
            </div>
          </div>
          <div>
            <div v-for="(item,index) in product" :key="index" class="wmp-product">
              <div v-for="(item,index) in item.list" :key="index" class="wmp-product-list">
                <div class="wmp-product-list-box">{{ item }}</div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
    <!--  促销管理-->
    <div class="w100" style="background-color: #fff"><div class="w1200" style="padding: 50px 0">
      <p style="font-size: 18px;margin-bottom: 20px;font-weight: bold;text-align: center">促销管理</p>
      <p style="font-weight: 500;font-size: 16px;text-align: center">
        齐道DMS通过客户的精细化管理与数字化结合，实现了政策灵活搭配。降低促销政策的复杂性，提升业务人员的效率。</p>
      <div class="DMS-lin-title" style="margin-left: 197px">
        <div>坎级</div>
        <div>金额</div>
        <div>总量</div>
        <div>次数</div>
        <div>数量</div>
      </div>
      <div class="flex-sb" style="gap: 21px;margin-top: 40px">
        <div v-for="(item,index) in Promotion" class="cx-list-box">
          <p>{{ item.title }}</p>
          <p>{{ item.p1 }}</p>
          <p>{{ item.p2 }}</p>
          <p>...</p>
        </div>
      </div>
    </div></div>
    <!--  订单管理-->
    <div style="background-color: #F3F6FB;padding: 50px 0">
      <div class="w1200">
        <p style="font-size: 18px;margin-bottom: 20px;font-weight: bold;text-align: center">订单管理</p>
        <div class="DMS-lin-title" style="justify-content: center">
          <div>渠道直连</div>
          <div>降成本 提效率</div>
          <div>管理可视化</div>
          <div>赋能价值提升</div>
        </div>
        <div class="flex-sb">
          <div class="wrapper-order">
            <div class="wrapper-img-order" v-for="(item,index) in order" :key="index">{{ item }}</div>
          </div>
        </div>
      </div>
    </div>
    <!--  订单审核跟踪-->
    <div class="w100" style="background-color: #fff"><div class="1200" style="padding: 50px 0">
      <p style="font-size: 18px;margin-bottom: 20px;font-weight: bold;text-align: center">订单审核跟踪</p>
      <div class="DMS-lin-title" style="justify-content: center">
        <div>坎级</div>
        <div>金额</div>
        <div>总量</div>
        <div>次数</div>
        <div>数量</div>
      </div>
      <div class="flex-sb" style="gap:100px;margin-top: 20px">
        <d-m-s-d-d-gengzong :auditTrail='auditTrail1'></d-m-s-d-d-gengzong>
        <d-m-s-d-d-gengzong :auditTrail='auditTrail2'></d-m-s-d-d-gengzong>
      </div>
    </div></div>
    <!--  客户对账 -->
    <div style="background-color: #F3F6FB;padding: 50px 0">
      <div class="w1200">
        <div style="display: flex;justify-content: center;gap: 200px">
          <div>
            <p style="font-size: 18px;margin-bottom: 20px;font-weight: bold">客户对账</p>
            <div class="kh-title">
              <div>渠道直连</div>
              <div>降成本 提效率</div>
              <div>管理可视化</div>
              <div>赋能价值提升</div>
            </div>
          </div>
          <d-m-scustomer :auditTrail='reconciliation' style="margin-top: 80px"></d-m-scustomer>
        </div>
      </div>
    </div>
    <!--  客户服务 -->
    <div class="w100" style="background-color: #fff"><div class="w1200" style="padding: 50px 0">
      <p style="font-size: 18px;margin-bottom: 20px;font-weight: bold;text-align: center">客户服务</p>
      <div class="flex-sb" style="gap: 100px">
        <div style="display: flex;align-items: center;gap: 50px">
          <div>
            <div class="fw-list-title">公告查看
              <div>公司公告发布、客户公告查看</div>
            </div>
            <div class="fw-list-title">证件资料
              <div>公司人员上传证书、资质、授权、宣传等资料客户对资料进行查看、下载</div>
            </div>
            <div class="fw-list-title">服务评价
              <div>客户对销售、产品、物流等服务。满意度 进行评价</div>
            </div>
            <div class="fw-list-title">意见反馈
              <div>接口方式对接客服系统。根据客户信息、咨询入口，智能分配客服，让服务更懂客户。</div>
            </div>
          </div>
          <div class="icon-title">
            <div>
              <img src="../../../../public/image/MDS/gg.svg">
              <p>公告查看</p>
            </div>
            <div>
              <img src="../../../../public/image/MDS/zj.svg">
              <p>证件资料</p>
            </div>
            <div>
              <img src="../../../../public/image/MDS/fw.svg">
              <p>服务评价</p>
            </div>
            <div>
              <img src="../../../../public/image/MDS/yj.svg">
              <p>意见反馈</p>
            </div>
          </div>
        </div>
        <div class="wrapper-img">
          <img src="../../../../public/image/MDS/u478.png" style="width: 407px">
        </div>
      </div>
    </div></div>
    <!--  思念食品案例-->
    <div style="background-color: #F3F6FB;padding: 50px 0">
      <div class="w1200">
        <div style="width: 955px;margin: 0 auto">
          <p style="font-size: 18px;margin-bottom: 16px;font-weight: bold">思念食品案例</p>
          <p style="line-height: 23px;">思念食品有限公司（简称“思念食品”）成立于1997年7月，在创始团队的带领下，现已发展成为中国大型专业速冻食品生产企业之一，
            主要从事水饺、汤圆、面点、粽子、馄饨、休闲6大品类产品的研发、生产、销售。公司产品有500多个花色品种，年生产能力超过70万吨，
            营销网络遍及全国，主要产品国内市场占有率为27%，并已进入美国、英国、新加坡、澳大利亚和中国香港、中国澳门等近20个国家和70多
            个地区的市场。目前，公司拥有5个国内生产基地、1个海外生产基地，几十条现代化的速冻及常温生产线、几万吨低温冷库，员工数量超过10000人。</p>
        </div>
        <div class="flex-sb" style="align-items: center;gap: 10px;margin-top: 46px">
          <div class="flex-sb" style="align-items: center;gap: 10px">
              <img src="../../../../public/image/MDS/625.jpg" style="width: 290px;border-radius: 8px;box-shadow: 0px 0px 5px #999">
              <img src="../../../../public/image/MDS/626.png" style="width: 290px;border-radius: 8px;box-shadow: 0px 0px 5px #999">
          </div>
            <img src="../../../../public/image/MDS/u614.svg" style="width: 78px">
            <img src="../../../../public/image/MDS/u617.svg" style="width: 78px">
            <img src="../../../../public/image/MDS/u623.svg" style="width: 78px">
            <img src="../../../../public/image/MDS/u624.svg" style="width: 78px">
        </div>
      </div>
    </div>
    <div style="background-color: #F3F6FB;padding: 50px 0;display: flex;align-items: center;justify-content: center">
      <div class="ty-btn">即刻体验</div>
    </div>
  </div>
</template>

<script>
import DMSJXSGuanli from './modules/DMSjxsGuanli'
import DMSDDGengzong from './modules/DMSddGengzong'
import DMScustomer from "@/views/duct/ductDMS/modules/DMScustomer";

export default {
  name: "ductDMS",
  components: {DMSJXSGuanli, DMSDDGengzong, DMScustomer},
  data() {
    return {
      // 产品管理
      product: [
        {
          list: ['产品分类', '产品价格', '物料映射']
        },
        {
          list: ['上架范围', '可用库存',]
        },
        {
          list: ['商品规格', '标准件系数', '69码维护']
        },
      ],
      //  促销管理
      Promotion: [
        {
          title: '买增/阶梯买赠',
          p1: '每购买  X  个，赠送 Y  个SKU或礼包',
          p2: '购买数量>= X 个，每购买 Z 个赠送 Y 个SKU或礼包'
        },
        {
          title: '折扣/阶梯折扣',
          p1: '购买数量>=X个，打Y折',
          p2: '购买数量>=X1个，打Y1折'
        },
        {
          title: '特价/阶梯特价',
          p1: '购买数量>=X个，商品特价：Y元',
          p2: '购买数量>=X1个，商品特价：Y1元'
        },
      ],
      //  订单管理
      order: ['库存', '重量', '车型', '标准件', '实时结算', '配额', '起运量', '提货日期', '装运类型'],
      //订单审核跟踪
      auditTrail1: [
        {
          title: '审核处理',
          list: ['拼车处理', '调换货', '加减货', '订单关闭', '有效期', '交期反馈']
        },
      ],
      auditTrail2: [{
        title: '物流跟踪',
        list: ['车辆信息', '发出时间', '时间电话', '预计交期', '收货确认', '物流反馈']
      }],
      //  客户对账
      reconciliation: [
        {
          title: '客户对账',
          list: ['资金信息', '费用信息', '订单信息', '月度账单', '账单确认', '差异反馈']
        }
      ]
    }
  },

}
</script>

<style scoped lang="less">
h2 {
  font-family: YuYangT3;
}

.flex-sb {
  display: flex;
  justify-content: center;
}



// DMS经销商管理
.DMS-lin-title {
  display: flex;
  align-items: center;
  gap: 50px;
  margin-left: 20px;
  font-size: 16px;
  color: #0D36A8;
  margin-top: 16px;

  div {
    position: relative;
  }

  div::before {
    content: "";
    width: 8px;
    height: 8px;
    background: #0D36A8;
    position: absolute;
    top: 8px;
    left: -13px;
    border-radius: 50%;
  }
}

// 客户管理
.kh-title {
  margin-left: 16px;

  div {
    position: relative;
    margin-top: 20px;
  }

  div::before {
    content: "";
    position: absolute;
    width: 8px;
    height: 8px;
    border-radius: 50%;
    background-color: #0D36A8;
    top: 7px;
    left: -13px;
  }
}

.kh-right-box {
  color: #fff;

  .kh-right-box-title {
    width: 234px;
    height: 40px;
    background: #0d36a8;
    border-radius: 4px;
    display: flex;
    align-items: center;
    justify-content: center;
    font-size: 18px;
    font-weight: bold;
  }

  .wil-mei {
    display: flex;
    justify-content: space-between;

    p {
      width: 114px;
      height: 32px;
      background: #0d36a8;
      border-radius: 4px;
      display: flex;
      align-items: center;
      justify-content: center;
      margin-top: 6px;
    }
  }
}

//产品管理
.wmp-product {
  display: flex;
  width: 400px;
  justify-content: space-between;

  .wmp-product-list {
    width: 83px;
    height: 83px;
    box-sizing: border-box;
    border-radius: 50%;
    display: flex;
    align-items: center;
    justify-content: center;
    background-color: #fff;
    border: 2px solid #78C7FF;

    .wmp-product-list-box {
      width: 72px;
      height: 72px;
      background-color: #0D36A8;
      color: #fff;
      border-radius: 50%;
      display: flex;
      align-items: center;
      justify-content: center;
      font-size: 14px;
      text-align: center;
      box-sizing: border-box;
      padding: 7px;
    }
  }
}

.wmp-product:nth-last-child(2) {
  justify-content: space-evenly;
}

//促销管理
.cx-list-box {
  width: 364px;
  height: 180px;
  box-sizing: border-box;
  background: #f3f6fb;
  border-radius: 8px;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  padding: 23px 22px;
}

//订单管理
.wrapper-order {
  display: grid;
  grid-template-columns: 1fr 1fr 1fr;
  gap: 10px;
  margin-top: 43px;

  .wrapper-img-order {
    width: 114px;
    height: 50px;
    display: flex;
    align-items: center;
    justify-content: center;
    color: #fff;
    background: #78c7ff;
    font-size: 14px;
    font-weight: 500;
  }
}

//客户服务
.fw-list-title {
  font-weight: bold;
  color: #0D36A8;
  margin-bottom: 28px;
  position: relative;

  div {
    font-weight: normal;
    color: #808080;
    line-height: 1.5rem;
    width: 312px;
  }
}

.fw-list-title::before {
  content: "";
  width: 8px;
  height: 8px;
  background-color: #0D36A8;
  border-radius: 50%;
  position: absolute;
  top: 7px;
  left: -13px;
}

.icon-title {
  display: flex;
  flex-direction: column;
  gap: 26px;

  div {
    display: flex;
    align-items: center;
    gap: 10px;
  }

  p {
    color: #0D36A8;
    font-weight: 500;
  }
}
.ty-btn{
  width: 146px;
  height: 44px;
  border: 1px solid #333333;
  border-radius: 4px;
  display: flex;
  align-items: center;
  justify-content: center;
  font-size: 22px;
  cursor: pointer;
}
</style>
