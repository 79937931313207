<template>
  <div class="home-butbox w1240">
    <div class="home-butleft">
      <img src=/image/log.png style="width: 80px; height: 80px">
<!--      <span class="home-butbox-logtxt">齐道信息</span>-->
    </div>
    <div class="home-but-text" style="position: relative">
      <div v-for="(item,index) in arr" :key="index">
        <p :class="[index === checkindex ? 'active' : '']" style="width:100px;
           font-size:16px;font-weight: bold;text-align:center; height: 35px;" class="home-but-box"
           @mouseenter="mouseenter(index)" @click="clickList(index)">{{ item.name }}</p>
      </div>
    </div>
    <div>
      <mu-button color="primary" class="info-right">体验DEMO</mu-button>
      <mu-button color="primary">联系我们</mu-button>
    </div>
    <!--    产品弹窗-->
    <transition name="el-zoom-in-top">
      <div class="product" v-if="product" @mouseleave="mouseleave">
        <div v-for="(item,index) in productData" :key="index" class="product-lin">
          <p class="product-p">{{ item.title }}</p>
          <ul style="display: flex;flex-direction: column;flex-wrap: wrap;height: 204px">
            <li v-for="(item,index) in item.list" :key="index" class="product-li">
              <h4 @click="pathUrl(item.url)">{{ item.link }}</h4>
              <p>{{ item.text }}</p>
            </li>
          </ul>
        </div>
      </div>
    </transition>
    <!--    解决方案弹窗-->
    <transition name="el-zoom-in-top">
      <div class="solution" v-if="solutionBen" @mouseleave="mouseleave">
        <div v-for="(item,index) in solution" :key="index">
          <p class="solution-p">{{ item.title }}</p>
          <ul>
            <li v-for="(item,index) in item.list" :key="index" class="solution-lin">
              <h4>{{ item.text }}</h4>
            </li>
          </ul>
        </div>
      </div>
    </transition>
  </div>
</template>

<script>
import {productData} from '@/publicData/navListData'
import {solution} from '@/publicData/HomeMentList'

export default {
  name: "homeNavigationbar",
  data() {
    return {
      product: false,
      productData,
      solution,
      solutionBen: false,
      checkindex: 0,
      arr: [
        {
          name: "首页",
          url: "",
        },
        {
          name: "产品",
          url: "",
        },
        {
          name: "客户",
          url: "",
        },
        {
          name: "解决方案",
        },
        {
          name: "联系我们",
          url: "",
        }
      ]
    }
  },
  mounted() {
    console.log(this.productData)
  },
  methods: {
    clickList(index) {
      this.checkindex = index
      if (this.checkindex === 0) {
        this.$router.push({path: '/mainHomePage'})
      }
      if (this.checkindex === 2) {
        this.$router.push({path: '/customer'})
      }
    },
    //鼠标移入
    mouseenter(index) {
      if (index === 1) {
        this.product = true
      }
      if (index === 3) {
        this.solutionBen = true
      }
      if (index !== 1) {
        this.product = false
      }
      if (index !== 3) {
        this.solutionBen = false
      }
    },
    //鼠标移出
    mouseleave(index) {
      this.product = false
      this.solutionBen = false
    },
    //路由跳转
    pathUrl(url) {
      this.$router.push({path: url})
      this.checkindex = 1
      this.product = false
    }
  }
}
</script>

<style scoped lang="less">
.home-butbox {
  width: 100%;
  height: 94px;
  display: flex;
  align-items: center;
  justify-content: space-around;
  box-sizing: border-box;
  background-color: #fff;
  margin: 0 auto;
}

.home-butleft {
  display: flex;
  align-items: center;
}

.home-butbox-logtxt {
  font-size: 28px;
  font-weight: bold;
  color: #333;
  text-align: center;
  //font-family:YuYangT3;
  line-height: 24px;
}

.home-but-text {
  display: flex;
  justify-content: space-between;

}

.active {
  border-bottom: #0170fe 1px solid;
  color: rgba(1, 112, 254, 1);
}

.home-but-box {
  cursor: pointer;
}

.home-but-box:hover {
  color: rgba(1, 112, 254, 1);
  transition: 0.5s;
}

.info-right {
  margin-right: 30px;
}

/* 产品弹窗*/
.product {
  width: 650px;
  height: 300px;
  background-color: #fff;
  padding: 48px 51px;
  position: absolute;
  top: 94px;
  z-index: 99999;
  display: flex;
  gap: 40px;
  justify-content: center;
  border-radius: 0 0 4px 4px;
  box-shadow: 0px 2px 6px #eee;

  .product-lin {
    .product-p {
      color: #0D36A8;
      font-weight: bold;
      font-size: 18px;
      margin-bottom: 10px;
    }

  }
}

.product-li {
  margin-bottom: 20px;
  position: relative;
  margin-left: 8px;

  h4 {
    font-size: 14px;
    font-weight: normal;
  }

  p {
    font-size: 12px;
    color: #999;
    line-height: 1.25rem;
  }

  h4:hover {
    cursor: pointer;
    transition: 0.3s;
    color: #0170fe;
  }
}

//.product-li:nth-of-type(4) {
//  margin-left: 48px;
//}

.product-li::before {
  content: "";
  width: 2px;
  height: 28px;
  position: absolute;
  top: 5px;
  left: -8px;
  background-color: #0D36A8;
  border-radius: 4px;
}

//解决方案
.solution {
  width: 600px;
  height: 250px;
  background-color: #fff;
  padding: 10px 30px 20px 30px;
  position: absolute;
  top: 94px;
  z-index: 99999;
  display: flex;
  justify-content: space-between;
  border-radius: 0 0 4px 4px;
  box-shadow: 0px 2px 6px #eee;

  .solution-p {
    color: #999;
    font-weight: bold;
    font-size: 12px;
    margin-bottom: 10px;
  }

  .solution-lin {
    h4 {
      cursor: pointer;
      font-size: 12px;
    }

    h4:hover {
      transition: 0.3s;
      color: #0170fe;
    }
  }

  .solution-lin ~ .solution-lin {
    margin-top: 10px;
  }
}
</style>
