<template>
  <div>
  <div class="wrapper w100">
    <div class="wrapper-footer w1200">
      <div class="left">
        <div class="left-logo" style="font-size: 22px;font-weight: 600">
          成都齐道科技有限公司
          <!--          <img src="../../../public/image/cn-logo.svg">-->
        </div>
        <p class="about">Tel：18180763501</p>
        <p class="about">E-mail：xuduan@qidaokj.cn</p>
        <p class="about">简历投递：xuduan@qidaokj.cn</p>
        <div class="flex">
          <div class="left-QR">
            <img src="/image/official.png">
            <p><i class="iconfont icon-weixin"></i><span>微信公众号</span></p>
          </div>
          <div class="left-QR">
            <div class="left-QR-right">
            <img src="/image/consulting-service.png">
            </div>
            <p><i class="iconfont icon-weixin"></i><span>在线咨询</span></p>
          </div>
        </div>
      </div>
      <div class="right">
        <div class="right-our">
          <dl>
            <dt>产品</dt>
            <dd @click="$router.push({path:'/ductTPM'})">TPM营销费用管理平台</dd>
            <dd @click="$router.push({path:'/ductDMS'})">DMS经销商服务平台</dd>
            <dd @click="$router.push({path:'/ductSFA'})">SFA移动访销平台</dd>
            <dd>KMS直营对账平台</dd>
            <dd @click="$router.push({path:'/ductDIS'})">DIS经销商数据管理系统</dd>
            <dd @click="$router.push({path:'/ductDistribution'})">深度分销系统</dd>
          </dl>
          <dl>
            <dt>解决方案</dt>
            <dd>通用解决方案</dd>
            <dd @click="$router.push({path:'/Milk'})">乳业解决方案</dd>
            <dd @click="$router.push({path:'/Food'})">食品解决方案</dd>
            <dd @click="$router.push({path:'/Alcohol'})">酒类解决方案</dd>
          </dl>
          <dl>
            <dt>用户中心</dt>
            <dd>产品上线指南</dd>
            <dd>实施指南</dd>
            <dd>技术文档</dd>
          </dl>
          <dl>
            <dt @click="$router.push({path:'/aboutUs'})">关于我们</dt>
            <dd>公司介绍</dd>
            <dd>公司动态</dd>
            <dd>法律声明</dd>
            <dd @click="$router.push({path:'/JoinUs'})">加入我们</dd>
            <dd>联系我们</dd>
          </dl>
          <dl>
            <dt>市场活动</dt>
            <dd>主题活动</dd>
            <dd>宴会活动</dd>
            <dd>市场陈列</dd>
          </dl>
        </div>
      </div>
    </div>
  </div>
    <div class="footer-bottom w100">© 2021-2026 成都齐道科技有限公司 蜀ICP备2021024431号-1</div>
  </div>
</template>

<script>
export default {
  name: "TabFooter"
}
</script>

<style scoped lang="less">
.wrapper {
  width: 100%;
  min-width: 1240px;
  background-color:#0D36A8;
  color: #fff;
  font-size: 12px;
  box-sizing: border-box;
  padding: 30px;

  .wrapper-footer {
    display: grid;
    grid-template-columns:300px 1fr;
    gap: 30px;
    box-sizing: border-box;
  }

  .left {
    box-sizing: border-box;
    //padding-left: 50px;
    .about{
      line-height: 2;
      color: #eee;
    }
    .about:last-of-type{
      margin-bottom: 20px;
    }
    .left-logo {
      display: flex;
      align-items: center;
      margin-bottom: 20px;
      p {
        font-size: 28px;
        font-weight: bold;
        //font-family: YuYangT3;
      }
    }
    .left-QR{
      img{
        width: 90px;
      }
      .left-QR-right{
        width: 90px;
        height: 90px;
        padding: 3px;
        background-color: #fff;
        img{
          width: 84px;
        }
      }
      p{
        display: flex;
        align-items: center;
        gap: 4px;
      }
    }

  }
  .right{
    box-sizing: border-box;
    width: 100%;
    .right-our{
      display: flex;
      justify-content: space-around;
      font-size: 13px;
      box-sizing: border-box;
      padding: 20px;
      margin-bottom: 10px;
      dt{
        font-size: 16px;
        color: #fff;
        font-weight: bold;
      }
      dd{
        line-height: 2rem;
        color: #f5f5f5;
        transition: 0.5s;
      }
      dd:hover{

        color: #fff;
        cursor: pointer;
      }
    }
  }
}
.flex{
  display: flex;
  align-items: center;
  gap: 15px;
}
.footer-bottom{
  height: 40px;
  display: flex;
  align-items: center;
  justify-content: center;
  color: #fff;
  background-color: #333;
  font-size: 12px;
}
</style>
