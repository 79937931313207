<template>
  <div style="position: relative">
    <div class="wrapper">
      <div class="wrapper-box">
        <img :src="auditTrail.centerBg" style="z-index: 100">
        <p class="wrapper-box-p">{{ auditTrail.title }}</p>
      </div>
    </div>
    <div v-for="(item,index) in auditTrail.list" :key="index" class="wrapper-lin">
      <img :src="auditTrail.bg">
      <div class="wrapper-ml">
        <p class="wrapper-mei-lin1">{{ item.title }}</p>
        <p class="wrapper-mei-lin2">{{ item.p }}</p>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  name: "绩效管理",
  props: {
    auditTrail: {
      type: Array,
    },
  },
}
</script>

<style scoped lang="less">

.wrapper {
  position: relative;

  .wrapper-box {
    position: relative;
    display: flex;
    align-items: center;
    justify-content: center;
    width: 150px;
    height: 150px;

    img {
      position: absolute;
      width: 150px;
    }

    .wrapper-box-p {
      position: absolute;
      width: 100%;
      height: 100%;
      display: flex;
      align-items: center;
      justify-content: center;
      top: 0;
      left: 0;
      font-size: 16px;
      color: #fff;
      font-weight: bold;
      z-index: 300;
    }

    .wbp {
      top: 80px;
      color: #9ECFFB;
      font-size: 28px;
    }
  }
}

.wrapper-lin {
  width: 324px;
  height: 64px;
  position: absolute;
  z-index: 800;
  box-sizing: border-box;
  img {
    width: 324px;
    height: 64px;
  }

  .wrapper-ml {
    position: absolute;
    width: 100%;
    height: 100%;
    top: 0;
    left: 0;
    box-sizing: border-box;
    padding: 0px 40px;
    display: flex;
    flex-direction: column;
    justify-content: center;

    .wrapper-mei-lin1 {
      font-weight: bold;
      color: #0D36A8;
    }

    .wrapper-mei-lin2 {
      color: #fff;
      font-size: 12px;
    }
  }

}
.wrapper-lin:nth-of-type(2n) .wrapper-mei-lin1,.wrapper-lin:nth-of-type(2n) .wrapper-mei-lin2 {
  text-align: right;
}

.wrapper-lin:nth-of-type(2) {
  //position: absolute;
  top: -43px;
  left: -318px;

}


.wrapper-lin:nth-of-type(3) {
  //position: absolute;
  top: 42px;
  left: 180px;
}

.wrapper-lin:nth-of-type(4) {
  top: 136px;
  left: -313px;
}

.wrapper-lin:nth-of-type(5) {
  //position: absolute;
  top: -43px;
  left: 146px;
}

.wrapper-lin:nth-of-type(6) {
  //position: absolute;
  top: 42px;
  left: -348px;
}

.wrapper-lin:nth-of-type(7) {
  //position: absolute;
  top: 136px;
  left: 150px;
}
</style>