<template>
  <div style="background-color: #fff">
    <div class="wrapper-banner">
      <div class="w1200">
        <h1 style="font-family: YuYangT3">营销费用管理系统 (TPM)</h1>
        <p style="font-size: 22px">Trade Promotion Management</p>
        <p style="font-size: 18px;line-height: 1.75rem">齐道TPM活动费⽤管理融⼊PDCA闭环管理模型</p>
        <p style="font-size: 18px;line-height: 1.75rem"> 覆盖营销活动全流程，帮助企业精打细算，找到提升价值创造的关键点。</p>
        <ul class="wrapper-banner-ul" style="display: flex;font-size: 16px;gap: 50px;margin-left: 20px">
          <li>预算财年、预算科目、预算费用</li>
          <li>活动</li>
          <li>管理可视化</li>
          <li>赋能价值提升</li>
        </ul>
        <div class="tpm-btn" plain>即可体验</div>
      </div>
    </div>
    <div style="padding: 46px">
      <div class="w1200">
        <h2 style="text-align: center;margin: 0px 0 10px 0;font-weight: bold;font-size: 22px">系统流程</h2>
        <div class="wrapper-title-lin">
          <div>预算财年、预算科目、预算费用</div>
          <div>活动</div>
          <div>管理可视化</div>
          <div>赋能价值提升</div>
        </div>
        <div class="wrapper">
          <div class="wrapper-imgText-box">
            <div v-for="(item,index) in TPMSystem" :key="index" class="wib-lin">
              <div class="wib-lin-title">{{ item.title }}</div>
              <div class="wib-lin-content">
                <p v-for="(item,index) in item.list" :key="index">{{ item }}</p>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
    <!--    预算管理-->
    <div style="background-color: #F3F6FB;padding: 46px 0">
      <div class="w1200">
        <p style="font-size: 22px;margin-bottom: 25px;text-align: center">预算管理</p>
        <div style="display: flex;justify-content: center;width: 100%;gap: 300px;position: relative">
          <div style="width: 168px">
            <div style="position: relative;">预算管理：支持全维度配置
              <div style="position: absolute;width: 32px;border-top: 4px solid #78C7FF;top: 8px;right: -70px"></div>
            </div>
            <div class="wrapper-ys-text" style="text-align: right">
              <!--    分支-->
              <div class="ys-branch">
                <div class="branch-lin" style="height: 213px">
                  <div class="branch-lin-left"></div>
                </div>
              </div>
              <div>期间</div>
              <div>组织</div>
              <div>客户</div>
              <div>产品</div>
              <div>渠道</div>
              <div>科目</div>
            </div>
          </div>
          <div style="margin-top: 30px">
            <img src="../../../../public/image/u782.jpg" style="width: 430px">
          </div>
          <p style="position: absolute;font-weight: bold;top: 58px;left: 430px">预算全维度</p>
          <p style="position: absolute;font-weight: bold;top: 221px;left: 430px">分组灵活配置</p>
        </div>
      </div>
    </div>
    <!--活动模板-->
    <div class="w1200">
      <div style="padding: 50px 0">
        <p style="font-size: 22px;margin-bottom: 25px;text-align: center">活动模板</p>
        <div style="display: flex;align-items: center;justify-content: center;gap: 300px">
          <div style="display: flex;flex-direction: column;gap: 50px">
            <div class="hd-title">活动模板、活动细类
              <!--    分支-->
              <div class="hd-branch">
                <div class="branch-lin" style="height: 70px">
                  <div class="branch-lin-left"></div>
                </div>
              </div>
              <div class="hd-text">
                <div>业务视角的费用归集维度</div>
                <div>TPM系统费用类型</div>
              </div>
            </div>
            <div class="hd-title" style="margin-left: 70px">会计科目
              <!--    分支-->
              <div class="hd-branch">
                <div class="branch-lin" style="height: 70px">
                  <div class="branch-lin-left"></div>
                </div>
              </div>
              <div class="hd-text" style="margin-left: -66px">
                <div>财务视角的费用归集维度</div>
                <div>ERP系统费用类型</div>
              </div>
            </div>
          </div>
          <div>
            <div style="display: flex;align-items: center;gap: 30px;">
              <div style="width: 150px;height: 150px;border-radius: 50%;background-color: #78C7FF;position: relative">
                <div class="wrapper-lin-box" style="position: absolute;bottom: 0px;left: 13px">
                  <div class="wlb-box">
                    <p>活动模板</p>
                    <p>活动细类</p>
                  </div>
                </div>
              </div>
              <div class="wlb-icon">
                <div><i class="iconfont icon-zhuanhuan" style="font-size: 20px"></i></div>
              </div>
              <div class="wrapper-lin-box">
                <div class="wlb-box">
                  <p>预算科目</p>
                </div>
              </div>
              <div class="wlb-icon">
                <div><i class="iconfont icon-zhuanhuan" style="font-size: 20px;color: #444"></i></div>
              </div>
              <div style="width: 150px;height: 150px;border-radius: 50%;background-color: #78C7FF;position: relative">
                <div class="wrapper-lin-box" style="position: absolute;bottom: 0px;left: 13px">
                  <div class="wlb-box">
                    <p>会计科目</p>
                  </div>
                </div>
              </div>
            </div>
            <div class="wlb-more">
              <div class="wlb-more-div">
                <p>链接业务、财务费用分类体系</p>
                <p>达成业务、财务费用事务认知一致</p>
              </div>
              <div class="wlb-more-div">
                <p>实现业务、财务一体化费用管理</p>
                <p>支撑业务、财务多纬度损益分析</p>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
    <div class="wrapper-bg"></div>
    <!--    活动申请-->
    <div class="w1200" style="padding: 50px 0">
      <p style="font-size: 22px;margin-bottom: 25px;text-align: center">活动申请</p>
      <div style="display: flex;align-items: center;justify-content: center;gap: 150px">
        <div>
          <div class="hd-title">活动申请：活动总体计划
            <!--    分支-->
            <div class="hd-branch">
              <div class="branch-lin" style="height: 156px">
                <div class="branch-lin-left"></div>
              </div>
            </div>
            <div class="sq-lin-text">
              <div>活动主题</div>
              <div>活动执行人</div>
              <div>活动开始时间</div>
              <div>活动结束时间</div>
              <div>目标销量</div>
              <div>预估销量</div>
            </div>
          </div>
          <div class="hd-title" style="margin-left: 38px;margin-top: 20px">活动申请:活动细类
            <!--    分支-->
            <div class="hd-branch">
              <div class="branch-lin" style="height: 156px">
                <div class="branch-lin-left"></div>
              </div>
            </div>
            <div class="sq-lin-text">
              <div>费用承担组织</div>
              <div>经销商</div>
              <div>品项</div>
              <div>渠道</div>
              <div>费用类型</div>
              <div>预算编号</div>
            </div>
          </div>
        </div>
        <div class="sq-lin-img"
             style="padding-right: 0px;display: flex;justify-content: space-between;align-items: center">
          <img src="../../../../public/image/u802.png" style="width:150px">
          <img src="../../../../public/image/u803.png" style="width: 450px">
        </div>
      </div>
    </div>
    <div class="wrapper-bg2"></div>
    <!--    费用核销-->
    <div class="w1200" style="padding: 50px 0">
      <div style="display: flex;align-items: center;justify-content: center;gap: 40px">
        <div>
          <img src="../../../../public/image/u816.png" style="width: 545px">
        </div>
        <div class="hx-lin-box">
          <p>费用核销</p>
          <div>客户业务上传稽核结果、活动资料、标准规则</div>
          <div>主管/经理/市场部/财务审核</div>
          <div>TPM规划金额、稽核金额、核销金额、执行情况销量费比</div>
          <div>主管/经理/市场部/财务审核</div>
        </div>
      </div>
    </div>
    <div class="wrapper-bg"></div>
    <!--    费用上账-->
    <div class="w1200" style="padding: 50px 0">
      <p style="font-size: 22px;margin-bottom: 25px;text-align: center">费用上账</p>
      <div style="display: flex;justify-content: center;gap: 80px">
        <div>
          <div class="hd-title">费用上账
            <!--    分支-->
            <div class="hd-branch">
              <div class="branch-lin" style="height: 374px">
                <div class="branch-lin-left"></div>
              </div>
            </div>
            <div class="sq-lin-text" style="display: flex;flex-direction: column;justify-content: space-between;height: 374px">
              <div class="hd-title">上账方式
                <!--    分支-->
                <div class="hd-branch" style="right: -46px">
                  <div class="branch-lin" style="height: 26px">
                    <div class="branch-lin-left"></div>
                  </div>
                </div>
                <div class="sq-lin-text" style="margin-left: 65px">
                  <div>批量上账</div>
                  <div>部分上账</div>
                </div>
              </div>
              <div class="hd-title">支付方式
                <!--    分支-->
                <div class="hd-branch" style="right: -46px">
                  <div class="branch-lin" style="height: 26px">
                    <div class="branch-lin-left"></div>
                  </div>
                </div>
                <div class="sq-lin-text" style="margin-left: 65px">
                  <div>现金、转预付/账扣</div>
                  <div>折扣、补货</div>
                </div>
              </div>
              <div class="hd-title">发票管理
                <!--    分支-->
                <div class="hd-branch" style="right: -46px">
                  <div class="branch-lin" style="height: 74px">
                    <div class="branch-lin-left"></div>
                  </div>
                </div>
                <div class="sq-lin-text" style="margin-left: 65px">
                  <div>发票号</div>
                  <div>发票金额</div>
                  <div>税金</div>
                  <div>进项税转出</div>
                </div>
              </div>
              <div class="hd-title">发票AI识别
                <!--    分支-->
                <div class="hd-branch">
                  <div class="branch-lin" style="height: 26px">
                    <div class="branch-lin-left"></div>
                  </div>
                </div>
                <div class="sq-lin-text">
                  <div>增值税普通发票</div>
                  <div>增值税专用发票</div>
                </div>
              </div>
              <div class="hd-title">开票与揉价
              </div>
            </div>
          </div>
        </div>
        <div class="wrapper-img">
          <img src="../../../../public/image/u827.png" style="width: 587px">
        </div>
      </div>
    </div>
    <div class="wrapper-bg2"></div>
    <!--    五得利案例-->
    <div class="w1200" style="padding: 50px 0">
      <div style="display: flex;justify-content: center;gap: 30px">
        <div style="width: 500px">
          <p style="font-size: 16px;margin-bottom: 16px;font-weight: bold">五得利案例</p>
          <p style="margin-bottom: 10px;line-height: 1.75rem">五得利面粉集团始建于1989年，是一家专业化生产面粉的民营企业。经过三十二年的不懈努力和实践，公司从一个日处理小麦能力不足15吨的作坊式小厂，
            发展成目前拥有六省十八地18个子公司，35个大型制粉车间，96条现代化面粉生产线，日处理小麦能力达55000吨，员工5000多名的大型制粉企业。</p>
          <p style="line-height: 1.75rem"> 专注面粉三十二年。五得利产品畅销全国除港澳台地区以外的所有省市自治区直辖市，所有大中城市均有五得利的销售网点，市场覆盖率达到了97.6%，
            在北京市场的占有率达40%以上，拥有占优势的忠实消费群体。2016年度产值近276亿元，2017年度产值达293.7亿元。2019年度销售收入达321亿。</p>
        </div>
        <div>
          <img src="../../../../public/image/u885.png" style="width: 600px">
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import {TPMSystem, TPMData} from '@/publicData/TPMSystem'

export default {
  name: "ductTPM",
  components: {},
  data() {
    return {
      TPMSystem,
      TPMData,
    }
  }
}
</script>

<style scoped lang="less">
.wrapper-banner {
  background: url("../../../../public/image/TPM/tpm-banner.jpg")   center / cover no-repeat;
  width: 100%;
  height: 360px;
  display: flex;
  flex-direction: column;
  justify-content: center;
  color: #fff;
  h1 {
    margin: 0;
    font-size: 28px;
  }
  .wrapper-banner-ul{
    li{
      position: relative;
    }
    li::before{
      content: "";
      width: 12px;
      height: 12px;
      background-color: #fff;
      border-radius: 50%;
      position: absolute;
      top: 6px;
      left: -19px;
    }
  }
}
//系统流程
.wrapper-title-lin {
  display: flex;
  justify-content: center;
  gap: 50px;

  div {
    color: #0D36A8;
    font-size: 16px;
    position: relative;
  }

  div::before {
    content: "";
    position: absolute;
    width: 12px;
    height: 12px;
    background-color: #0D36A8;
    border-radius: 50%;
    top: 6px;
    left: -17px;
  }
}

.wrapper-imgText-box {
  display: flex;
  align-items: center;
  justify-content: center;
  gap: 35px;
  margin-top: 16px;

  .wib-lin {
    width: 180px;
    height: 216px;
    background-color: #fff;
    border-radius: 4px;

    .wib-lin-title {
      width: 100%;
      height: 52px;
      border-radius: 4px 4px 0 0;
      background-color: #0D36A8;
      color: #fff;
      font-size: 18px;
      display: flex;
      align-items: center;
      justify-content: center;
    }

    .wib-lin-content {
      width: 100%;
      height: 164px;
      border: 1px solid #E5E5E5;
      border-radius: 0 0 4px 4px;
      box-sizing: border-box;
      display: flex;
      flex-direction: column;
      align-items: center;
      padding-top: 16px;

      p {
        line-height: 1.75rem;
      }
    }
  }
}

//预算管理
.wrapper-ys-text {
  margin-top: 44px;
  height: 220px;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  position: relative;

  .ys-branch {
    position: absolute;
    transform: rotatey(180deg);
    top: 3px;
    right: -40px;
  }
}

//分支
.branch-lin {
  width: 25px;
  border: 4px solid #78C7FF;
  border-right: none;
  position: relative;
  display: flex;
  align-items: center;
}

.branch-lin-left {
  width: 30px;
  box-sizing: border-box;
  border-top: 4px solid #78C7FF;
  position: relative;
  left: -25px;
}

//活动模板
.hd-title {
  position: relative;
  display: flex;
  align-items: center;
  //font-weight: bold;
  //活动分支
  .hd-branch {
    position: relative;
    right: -40px;
  }

  .hd-text {
    display: flex;
    flex-direction: column;
    gap: 46px;
    position: absolute;
    left: 205px;

    div {
      width: 200px;
    }
  }
}

.wrapper-lin-box {
  width: 130px;
  height: 130px;
  border-radius: 50%;
  background-color: #5EBCFF;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;

  .wlb-box {
    color: #fff;
    font-size: 16px;
  }
}

.wlb-more {
  display: flex;
  align-items: center;
  justify-content: center;
  gap: 45px;
  margin-top: 30px;

  .wlb-more-div {
    p {
      line-height: 28px;
      color: #0D36A8;
      //font-weight: bold;
      position: relative;
    }

    p::before {
      content: "";
      width: 8px;
      height: 8px;
      border-radius: 50%;
      position: absolute;
      background-color: #0D36A8;
      top: 9px;
      left: -13px;
    }
  }
}

//活动申请
.sq-lin-img {
  width: 700px;
  padding: 20px;
  box-sizing: border-box;
  background-color: #fff;
  border-radius: 10px;

  img {
    width: 100%;
  }
}

.sq-lin-text {
  margin-left: 50px;

  div {
    line-height: 2rem;
  }

}

//费用核销
.hx-lin-box {
  p {
    font-size: 22px;
    margin-bottom: 30px;
  }

  div {
    position: relative;
    line-height: 1.75rem;
    margin-left: 16px;
  }

  div::before {
    position: absolute;
    content: "";
    width: 8px;
    height: 8px;
    top: 9px;
    left: -13px;
    background: #0D36A8;
    border-radius: 50%;
  }
}

//页面背景装饰
.wrapper-bg {
  background: url("../../../../public/image/tpmzhuangshi.svg") top / cover no-repeat;
  width: 100%;
  height: 20px;
}

.wrapper-bg2 {
  background: url("../../../../public/image/tpmzhuangshi2.svg") top / cover no-repeat;
  width: 100%;
  height: 20px;
}
</style>
