<template>
  <div style="background-color: #fff">
    <div class="banner">
      <div class="w1240" style="padding: 120px 0 0 80px">
          <h1 style="margin: 0;color: #0D36A8">一路与众多品牌共同成长</h1>
          <p style="font-weight: bold">费用管理系统 · 经销商系统 · 移动访销系统</p>
          <p style="font-weight: bold">直营应收对账系统 · 经销商数据管理系统 · 门店管理平台</p>
        </div>
    </div>
    <div style="padding: 30px 0">
      <h2 style="text-align: center;font-size: 20px;font-weight: bold">行业典型客户案例</h2>
      <p style="text-align: center;font-weight: bold">时代进步，市场变化，企业的业务发展需求除了“谋求当下”，更要“动态前瞻”商派为每家企业诊断数字化转型需求及问题，</p>
      <p style="text-align: center;font-weight: bold">按需定制符合企业发展的解决方案，更伴随企业发展提供全程支持。</p>
    </div>
    <div style="padding: 30px 0;background-color: #F4F4F4">
      <div class="w1240" style="display: flex;justify-content: center;gap: 50px">
        <div style="width: 600px;margin-top: 30px">
          <div style="font-size: 16px;font-weight: bold">五得利TPM项目</div>
          <p>五得利面粉集团始建于1989年，是一家专业化生产面粉的民营企业。经过三十二年的不
            懈努力和实践，公司从一个日处理小麦能力不足15吨的作坊式小厂，发展成目前拥有六省十
            八地18个子公司，35个大型制粉车间，96条现代化面粉生产线，日处理小麦能力达55000吨
            ，员工5000多名的大型制粉企业。</p>
          <p>TPM营销费用管理系统帮助五得利的营销费用透明化，政策处理由人工改为系统处理，不仅提高了效率，减少了错误，营销活动费用从申请到核销上账都有迹可循。</p>
        </div>
        <img style="width: 490px" src="../../../public/image/cust/wdlTpm.png">
      </div>
    </div>
    <div style="padding: 30px 0">
      <div class="w1240" style="display: flex;justify-content: center;gap: 50px">
        <img src="../../../public/image/cust/snDMS.png" style="width: 490px">
        <div style="width: 600px;margin-top: 30px">
          <div style="font-size: 16px;font-weight: bold">思念DMS项目</div>
          <p>思念食品有限公司（简称“思念食品”）成立于1997年7月，在创始团队的带领下，现已发展成为中国大型专业速冻食品生产企业之一，
            主要从事水饺、汤圆、面点、粽子、馄饨、休闲6大品类产 品的研发、生产、销售。公司产品有500多个花色品种，年生产能力超过70万吨，
            营销网络遍及全国，主要产品国内市场占有率为27%，并已进入美国、英国、新加坡、澳大利亚和中国香港、中国澳门等近20个国家和70多个
            地区的市场。目前，公司拥有5个国内生产基地、1个海外生产基地，几十条现代化的速冻及常温生产线、几万吨低温冷库，员工数量超过10000人。</p>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  name: "customer",
  data() {
    return {
      banner: require('../../../public/image/cust/banner.jpg')
    }
  }
}
</script>

<style scoped lang="less">
.banner {
  width: 100%;
  height: 360px;
  background: url('../../../public/image/cust/banner.jpg') center/cover no-repeat;
}
</style>