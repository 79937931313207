<template>
  <div>
    <div class="w100" style="background-color: #fff">
    <div class="w1100">
      <!--      行业发展趋势-->
      <h2 class="wrapper-h2">行业发展趋势</h2>
      <div class="flex" style="justify-content: center;gap: 60px;margin-bottom: 60px">
        <div class="trend-box" v-for="(item,index) in trendText" :key="index">
          {{item}}
        </div>
      </div>
      <!--      乳业行业痛点-->
      <h2 class="wrapper-h2-cn">行业发展趋势</h2>
      <div class="flex" style="justify-content: space-evenly">
        <div class="painSpot" v-for="(item,index) in painSpot" :key="index">
          <img :src="item.url">
          <p>{{item.text}}</p>
        </div>
      </div>
      <div style="margin: 48px auto 46px auto;width: 800px; border-bottom: 2px dashed #bbb"></div>
      <!--      解决方案-->
      <h2 class="wrapper-h2-cn">解决方案</h2>
      <p style="text-align: center;line-height: 22px">应对策略：基于乳制品行业现状，紧密围绕渠道分销体系优化与追踪、促销政策精准投放、以人为本，降本增效三大行动策略</p>
      <p style="text-align: center;line-height: 22px">以数字化智能化工具为手段，结合落地执行的工具抓手，驱动企业业务发展。</p>
      <el-menu mode="horizontal" :default-active="activeIndex" class=" el-menu"  text-color="#333"  active-text-color="#0D36A8" @select="handleSelect">
        <el-menu-item  v-for="(item,index) in solve" :key="index" :index="index" style="font-size: 16px;" >
          {{ item }}
        </el-menu-item>
      </el-menu>
    </div>
      </div>
    <div style="background-color: #f3f6fb">
      <div class="w1100 flex" style="justify-content: center;align-items: center;height: 422px">
        <div v-if="activeIndex===0" style="width: 100%">
          <div class="flex" style="justify-content: space-evenly">
            <div class="solve-box" v-for="(item,index) in solveList" :key="index">
              <img style="margin-bottom: 9px" :src="item.url">
              <p style="font-weight: bold;margin-bottom: 9px;font-size: 16px">{{item.text}}</p>
              <div class="solve-text">
                <p style="text-align: justify" v-for="(dxitem,dxindex) in item.p" :key="dxindex">{{dxitem}}</p>
              </div>
            </div>
          </div>
        </div>
        <div v-if="activeIndex===1" style="width: 100%">
          <div class="flex" style="justify-content: center;gap: 120px">
            <div style="position: relative">
              <div v-for="(item,index) in auditTrail" :key="index" class="wrapper">
                <div class="wrapper-box">
                  <div class="wrapper-box-center">
                    <p class="wrapper-box-p" style="font-weight: bold">{{ item.title }}</p>
                    <p class="wrapper-box-p ">{{ item.p }}</p>
                  </div>
                </div>
                <div v-for="(item,index) in item.list" :key="index" class="wrapper-lin">
                  <p><span>{{ item.title }}</span></p>
                </div>
              </div>
            </div>
            <div class="sale-lin">
              <div class="saleBox" v-for="(item,index) in saleText" :key="index">
                <div>{{item.title}}</div>
                <p>{{item.text}}</p>
              </div>
            </div>
          </div>
        </div>
        <div v-if="activeIndex===2" style="width: 100%">
          <div class="wrapper-DMS flex" style="justify-content: center">
            <div class="DMS-box">
              <!--    居中图片-->
              <p class="center-img">经销商管理</p>
              <div class="DMS-text-box">
                <div>渠道直连</div>
                <p>搭建思念与渠道之间的信息桥梁，打通品牌商与零售终端之间的业务链，杜绝信息不对称与传递失真。</p>
              </div>
              <div class="DMS-text-box">
                <div>降低成本提高效率</div>
                <p>基于移动互联网的工具创新，让品牌商与渠道间的信息流转加快，协作效率更高。渠道自助，也为品牌商降低了人力成本与管理成本。</p>
              </div>
              <div class="DMS-text-box">
                <div>管理可视化</div>
                <p>实时准确的掌握渠道运营情况，做到营销数据可视化，为品牌商营销策略的制定提供数据支撑，从而实现管理的透明化和可视化。</p>
              </div>
              <div class="DMS-text-box">
                <div>赋能价值提升</div>
                <p>厂商和渠道，互相获得促使自身业务发展的支持与推力。品牌商可以更快的从渠道获取到市场对产品及服务的反馈;同时品牌商赋能给到渠道商，提升渠道商销售及管理能力，实现共同成长。</p>
              </div>
            </div>
          </div>
        </div>
        <div v-if="activeIndex===3" style="width: 100%">
          <div class="flex" style="align-items: center;justify-content: center">
            <div class="marketing" >
              <div class="marketing-lin" v-for="(item,index) in marketing.list">
                <div class="m-l-title">{{item.title}}</div>
                <div class="m-l-p">{{item.p}}</div>
              </div>
              <div class="marketing-text">
                <p v-for="(item,index) in marketing.text">{{item}}</p>
              </div>
              <div class="marketing-cn"></div>
            </div>
          </div>
        </div>
      </div>
    </div>
    <div class="w100" style="background-color: #fff">
      <div class="button-wrapper">
        <mu-button large color="primary">即刻体验</mu-button>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  name: "index",
  data(){
    return{
      activeIndex:0,
      trendText:[
          '近年以来，中国乳业持续增长，位居世界前列，但生产量任然无法满足消费者需求，未来的牛奶消费者越来越注重产品质量。',
          '乳业市场的快速增长，进口市场也在持续扩大，中国牛奶市场被进一步瓜分，中国本土企业仍然面临不小的威胁。',
          '头部乳业企业不断开括渠道下沉，未来市场，不论是头部乳业企业扩展渠道下沉，或是中小企业，都急需扩张市场，采取应对措施。'
      ],
      painSpot:[
        {
          url:require('../../../../public/image/solve/KA.webp'),
          text:'KA门店运营情况难掌握'
        },
        {
          url:require('../../../../public/image/solve/xs.webp'),
          text:'销售管理急需规范'
        },
        {
          url:require('../../../../public/image/solve/yx.webp'),
          text:'营销费用管理难'
        },
        {
          url:require('../../../../public/image/solve/ty.webp'),
          text:'无统一的客户管理体系'
        },
      ],
      solve:['KA管理','销售管理','客户管理','营销管理'],
      solveList:[
        {
          url:require('../../../../public/image/solve/dazao.webp'),
          text:'打造KMS管理系统',
          p:['将直营业务固化，工作流程放在平台统一进行管理。例如主数据维护，避免后期新增、变更操作失误，降低工作交接风险，减少人员工作量。']
        },
        {
          url:require('../../../../public/image/solve/yewu.webp'),
          text:'业务在线处理',
          p:['1、实现单据在线处理，系统自动下载单据及数据转换，减少人工操作；','2、自动标记单据差异，提高单据处理效率；','3、自动出具对账差异表，提高对账效率；','4、实现单据在线批审，提高单据审核效率。']
        },
        {
          url:require('../../../../public/image/solve/zhenghe.webp'),
          text:'整合数据资源',
          p:['将各系统数据整合，及时提供销售数据、促销活动费用、预估损益、库存预警等数据分析报表。']
        },
      ],
      auditTrail: [
        {
          title: '齐道SFA',
          p: '移动访销',
          list: [
            {
              title: '工作助手',
            },
            {
              title: '销售赋能',
            },
            {
              title: '学习成长',
            },
            {
              title: '高效沟通',
            },
            {
              title: '激励体系',
            },
            {
              title: 'AI赋能',
            }
          ]
        }
      ],
      saleText:[
        {
          title:'更紧密的沟通和连接',
          text:'工作计划、工作任务、销售公告等即时发送到手机端，下级执行表现和执行结果及时反馈到上级，上下级工作关联更紧密。'
        },
        {
          title:'更灵活的过程管理设置',
          text:'轻松配置拜访步骤和拜访内容，实现在手机端适应不同客户不同拜访场景表单内容不同的配置，让工作过程更灵活简单。'
        },
        {
          title:'更真实的现场数据采集',
          text:'通过定位、Ai识别技术保障数据的准确和真实，多种识别技术让现场采集数据更简单高效，确保市场费用的真实投入到市场。'
        },
      ],
      marketing:{
        text:['营销费用','闭环管理流程'],
        list:[
          {
            title:'预算',
            p:'建立预算管控'
          },
          {
            title:'预算',
            p:'规范化费用活动申请和事前准备'
          },
          {
            title:'预算',
            p:'费用活动执行过程管理'
          },
          {
            title:'预算',
            p:'费用核销管理'
          },
          {
            title:'上账',
            p:'费用上账管理'
          },
          {
            title:'分析',
            p:'多维度数据分析'
          },
        ]
      }
    }
  },
  methods:{
    handleSelect(key){
      this.activeIndex = key
    }
  }
}
</script>

<style scoped lang="less">
@import "./Milk";
</style>
