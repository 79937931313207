<template>
  <div class="DMS-box">
    <!--    居中图片-->
    <p class="center-img">经销商管理</p>
    <div class="DMS-text-box">
      <div>渠道直连</div>
      <p>搭建思念与渠道之间的信息桥梁，打通品牌商与零售终端之间的业务链，杜绝信息不对称与传递失真。</p>
    </div>
    <div class="DMS-text-box">
      <div>降低成本提高效率</div>
      <p>基于移动互联网的工具创新，让品牌商与渠道间的信息流转加快，协作效率更高。渠道自助，也为品牌商降低了人力成本与管理成本。</p>
    </div>
    <div class="DMS-text-box">
      <div>管理可视化</div>
      <p>实时准确的掌握渠道运营情况，做到营销数据可视化，为品牌商营销策略的制定提供数据支撑，从而实现管理的透明化和可视化。</p>
    </div>
    <div class="DMS-text-box">
      <div>赋能价值提升</div>
      <p>厂商和渠道，互相获得促使自身业务发展的支持与推力。品牌商可以更快的从渠道获取到市场对产品及服务的反馈;同时品牌商赋能给到渠道商，提升渠道商销售及管理能力，实现共同成长。</p>
    </div>
  </div>
</template>

<script>
export default {
  name: "DMSJXSGuanli"
}
</script>

<style scoped lang="less">
.DMS-box {
  width: 600px;
  height: 340px;
  border: 8px solid #d4edff;
  display: flex;
  align-items: center;
  justify-content: center;
  border-radius: 50%;
  position: relative;
}

.center-img {
  width: 152px;
  height: 86px;
  display: flex;
  justify-content: center;
  align-items: center;
  font-size: 18px;
  color: #fff;
  font-weight: bold;
  background: #0D36A8;
  border-radius: 50%;
  box-shadow: 0px 2px 9px 0px rgba(20, 94, 179, 0.59);
}
.DMS-text-box{
  width: 405px;
  height: 170px;
  background: #ffffff;
  border: 4px solid #d4edff;
  border-radius: 4px;
  box-sizing: border-box;
  padding: 20px 23px;
  position: absolute;
  z-index: 100;
  div{
    font-size: 16px;
    color: #0d36a8;
    margin-bottom: 8px;
  }
  p{}
  line-height: 1.5rem;
}
.DMS-text-box:nth-child(2){
  top: -60px;
  left: -175px;
}
.DMS-text-box:nth-child(3){
  top: -60px;
  right:-175px;
}
.DMS-text-box:nth-child(4){
  bottom: -60px;
  left: -175px;
}
.DMS-text-box:nth-child(5){
  bottom: -60px;
  right: -175px;
}
</style>