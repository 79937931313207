<template>
<div class="w100" style="background-color: #fff">
  <div style="background-color: #F3F6FB;padding-bottom: 25px">
    <div class="w1100">
      <!--      食品行业发展趋势-->
      <h2 class="wrapper-h2">食品行业发展趋势</h2>
      <p style="line-height: 20px">随着休闲食品行业集中度逐渐攀升，已经开始从粗放式发展阶段向精细化发展阶段迈进，消费升级、场景多元化、渠道多元化等因素对企业的经营和发展提出了更高的要求。</p>
      <!--      食品行业面临的挑战-->
      <h2 class="wrapper-h2" style="margin-bottom: 32px">食品行业面临的挑战</h2>
      <div class="flex wrapper-fl">
        <div class="wrapper-food" v-for="(item,index) in foodList" :key="item">
          <div>
            <img :src="item.url">
          </div>
          <div class="wrapper-food-text">
            <div>{{item.title}}</div>
            <p>{{item.text}}</p>
          </div>
        </div>
      </div>
    </div>
  </div>
  <div class="w1100">
    <!--      食品行业数字化转型方向-->
    <h2 class="wrapper-h2">食品行业数字化转型方向</h2>
    <p style="line-height: 20px;margin-bottom: 20px">面对休闲食品行业当前的发展现状，企业除了从战略调整、销售模式优化、内部组织优
      化、流程优化、绩效优化等方向去调整外，也需要在现有信息化基础上支撑营销的变革，并且利用信息化、数字化、智能化来驱动业务开展。</p>
    <div class="flex" style="justify-content: space-between">
      <div class="direction-box" v-for="(item,index) in direction" :key="index">
        <img :src="item.url">
        <div class="direction-text">
          <div>{{item.title}}</div>
          <p>{{item.text}}</p>
        </div>
      </div>
    </div>
    <!--   食品行业解决方案 -->
    <h2 class="wrapper-h2">食品行业解决方案</h2>
    <div>
      <div class="industry-box" v-for="(item,index) in industry" :key="index">
        <div class="industry-number">0{{index + 1}}</div>
        <div class="industry-text">
          <div>{{item.title}}</div>
          <p>{{item.text}}</p>
        </div>
      </div>
    </div>
  </div>
  <div class="button-wrapper">
    <mu-button large color="primary">即刻体验</mu-button>
  </div>
</div>
</template>

<script>
export default {
  name: "index",
  data(){
    return{
      foodList:[
        {
          url:require('../../../../public/image/solve/sj.webp'),
          title:'消费升级',
          text:'中国人均休闲食品消费与发达国家相差较大，加上中国人均GDP逐年增长，催生出的消费市场和欲望十分庞大'
        },
        {
          url:require('../../../../public/image/solve/cc.webp'),
          title:'消费层次多元化',
          text:'经济的发展，导致一、二线城市和三、四、五线城市乃至农村的消费差距不断缩小，消费层次呈多元化方向发展'
        },
        {
          url:require('../../../../public/image/solve/qt.webp'),
          title:'消费群体多元化',
          text:'消费场景的多元化导致老、中、青、幼多种消费群体对休闲食品的需求不断增加'
        },
        {
          url:require('../../../../public/image/solve/cj.webp'),
          title:'消费场景多元化',
          text:'经济的发展导致消费场景开始朝多元化方向发展，企业已经向居家、旅行、聚会、办公、团购等多种场景渗透。'
        },
        {
          url:require('../../../../public/image/solve/hd.webp'),
          title:'活动多元化',
          text:'经济的发展导致消费场景开始朝多元化方向发展，企业已经向居家、旅行、聚会、办公、团购等多种场景渗透'
        },
        {
          url:require('../../../../public/image/solve/qd.webp'),
          title:'渠道多元化',
          text:'因为消费需求大，当下的消费场所已经扩展到火车、飞机、餐饮、酒店、线上电商渠道、商场/办公楼无人售货等多种销售渠道。'
        },
      ],
      direction:[
        {
          url:require('../../../../public/image/solve/1.jpg'),
          title:'渠道精耕',
          text:'深化传统渠道应用，并通过信息化工具的连接，让厂家与经销商、终端的铺货、订单、活动等业务交互线上化，并且通过业务人员的服务来提升经销商和终端的满意度。'
        },
        {
          url:require('../../../../public/image/solve/2.jpg'),
          title:'营销创新',
          text:'面对不断变化的市场，需要工具来支撑营销端销售模式的创新带来的组织、流程、绩效等多方位业务信息化的需求。'
        },
        {
          url:require('../../../../public/image/solve/3.jpg'),
          title:'效率提升',
          text:'面对市场的不断变化，需要工具来提升工作人员的效率，高效是企业的生命力'
        },
        {
          url:require('../../../../public/image/solve/4.jpg'),
          title:'效率提升',
          text:'面对市场的不断变化，需要工具来提升工作人员的效率，高效是企业的生命力'
        },
      ],
      industry:[
        {
          title:'渠道经营服务提升',
          text:'通过系统支撑多级渠道的销售政策及达成反馈，实现订单、费用余额、费用核销、售后服务等业务的线上化来提升对经销商的服务；并且可连接线上渠道的订单，实现对整体渠道业务的管理分析。'
        },
        {
          title:'渠道经营服务提升',
          text:'通过系统支撑访销、车销业务的线上化，并且通过AI技术辅助业务拜访行为的提效，通过拜访采集、业务整改等行为评分应用驱动业务人员的拜访，提升销售人员的工作效率。'
        },
        {
          title:'商超业务提升',
          text:'借助系统实现对商超订单自动化处理，并进一步保证订单、对账、核销的准确性，提高工作效率和商超满意度，另外，通国对商超POS数据的采集，了解销售数据，针对数据做针对话处理。'
        },
        {
          title:'营销费用管理提升',
          text:'在系统中构建费用预算控制、活动申请、活动执行、核销上账、结案分析的费用闭环管理；并支撑对多种渠道的多种场景的市场活动的投放和控制，保障费用投入的准确性，避免费用盲区，并通过数据分析，保证费用精准投放。'
        }
      ]
    }
  }
}
</script>

<style scoped lang="less">
@import "./Food";
</style>