<template>
  <div class="wrapper">
    <div style="background-color: #F3F6FB">
      <h1 style="text-align: center;font-size: 18px">SFA移动访销系统</h1>
      <div style="display: flex;align-items: center;justify-content: center;padding: 50px">
        <s-f-a-chao-na :auditTrail=auditTrail></s-f-a-chao-na>
      </div>
      <!--    功能集成-->
      <div class="w1240" style="margin-bottom: 56px">
        <h2 style="text-align: center;margin: 50px 0 40px 0;font-weight: bold;font-size: 18px">功能集成</h2>
        <div class="wrapper-imgText">
          <div class="wrapper-imgText-box">
            <div v-for="(item,index) in integration" :key="index" class="wib-lin">
              <div class="wib-lin-title">{{ item.title }}</div>
              <div class="wib-lin-content">
                <p v-for="(item,index) in item.list" :key="index">{{ item }}</p>
              </div>
            </div>
          </div>
        </div>
      </div>
      <!--工作管理-->
      <div style="background-color: #fff;">
        <div class="w1240">
          <h2 style="text-align: center;font-size: 18px;font-weight: bold;padding: 40px 0 20px 0">工作管理</h2>
          <el-menu mode="horizontal" class="el-menu-demo el-menu" :default-active="activeIndex" active-text-color="#0D36A8">
            <el-menu-item  v-for="(item,index) in sfaWork" :key="index" :index="index" @click="tabChange(index)" class="mu-tab">
              {{ item.title }}
            </el-menu-item>
          </el-menu>
          <div class=" flex-sb" style="padding-bottom: 100px;padding-left: 100px">
            <div class="wrapper-content" v-for="(item,index) in sfaWork" :key="index"
                 :class="[activeIndex === index ? '' : 'none']">
              <div style="width: 400px;">
                <p>{{ item.list.title }}</p>
                <div class="wrapper-content-p" v-for="(item,index) in item.list.p"><span>{{ item }}</span></div>
              </div>
              <div class="wrapper-img">
                <img v-for="(item,index) in item.list.urls" :src="item.url" :style="item.width">
              </div>
            </div>
          </div>
        </div>
      </div>
      <!--    销售能力提升-->
      <div style="background-color: #fff">
        <h2 style="text-align: center;font-size: 18px;font-weight: bold;padding: 40px 0 20px 0">销售能力提升</h2>
        <el-menu mode="horizontal" class="el-menu-demo el-menu" :default-active="activeIndex2" active-text-color="#0D36A8">
          <el-menu-item v-for="(item,index) in sfaSale" :key="index" :index="index" @click="tabChange2(index)" class="mu-tab">
            {{ item }}
          </el-menu-item>
        </el-menu>
        <div style="background-color: #F3F6FB;padding-top: 30px;padding-bottom: 30px">
          <div class="w1240">
            <sfa-sale :auditTrail="sfaSaleFirst" v-if="activeIndex2 == 0"></sfa-sale>
            <sfa-r-o-i :auditTrail="sfaSaleTwo" :sfaSaleTwoImg="sfaSaleTwoImg"  v-if="activeIndex2 == 1"></sfa-r-o-i>
            <div style="align-items: center;justify-content: center;gap: 90px" class="flex-sb" v-if="activeIndex2 == 2">
              <div class="wrapper-meiText">
                <div class="wml-lin">
                  <p>销量分析</p>
                  <div><span>根据终端订单，统计终端销量增长与下滑情况，及时关注终端销量变动原因。</span></div>
                  <div><span>根据经销商销量信息（提供标准接口，支持从企业订单系统或ERP系统获取经销商订单统计信息），
          统计经销商的销量增长与下滑情况，结合终端销量与经销商销量可分期渠道库存情况。</span></div>
                </div>
                <div class="wml-lin">
                  <p>活跃度分析</p>
                  <div><span>根据终端陈列情况、活动执行情况、订单情况等分析终端活跃度。</span></div>
                  <div><span>针对不同活跃度的终端制定不同的政策来激活终端，提升终端销量。</span></div>
                </div>

              </div>
              <div class="wrapper-lin-mei">
                <img src="../../../../public/image/sfa/u1292.svg">
                <img src="../../../../public/image/sfa/u1293.png">
              </div>
            </div>
            <div  v-if="activeIndex2 == 3 "
                 style="display: flex;align-items: center;justify-content: center;margin-top: 50px;margin-bottom: 100px">
              <sfa-ments :auditTrail="sfaMents"></sfa-ments>
            </div>
            <div style="justify-content: center;gap: 100px" class="flex-sb" v-if="activeIndex2 == 4">
              <div class="wrapper-meiText" style="gap: 40px">
                <div class="wml-lin" style="display: flex;gap: 20px;">
                  <span class="wml-lin-mei">
                    <p>员工通讯录 —— 一键联系</p>
                    <span>不管天南海北，都能随时随地在SFA找到企业内部员工同时可查看员工绩效、员工画像等；</span>
                  </span>
                </div>
              </div>
              <div class="wrapper-lin-mei">
                <img src="../../../../public/image/sfa/u1342.svg">
                <img src="../../../../public/image/sfa/u1343.svg">
              </div>
            </div>
          </div>
        </div>
      </div>
      <!--AI提升工作效率-->
      <div style="background-color: #fff">
        <div class="w1240">
          <h2 style="text-align: center;font-size: 18px;font-weight: bold;padding: 40px 0 20px 0">AI提升工作效率</h2>
          <div style="text-align: center;font-size: 14px;line-height: 20px">
            <p>AI强势整合业务员的市场作业及管理者的审核⼯作，助⼒快消⾏业数字化转型更简单、便捷、准确、全⾯，大大提升工作效率。</p>
            <ul style="display: flex;gap: 20px;justify-content: center">
              <li>AI⾃拍识别</li>
              <li>AI智能货架盘点</li>
              <li>AI货架全⽅位洞察</li>
              <li>AI促销检查</li>
              <li>AI订货</li>
              <li>AI审核</li>
              <li>AI照⽚模糊检测</li>
            </ul>
          </div>
          <div style="display: flex;align-items: center;justify-content: space-around;padding: 88px 0">
            <div style="width:180px;padding:15px 5px;border-radius: 20px;box-shadow: 0px 0px 10px #e5e5e5">
              <img style="width: 170px" src="../../../../public/image/sfa/u969.png">
            </div>
            <div class="sfa-ai">
              <div class="sfa-ai-lin">
                <img src="../../../../public/image/sfa/AI.png">
              </div>
              <div class="sfa-aiList" v-for="(item,index) in aiList" :key="index">{{ item }}</div>
            </div>
            <img style="width: 170px" src="../../../../public/image/sfa/u1363.png">
          </div>
        </div>
      </div>
    </div>
    <div style="background-color: #fff">
      <div class="w1240">
        <div class="button-wrapper">
          <mu-button large color="primary">即刻体验</mu-button>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import SFAChaoNa from "@/views/duct/ductSFA/modules/SfaChaoNa";
import sfaSale from './modules/SfaSale'
import sfaROI from './modules/SfaRol'
import sfaMents from './modules/SfaMents'

export default {
  components: {SFAChaoNa, sfaSale, sfaROI, sfaMents},
  name: "ductSFA",
  data() {
    return {
      activeIndex:0,
      // tabIndex: 0,
      activeIndex2: 0,
      auditTrail: [
        {
          title: '齐道SFA',
          p: '移动访销',
          list: [
            {
              title: '工作助手',
              p: '便捷工具辅助完成日常工作',
            },
            {
              title: '销售赋能',
              p: '客户ROI数据支撑，优化销售方式',
            },
            {
              title: '学习成长',
              p: '随时随地学习成交最优路径',
            },
            {
              title: '高效沟通',
              p: '对内对外高效沟通',
            },
            {
              title: '激励体系',
              p: '便捷工具辅助完成日常工作',
            },
            {
              title: 'AI赋能',
              p: '门头、陈列等自动识别提高真实性',
            }
          ]
        }
      ],
      integration: [
        {
          title: '拜访中心',
          list: ['便捷指定/调整拜访计划', '自定义周期路线']
        },
        {
          title: '考勤管理',
          list: ['企业自定义打卡规则及排班微信步数辅助评估访店真实性']
        },
        {
          title: '客户管理',
          list: ['客户/终端信息完整记录便捷记录新客户']
        },
        {
          title: '客户拜访',
          list: ['拜访步骤标准化、结构化随时随地查看历史拜访记录']
        },
        {
          title: '活动执行',
          list: ['执行中活动检查提醒', '活动执行过程照片实时上传']
        },
        {
          title: '订单管理',
          list: ['及时提报终端补货需求', '产品需求实时汇总助力产销平衡']
        },
        {
          title: '协访管理',
          list: ['自定义协访计划', '协访记录实时上传']
        },
        {
          title: '商机管理',
          list: ['商机资源分级管理，利于资源分配全流程销售协同']
        },
      ],
      sfaWork: [
        {
          title: '拜访计划',
          list:
              {
                title: '避免业务人员市场上的盲动——拜访计划',
                p: ['客户拜访计划制定：', '支持在后台及移动端制定未来单日的拜访计划；',
                  '支持在后台及移动端制定未来一段时间的周期性拜访计划，可以按周生成也可以按月生成；', '支持在移动端添加当日临时拜访计划。'],
                urls: [
                  {
                    url: require('../../../../public/image/sfa/u983.png'),
                  },
                  {
                    url: require('../../../../public/image/sfa/u986.png')
                  },
                  {
                    url: require('../../../../public/image/sfa/u989.png'),
                  },
                ],
              }

        },
        {
          title: '考勤计划',
          list:
              {
                title: '一键打卡——考勤管理',
                p: ['后台支持多维度设置打卡规则，可针对员工不同工作性质设置不同的打卡规则，支持按固定时间打卡、按班次打卡或者自由上下班打卡；',
                  '考勤一键打卡，自动获取当前定位地址，支持根据打卡规则拍照及填写备注，还支持人脸识别打卡；',
                  '支持后台自定义配置打卡提醒，提醒员工按时打卡。'],
                urls: [
                  {
                    url: require('../../../../public/image/sfa/kaoqing.png'),
                    width: 'width:368px'
                  },
                ],
              }

        },
        {
          title: '客户管理',
          list:
              {
                title: '开拓/沉淀最宝贵的市场资源——客户管理',
                p: ['客户管理包含终端门店的新增、修改、审批以及潜在客户的新增；',
                  '可随时随地查看权限范围内的所有经销商、终端、直营门店等客户基本信息；',
                  '可随时随地创建和记录潜在客户，便于后续的跟进和开拓。'],
                urls: [
                  {
                    url: require('../../../../public/image/sfa/u1008.png'),
                  },
                  {
                    url: require('../../../../public/image/sfa/u1004.png'),
                  },
                ],
              }

        },
        {
          title: '客户拜访',
          list:
              {
                title: '从执行出绩效——客户拜访',
                p: ['根据制定好的拜访计划进行客户拜访执行；',
                  '针对由销售人员负责的经销商拜访，与市场终端的拜访所需要执行的拜访工作区分不同的拜访执行内容；',
                  '城市经理及主管主要进行经销商的管理，终端走访通常不作为重点，相关操作执行不作为必做项；',
                  '可在手机端直接添加当日临时拜访计划，用于应对临时拜访需求，保证了计划的灵活性。'],
                urls: [
                  {
                    url: require('../../../../public/image/sfa/u1026.png'),
                  },
                  {
                    url: require('../../../../public/image/sfa/u1016.png'),
                  },
                  {
                    url: require('../../../../public/image/sfa/u1021.png'),
                  },
                ],
              }

        },
        {
          title: '活动执行',
          list:
              {
                title: '活动资料实时采集——活动执行',
                p: ['支持在后台创建活动并指定执行客户，也支持提供标准接口，从企业费用管理系统同步活动信息到销售助手；',
                  '随时查看正在执行中的活动列表，及权限范围内的参与活动的客户；',
                  '拜访过程中可及时上传活动执行照片和描述，保障活动真实有效执行。',],
                urls: [
                  {
                    url: require('../../../../public/image/sfa/u1035.png'),
                  },
                  {
                    url: require('../../../../public/image/sfa/u1041.png'),
                  },
                ],
              }

        },
        {
          title: '订单管理',
          list:
              {
                title: '终端订单及时采集——订单管理',
                p: ['支持在后台设置安全库存，及时提醒业务员终端的补货需求；',
                  '业务员及时与终端沟通补货需求，及时采集终端补货需求，并传达给对应的经销商，避免终端缺货情况的发生；',
                  '通过终端订单统计终端的进货量，结合库存盘点信息，便于掌握终端销量数据。',],
                urls: [
                  {
                    url: require('../../../../public/image/sfa/u1059.png'),
                  },
                  {
                    url: require('../../../../public/image/sfa/u1058.png'),
                  },
                ],
              }

        },
        {
          title: '商机管理',
          list:
              {
                title: '线索数据精细化管理——商机管理',
                p: ['以客户为中心，跟踪、记录跟进进程，快速锁定高价值线索；', '线索分配策略，保证每条商机被高效的分配及跟进，避免抢客，维持有序的销售氛围；',
                  '自定义销售漏斗以及多维度数据报表，全面洞察各阶段客户跟进情况；', '一键快速添加到拜访计划，商机快速有效跟进。'],
                urls: [
                  {
                    url: require('../../../../public/image/sfa/u1070.png'),
                  },
                  {
                    url: require('../../../../public/image/sfa/u1079.svg'),
                  },
                  {
                    url: require('../../../../public/image/sfa/u1089.png'),
                  },
                  {
                    url: require('../../../../public/image/sfa/u1098.png'),
                  },
                ],
              }
        },
      ],
      sfaSale: ['学习成长', '客户ROI', '分析报告', '绩效管理', '对内协同'],
      sfaSaleFirst: [
        {
          title: '话术库',
          p: ['支持统一设置通用话术，也支持自定义维护常用话术；', '支持复制话术到企业微信中快捷回复客户消息；', '通过专业话术提供更专业的服务体验。'],
          icon: require('../../../../public/image/sfa/huashu.svg'),
        },
        {
          title: '销售技巧',
          p: ['便于员工随时学习销售技巧，提升个人销售能力；', '便于营造学习氛围，利于提升销售团队整体的工作效率和工作质量。'],
          icon: require('../../../../public/image/sfa/xiaoshou.svg'),
        },
        {
          title: '企业宣传册/产品宣传册/客户案例',
          p: ['便于新员工快速了解企业信息，快速掌握产品特征；', '支持分享企业宣传册及产品宣传册及客户案例到朋友圈或客户群，发掘和培养潜在客户。'],
          icon: require('../../../../public/image/sfa/anli.svg'),
        },
        {
          title: '直播培训',
          p: ['支持在销售助手小程序中进行实时的视频直播；', '支持在线通过视频直播的方式进行业务员培训。'],
          icon: require('../../../../public/image/sfa/u1260.png')
        },
      ],
      sfaSaleTwo: [
        {
          title: '销量同比',
          p: '随时查看自己负责客户的同比销量统计数据',
          icon: require('../../../../public/image/sfa/u1257.png')
        },
        {
          title: '费用同比',

          p: '随时查看自己负责客户的同比费用投入统计数据',
          icon: require('../../../../public/image/sfa/u1258.png')
        },
        {
          title: '客户ROI',
          p: '随时查看自己负责客户的月度投入产出统计数据',
          icon: require('../../../../public/image/sfa/u1259.png')
        },
        {
          title: '客户ROI排名',
          p: '随时查看自己负责客户的月度投入产出排名情况',
          icon: require('../../../../public/image/sfa/u1260.png')
        },
      ],
      sfaSaleTwoImg: [require('../../../../public/image/sfa/u1266.svg'), require('../../../../public/image/sfa/u1268.svg')],
      sfaMents: {
        title: '绩效管理',
        centerBg: require('../../../../public/image/sfa/u1295.svg'),
        centerIcon: require('../../../../public/image/sfa/u1296.svg'),
        bg: require('../../../../public/image/sfa/u1297.svg'),
        list: [
          {
            title: '工作计划完成',
            p: '客户拜访计划100%完成给予奖励。',
          },
          {
            title: '终端陈列达成',
            p: '终端陈列按要求执行并且完成度良好的给予奖励。',
          },
          {
            title: '数据采集',
            p: '竞品信息采集、终端销量数据采集、终端库存数据采集、窜货情况举报等给予奖励。',
          },
          {
            title: '窜货处罚',
            p: '被督导或客户投诉发现的窜货情况进行扣分处罚。',
          },
          {
            title: '销量目标达成',
            p: '负责客户的销量目标达成给予奖励。',
          },
          {
            title: '知识库学习',
            p: '知识库内知识学习完成给予奖励。',
          },

        ]
      },
      aiList: ['价签识别', '陈列场景识别', '人脸识别', '竞品识别', '门头识别', '终端陈列识别', '翻拍照片识别', '发票识别']
    }
  },
  methods: {
    tabChange(e) {
      this.activeIndex = e
      console.log(this.activeIndex)
    },
    tabChange2(e) {
      this.activeIndex2 = e
      console.log(this.activeIndex2)
    }
  },
}
</script>

<style scoped lang="less">
.wrapper {
  background-color: #F3F6FB;
  //margin-top: -18px;
  padding-top: 20px;
}

/*功能集成*/
.wrapper-imgText {
  box-sizing: border-box;

  .wrapper-imgText-box {
    display: flex;
    justify-content: space-between;

    .wib-lin {
      width: 144px;
      height: 166px;
      background-color: #fff;
      box-sizing: border-box;

      .wib-lin-title {
        width: 100%;
        height: 52px;
        display: flex;
        align-items: center;
        justify-content: center;
        font-size: 16px;
        color: #fff;
        background: #0d36a8;
      }

      .wib-lin-content {
        display: flex;
        flex-direction: column;
        align-items: center;
        padding: 5px 10px 0 10px;
        text-align: center;
        background-color: #fff;

        p {
          font-size: 12px;
          line-height: 1.5rem;
        }
      }
    }
  }
}

.flex-sb {
  display: flex;
  justify-content: space-between;
}

.wrapper-img {
  box-sizing: border-box;
  display: flex;
  gap: 30px;
  width: 800px;
  padding-right: 200px;
  flex-direction: row-reverse;;

  img {
    width: 109px;
  }
}

.wrapper-content {
  //padding-right: 100px;
  display: flex;
  justify-content: space-between;
  gap: 50px;
  width: 100%;

  p {
    font-weight: bold;
    line-height: 2;
    margin-bottom: 20px;
    color: #0D36A8;
  }

  i {
    margin-right: 20px;
    font-size: 30px;
  }

  span {
    font-size: 14px;
    line-height: 1.75rem;
  }

  .wrapper-content-p {
    margin-left: 16px;
    position: relative;
  }

  .wrapper-content-p::before {
    position: absolute;
    content: "";
    width: 8px;
    height: 8px;
    border-radius: 50%;
    background-color: #0D36A8;
    top: 9px;
    left: -14px;
  }
}

.wrapper-img-p {
  display: flex;
  flex-direction: column;
  gap: 50px;

  p {
    font-size: 20px;
  }
}
.el-menu{
  width: 1050px;
  margin:0 auto;
  margin-bottom: 23px;
  display: flex;
  align-items: center;
  justify-content: center;
}
.mu-tab {
  font-size: 16px;
  text-align: center;
  //line-height: 1.5rem;
}

.none {
  display: none;
}

//
.wrapper-lin-mei {
  display: flex;
  //width: 600px;
  align-items: center;
  justify-content: center;
  gap: 33px;

  img {
    width: 114px;
  }
}

.wrapper-meiText {
  display: flex;
  flex-direction: column;
  gap: 10px;

  .wml-lin {
    p {
      font-size: 18px;
      color: #0D36A8;
    }

    div {
      margin-bottom: 20px;
      width: 500px;
      margin-left: 20px;
      position: relative;
    }
    div::before{
      content: '';
      position: absolute;
      width: 8px;
      height: 8px;
      border-radius: 50%;
      background-color: #0d36a8;
      top: 7px;
      left: -14px;
    }
  }
}

//对内协同

.wml-lin-mei {
  display: block;
  p {
    font-size: 20px;
    font-weight: bold;
    color: #6F8EEA;
  }

span {
  display: block;
    margin-bottom: 10px;
    width: 500px;
    margin-left: 20px;
    position: relative;
  }
  span::before{
    content: '';
    position: absolute;
    width: 8px;
    height: 8px;
    border-radius: 50%;
    background-color: #0d36a8;
    top: 7px;
    left: -14px;
  }
}

.wml-lin-num {
  max-width: 100px;
  height: 100px;
  background-color: rgba(129, 211, 248, 1);
  color: #fff;
  border-radius: 20px;
  transform: rotate(45deg);
  position: relative;
}

.wml-lin-text {
  position: absolute;
  max-width: 100px;
  height: 100px;
  top: 0;
  left: 0;
  display: flex;
  align-items: center;
  justify-content: center;

  span {
    font-size: 20px;
    font-weight: bold;
    font-family: YuYangT3;
    color: #fff;
  }
}

//AI
.sfa-ai {
  width: 410px;
  height: 410px;
  box-sizing: border-box;
  border: 4px solid #D4EDFF;
  border-radius: 50%;
  display: flex;
  align-items: center;
  justify-content: center;
  position: relative;

  .sfa-ai-lin {
    width: 220px;
    height: 220px;
    box-sizing: border-box;
    border: 8px solid #D4EDFF;
    border-radius: 50%;
    display: flex;
    align-items: center;
    justify-content: center;
  }

  .sfa-aiList {
    width: 88px;
    height: 88px;
    box-sizing: border-box;
    border-radius: 50%;
    background-color: #78C7FF;
    color: #fff;
    text-align: center;
    line-height: 88px;
    position: absolute;
    top: -44px;
  }

  .sfa-aiList:nth-of-type(3) {
    top: 20px;
    right: 0;
  }

  .sfa-aiList:nth-of-type(4) {
    top: 160px;
    right: -44px;
  }

  .sfa-aiList:nth-of-type(5) {
    top: 290px;
    right: 0;
  }

  .sfa-aiList:nth-of-type(6) {
    top: 360px;
    right: 160px;
  }

  .sfa-aiList:nth-of-type(7) {
    top: 290px;
    left: 0px;
  }

  .sfa-aiList:nth-of-type(8) {
    top: 160px;
    left: -44px;
  }

  .sfa-aiList:nth-of-type(9) {
    top: 20px;
    left: 0px;
  }
}

.button-wrapper {
  text-align: center;

  .mu-button {
    margin: 50px;
    vertical-align: middle;
  }
}
.none{
  display: none;
}
</style>
