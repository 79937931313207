<template>
  <div style="position: relative">
    <div v-for="(item,index) in auditTrail" :key="index" class="wrapper">
      <div class="wrapper-box">
        <div class="wrapper-box-center">
          <p class="wrapper-box-p" style="font-weight: bold">{{ item.title }}</p>
          <p class="wrapper-box-p ">{{ item.p }}</p>
        </div>
      </div>
      <div v-for="(item,index) in item.list" :key="index" class="wrapper-lin">
        <p><span>{{ item.title }}</span></p>
      </div>
    </div>
    <div v-for="(item,index) in auditTrail" :key="index">
      <div v-for="(item,index) in item.list" :key="index" class="wrapper-mei">
        <div class="auditTrail-before">{{index + 1}}</div>
        <div>
          <p class="wrapper-mei-p1"><span>{{ item.title }}</span></p>
          <p class="wrapper-mei-p2"><span>{{ item.p }}</span></p>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  name: "SFAChaoNa",
  props: {
    auditTrail: {
      type: Array,
    },
  },
}
</script>

<style scoped lang="less">

.wrapper {
  position: relative;

  .wrapper-box {
    display: flex;
    align-items: center;
    justify-content: center;
    flex-direction: column;
    width: 232px;
    height: 232px;
    box-sizing: border-box;
    border: 6px solid #D4EDFF;
    border-radius: 50%;

    .wrapper-box-center {
      width: 90px;
      height: 90px;
      display: flex;
      align-items: center;
      justify-content: center;
      flex-direction: column;
      background-color: #78C7FF;
      border-radius: 50%;

      .wrapper-box-p {
        font-size: 16px;
        color: #fff;
      }
    }


  }
}

.wrapper-lin {
  width: 72px;
  height: 72px;
  position: absolute;
  display: flex;
  align-items: center;
  justify-content: center;
  background-color: #78C7FF;
  border-radius: 50%;

  p {
    font-size: 14px;
    color: #fff;
  }
}

.wrapper-lin:nth-of-type(2) {
  //position: absolute;
  top: -30px;
  left: 80px;

}

.wrapper-lin:nth-of-type(3) {
  //position: absolute;
  top: 33px;
  left: -13px;
}

.wrapper-lin:nth-of-type(4) {
  //position: absolute;
  top: 33px;
  left: 183px;
}

.wrapper-lin:nth-of-type(5) {
  //position: absolute;
  top: 144px;
  left: -14px;
}

.wrapper-lin:nth-of-type(6) {
  //position: absolute;
  top: 144px;
  left: 183px;
}

.wrapper-lin:nth-of-type(7) {
  //position: absolute;
  top: 190px;
  left: 80px;
}

.wrapper-mei {
  width: 250px;
  display: flex;
  gap: 6px;
  position: absolute;
  .auditTrail-before{
    width: 16px;
    height: 16px;
    background: #78c7ff;;
    border-radius: 50%;
    color: #fff;
    display: flex;
    align-items: center;
    justify-content: center;
    font-size: 12px;
  }

  .wrapper-mei-p1 {
    font-weight: bold;
    font-size: 14px;
  }

  .wrapper-mei-p2 {
    color: #808080;
  }
}

.wrapper-mei:nth-of-type(1) {
  top: 42px;
  left: -300px;
}
.wrapper-mei:nth-of-type(2) {
  top: 102px;
  left: -300px;
}
.wrapper-mei:nth-of-type(3) {
  top: 162px;
  left: -300px;
}
.wrapper-mei:nth-of-type(4) {
  top: 42px;
  right: -366px;
}
.wrapper-mei:nth-of-type(5) {
  top: 102px;
  right: -366px;
}
.wrapper-mei:nth-of-type(6) {
  top: 162px;
  right: -366px;
}
</style>