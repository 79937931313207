<template>
<div class="wrapper">
  <div v-for="(item,index) in auditTrail" :key="index" class="lin-wrapper">
    <div>
      <img :src="item.icon" class="lin-icon">
    </div>
    <div>
      <p class="lin-title">{{item.title}}</p>
      <p class="lin-text-p" v-for="(item,index) in item.p" :key="index">{{item}}</p>
    </div>
  </div>
</div>
</template>

<script>
export default {
  name: "sfaSale",

  props: {
    auditTrail: {
      type: Array,
    },
  },
  data(){
    return{

    }
  },
}
</script>

<style scoped lang="less">
.wrapper{
  display: flex;
  flex-wrap: wrap;
  justify-content: space-between;
  padding: 40px;
  gap: 26px;
}
.lin-wrapper{
  display: flex;
  gap: 10px;
  width: 500px;
  .lin-icon{
    width: 24px;
  }
  .lin-title{
    font-size: 18px;
    color: #0D36A8;
  }
  .lin-text-p{
    line-height: 2;
    margin-left: 20px;
    position: relative;
  }
  .lin-text-p::before{
    content: '';
    position: absolute;
    width: 8px;
    height: 8px;
    border-radius: 50%;
    background-color: #0d36a8;
    top: 9px;
    left: -14px;
  }

}
</style>