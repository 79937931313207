<template>
<div>
  <div v-for="(item,index) in auditTrail" :key="index" class="wrapper">
    <div class="wrapper-box">
      <img src="../../../../../public/image/MDS/duizhang.svg">
      <p class="wrapper-box-p">{{ item.title }}</p>
    </div>
    <div v-for="(item,index) in item.list" class="wrapper-lin">
      <p>{{ item }}</p>
    </div>
  </div>
</div>
</template>

<script>
export default {
  name: "DMScustomer",
  props: {
    auditTrail: {
      type: Array,
    },
  },
  data(){
    return{
    }
  },
}
</script>

<style scoped lang="less">
.wrapper {
  position: relative;
  .wrapper-box {
    width: 230px;
    height: 135px;
    display: flex;
    align-items: center;
    justify-content: center;
    flex-direction: column;
    position: relative;
    gap: 20px;
    .wrapper-box-p {
      font-size: 18px;
      color: #0D36A8;
      font-weight: bold;
    }
  }
}

.wrapper-lin {
  width: 72px;
  height: 72px;
  position: absolute;
  top:48px;
  left: -70px;
  display: flex;
  align-items: center;
  justify-content: center;
  background-color: #78C7FF;
  border-radius: 50%;
  p{
    color: #fff;
    font-weight: 500;
  }
}
.wrapper-lin:nth-child(2){
  //position: absolute;
  top:-32px;
  left: -30px;

}
.wrapper-lin:nth-of-type(3) {
  //position: absolute;
  top: -86px;
  left: 46px;
}
.wrapper-lin:nth-of-type(4) {
  //position: absolute;
  top: -86px;
  left: 137px;
}
.wrapper-lin:nth-of-type(5) {
  //position: absolute;
  top: -32px;
  left: 210px;
}
.wrapper-lin:nth-of-type(6) {
  //position: absolute;
  //top: 77px;
  left: 250px;
}

</style>
