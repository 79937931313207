<template>
  <div>
    <!--    轮播-->
    <mu-carousel transition="fade" style="width: 100%" :hide-indicators="false">
      <mu-carousel-item v-for="(item,index) in bannerList" :key="index" style="color: #fff">
        <img :src="item.url">
      </mu-carousel-item>
    </mu-carousel>
    <!--  正文内容-->
    <div style="background-color: #fff">
      <div class="w1240 wrapper">
        <div v-for="(item,index) in HomeMentList" :key="index" class="wrapper-list" @mouseleave="mouseleave(index)"
             @mouseenter="mouseenter(index)">
          <div class="wrapper-list-box" :class="[index === moveIndex ? 'none':'block']">
            <div class='title-icon'>
              <img :src="item.icon" style="width: 40px">
              <h3 style="color: #fff">{{ item.title }}</h3>
            </div>
            <div class="wrapper-text-p">
              <p>{{ item.p1 }}</p>
              <p>{{ item.p2 }}</p>
              <p>{{ item.p3 }}</p>
            </div>
          </div>
          <div :class="[index === moveIndex ? 'block':'none']">
            <div class="details">
              <div>
                <div>{{ item.list.title }}</div>
                <p v-for="(item,index) in item.list.p" style="font-size: 13px">{{ item }}</p>
              </div>
              <p class="linkTo" @click="clickList(item.index)">查 看</p>
            </div>
          </div>
        </div>
      </div>
      <div class="w1240" style="height: 310px;display: flex;align-items: center;justify-content: center">
<!--        <transition name="el-fade-in-linear">-->
        <div v-if="index == 0">
          <div class="w1240" style="display: flex;justify-content: center;gap: 50px">
            <div style="width: 380px;margin-top: 30px">
              <div style="font-size: 16px;font-weight: bold">营销费用管理</div>
              <p style="text-indent:2em">齐道CRM通过上下游企业协同能力，实现了品牌商对经销商的数据洞察，同时经销商也可以轻松获取品牌商的产品信息、实时政策，⾃我数字化管理亦可通过齐道CRM平台实现</p>
              <div class="btnAbout" @click="clickList(101)">了解更多</div>
            </div>
            <img src="../../../public/image/tpm-feiyong.png">
          </div>
        </div>
<!--        </transition>-->
<!--        <transition name="el-fade-in-linear">-->
        <div v-if="index == 1">
          <div class="w1240" style="display: flex;justify-content: center;gap: 50px">
            <div style="width: 380px;margin-top: 30px">
              <div style="font-size: 16px;font-weight: bold">客户管理</div>
              <p style="text-indent:2em">
                齐道CRM通过上下游企业协同能力，实现了品牌商对经销商的数据洞察，同时经销商也可以轻松获取品牌商的产品信息、实时政策，⾃我数字化管理亦可通过齐道CRM平台实现。</p>
              <div class="btnAbout" @click="clickList(102)">了解更多</div>
            </div>
            <img src="../../../public/image/dms-kehu.png">
          </div>
        </div>
<!--        </transition>-->
<!--        <transition name="el-fade-in-linear">-->
        <div v-if="index == 2">
          <div class="w1240" style="display: flex;justify-content: center;gap: 50px">
            <div style="width: 380px;margin-top: 30px">
              <div style="font-size: 16px;font-weight: bold">销售管理</div>
              <p style="text-indent:2em">终端拜访是快消⼀线业务员最⽇常的⼯作，有效的终端拜访动作，既保证了产品陈列和⼴告宣传效果，⼜起到获取竞品动态、抢占优质活动资源的⽬的。</p>
              <p style="text-indent:2em">齐道SFA还讲考勤管理、订单管理等多种业务结合，业务工作做出高效计划；</p>
              <div class="btnAbout" @click="clickList(103)">了解更多</div>
            </div>
            <img src="../../../public/image/u309.svg">
          </div>
        </div>
<!--        </transition>-->
<!--        <transition name="el-fade-in-linear">-->
        <div v-if="index == 3">
          <div class="w1240" style="display: flex;justify-content: center;gap: 50px">
            <div style="width: 380px;margin-top: 30px">
              <div style="font-size: 16px;font-weight: bold">直营应收管理</div>
              <p style="text-indent:2em">目前全国性KA卖场有近20家，拥有近4000家门店，KA渠道有着承载着零售企业提升品牌知名度、提高销售份额及增加市场占有率的重要作用；
                提升KA业务的效率，是企业获得业务增长的关键。</p>
              <div class="btnAbout" @click="clickList(103)">了解更多</div>
            </div>
            <img width="440px" src="../../../public/image/1117.png">
          </div>
        </div>
<!--        </transition>-->
      </div>

      <!--    行业痛点-->
      <div class="wrapper-painPoint">
        <div class="w1240">
          <h2>
            快销品行业痛点
            <img src="../../../public/image/zhuangshikuang.png">
          </h2>
          <div class="wapper-p-lin">
            <div v-for="(item,index) in painPoint" :key="index" class="wrapper-p-lin-L">
              <div class="wrapper-icon">
                <img :src="item.icon">
                <div>{{ item.title }}</div>
              </div>
              <div
                  style="display: flex;align-items: center;justify-content: center;flex-direction: column;height: 140px;font-size: 14px">
                <p v-for="(item,index) in item.p" :key="index">{{ item }}</p>
              </div>
            </div>
          </div>
        </div>
      </div>
      <!--    产品服务-->
      <div class="product" style="position: relative">
        <div class="product-title">
          <h2>产品服务
            <img src="../../../public/image/chanpinzhuangshi.svg"></h2>
          <p>四年专业领域深耕</p>
        </div>
        <div class="product-Img">
          <div>
            <ul class=" product-ul ">
              <li v-for="(item,index) in our" :key="index">
                <div style="display: flex;flex-direction: column;align-items: center;justify-content: center">
                  <img :src="item.icon">
                  <p class="product-num">{{ item.num }}</p>
                  <p class="product-title">{{ item.title }}</p>
                </div>
              </li>
            </ul>
          </div>
        </div>
      </div>
      <!--    解决方案-->
      <div class="solution">
        <div style="text-align: center">
          <h2 class="solution-h2">解决方案
            <img src="../../../public/image/jiejuezhuangshi.svg">
          </h2>
          <span>齐道可解决各行业在市场营销、渠道管理、业务员管理、直营对账方面的问题</span>
        </div>
        <div class="w1240 solution-lin">
          <div v-for="(item,index) in solution" :key="index" class="solution-lin-list">
            <div class="solution-title">{{ item.title }}</div>
            <ul>
              <li v-for="(item,index) in item.list" class="solution-li">
                <p>{{ item.text }}</p>
              </li>
            </ul>
          </div>
        </div>
      </div>
      <!--    服务客户-->
      <div class="w1240" style="padding:100px 0">
        <h2 class="service-h2">合作伙伴
          <img src="../../../public/image/jiejuezhuangshi.svg">
        </h2>
        <div class="service">
          <div v-for="(item,index) in serviceList" :key="index" class="service-img">
            <img :src="item.icon" style="width: 188px;height: 120px">
          </div>
        </div>
      </div>
    </div>
  </div>


</template>

<script>
import {bannerList} from '@/publicData/bannerList'
import {HomeMentList, painPoint, solution} from '@/publicData/HomeMentList'

export default {
  name: "mainHomePage",
  data() {
    return {
      index: 0,
      bannerList,
      HomeMentList,
      painPoint,
      solution,
      moveIndex: "",
      our: [
        {
          icon: require('../../../public/image/qiye.svg'),
          title: '企业总数',
          num: '20+'
        },
        {
          icon: require('../../../public/image/yonghu.svg'),
          title: '用户总数',
          num: '25,000+'
        },
        {
          icon: require('../../../public/image/ceshi.svg'),
          title: '累计服务客户',
          num: '5,000+'
        },
        {
          icon: require('../../../public/image/neizhi.svg'),
          title: '解决方案',
          num: '30+'
        },
      ],
      serviceList: [
        {
          icon:require('../../../public/image/wdl-logo.jpg',)
        },
        {
          icon:require('../../../public/image/mlt-logo.jpg',)
        },
        {
          icon:require('../../../public/image/sn.jpg',)
        },
        {
          icon:require('../../../public/image/hdl-logo.jpg',)
        },
        {
          icon:require('../../../public/image/yl-logo.jpg',)
        },
        {
          icon:require('../../../public/image/hs-logo.jpg',)
        },
        {
          icon:require('../../../public/image/bbl-logo.png',)
        },
        {
          icon:require('../../../public/image/ndxy-logo.png',)
        },
        {
          icon:require('../../../public/image/xyl-logo.png',)
        },
        {
          icon:require('../../../public/image/zt-logo.png',)
        },
        {
          icon:require('../../../public/image/jl-logo.png',)
        },
        {
          icon:require('../../../public/image/sll.png',)
        },
        {
          icon:require('../../../public/image/dbdz.jpg',)
        },
        {
          icon:require('../../../public/image/huishi-logo.jpg',)
        },
      ],
    }
  },
  methods: {
    clickList(index){
      // this.checkindex = index
      // this.index=0
      this.$emit('change',index);
      document.documentElement.scrollTop = 0;
    },
    mouseleave(index) {
      this.moveIndex = ''
      // console.log(this.moveIndex, index)
    },
    mouseenter(index) {
      this.moveIndex = index
      this.index = index
      // console.log(this.moveIndex, index)
    }
  }
}
</script>

<style scoped lang="less">
.banner-text {
  position: relative;
  top: 100px;
  left: 0;
  line-height: 3;

  h2 {
    font-size: 30px;
    font-weight: bold;
    //font-family: YuYangT3;
  }

  p {
    font-size: 18px;
  }
}

//四管理列表
.wrapper {
  display: flex;
  justify-content: space-between;
  margin-bottom: -60px;
  position: relative;
  top: -120px;
  z-index: 99999;

  .wrapper-list {
    box-sizing: border-box;
    //box-shadow: 0 0 30px #eee;
    width: 240px;
    height: 240px;
    text-align: center;
    cursor: pointer;
    transition: 0.5s;
    background-color: #fff;
    border-radius: 4px 4px 0 0;

    .wrapper-list-box {
      //display: none;
    }


    .title-icon {
      display: flex;
      align-items: center;
      justify-content: center;
      flex-direction: column;
      gap: 16px;
      width: 100%;
      height: 120px;
      background-color: #0D36A8;
      border-radius: 4px 4px 0 0;
    }

    .wrapper-text-p {
      width: 100%;
      height: 120px;
      box-sizing: border-box;
      display: flex;
      align-items: center;
      justify-content: center;
      flex-direction: column;
      border: 1px solid #E5E5E5;
      border-top: none;
      border-radius: 0 0 4px 4px;

      p {
        line-height: 1.75rem;
        font-size: 14px;
      }
    }

    //详情
    .details {
      display: flex;
      height: 240px;
      flex-direction: column;
      justify-content: space-between;
      padding: 10px;
      border: 1px solid #E5E5E5;
      border-radius: 4px;

      .linkTo {
        font-weight: bold;
        font-size: 12px;
        color: rgba(1, 112, 254, 0.8);
      }

      .linkTo:hover {
        color: rgba(1, 112, 254, 1);
        transition: 0.5s;
      }
    }

  }

  //.wrapper-list:hover {
  //  transform: translateY(-10px);
  //}
}

//行业痛点
.wrapper-painPoint {
  background-color: #0D36A8;
  width: 100%;
  box-sizing: border-box;
  padding: 80px;

  h2 {
    font-size: 18px;
    font-weight: bold;
    text-align: center;
    color: #fff;
    //font-family: YuYangT3;
    position: relative;
    margin-bottom: 60px;

    img {
      position: absolute;
      top: -25px;
      left: 540px;
    }
  }

  .wapper-p-lin {
    display: flex;
    justify-content: space-around;
    align-items: center;

    .wrapper-p-lin-L {
      display: flex;
      flex-direction: column;
      align-items: center;
      text-align: center;
      width: 280px;
      height: 280px;
      background-color: #fff;
      border-radius: 4px;

      p {
        line-height: 2;
      }

      .wrapper-icon {
        width: 280px;
        height: 140px;
        box-sizing: border-box;
        background-image: url("../../../public/image/xietong.png");
        display: flex;
        align-items: center;
        justify-content: center;
        flex-direction: column;
        gap: 16px;

        div {
          font-size: 18px;
          color: #fff;
        }

        img {
          //width: 100%;
        }
      }
    }

    .wrapper-p-lin-L:nth-child(2) .wrapper-icon {
      background-image: url("../../../public/image/zhongduan.png");
    }

    .wrapper-p-lin-L:nth-child(3) .wrapper-icon {
      background-image: url("../../../public/image/qudao.png");
    }
  }


}

//产品服务
.product {
  width: 100%;
  height: 450px;
  box-sizing: border-box;
  background: url('../../../public/image/chanpinBG.jpg')  top / cover no-repeat;
  padding: 90px 0;

  .product-title {
    display: flex;
    align-items: center;
    justify-content: center;
    gap: 16px;

    h2 {
      font-weight: bold;
      font-size: 18px;
      text-align: center;
      position: relative;
    }

    p {
      font-size: 12px;
    }

    img {
      position: absolute;
      top: -29px;
      left: -19px;
    }
  }


  .product-Img {
    width: 800px;
    margin: 0 auto;
    padding: 60px 0;
    box-sizing: border-box;
    border-bottom: 2px dashed #888;

    h3 {
      font-weight: bold;
      font-size: 30px;
      //font-family: YuYangT3;
      text-align: center;
      margin-bottom: 50px;
    }

    .product-ul {
      display: flex;
      justify-content: space-evenly;
      position: relative;

      .product-title {
        font-size: 12px;
        font-weight: bold;
        color: #999999;
      }

      .product-num {
        font-size: 18px;
        font-weight: bold;
        //font-family: YuYangT3;
      }
    }
  }
}

//解决方案
.solution {
  background-image: url("../../../public/image/jiejueBG.jpg");
  padding: 80px 0;

  .solution-h2 {
    font-size: 18px;
    position: relative;
    color: #fff;
    text-align: center;
    width: 130px;
    margin: 0 auto;
    padding-bottom: 20px;

    img {
      position: absolute;
      top: -25px;
      left: 10px;
    }
  }

  span {
    color: #fff;
  }
}

.solution-lin {
  display: flex;
  justify-content: space-between;
  margin-top: 36px;

  .solution-lin-list {
    box-sizing: border-box;
    width: 240px;

    .solution-title {
      width: 240px;
      height: 40px;
      background: linear-gradient(180deg, #f1d444, #dab200 44%, #d2b000 47%, #cfab00 47%, #d3af00 48%, #d6b000);
      //border-radius: 12px;
      display: flex;
      align-items: center;
      justify-content: center;
      color: #fff;
      font-weight: bold;
      font-size: 16px;
      position: relative;
      z-index: 100;
      border-radius:8px/30px;
    }

    ul {
      padding: 70px 72px;
      width: 100%;
      height: 320px;
      position: relative;
      top: -40px;
      z-index: 0;
      background: url("../../../public/image/jiejueBG.svg")   center / cover no-repeat;

      .solution-li {
        text-align: left;
        color: #E8C20F;
        line-height: 32px;
        font-size: 14px;
      }
    }

  }
}

// 服务客户
.service-h2 {
  font-size: 18px;
  position: relative;
  text-align: center;
  width: 130px;
  margin: 0 auto;
  padding-bottom: 20px;

  img {
    position: absolute;
    top: -25px;
    left: 10px;
  }
}

.service {
  display: flex;
  flex-wrap: wrap;
  width: 1036px;
  margin: 0 auto;
  gap: 24px;
  margin-top:40px;
}
.service-img:nth-of-type(12){
  border: 1px solid #e5e5e5;
  border-radius: 4px;
  box-sizing: border-box;
}

.none {
  display: none;
  transition: 0.5s;
}

.block {
  display: block;
  transition: 0.5s;
}

.btnAbout {
  font-weight: bold;
  background: #ffffff;
  border: 1px solid #0d36a8;
  border-radius: 4px;
  padding: 4px 0;
  width: 88px;
  box-sizing: border-box;
  text-align: center;
  cursor: pointer;
  margin-top: 50px;
  color: #0d36a8;
}
</style>
