<template>
<div>
  <div class="banner"></div>
  <div class="w1100">
    <h3 style="text-align: center;margin-top: 26px;margin-bottom: 16px;font-size: 16px">成都齐道科技有限公司</h3>
    <div class="wrapper">
      <p>成都齐道科技有限公司成立于2021年8月，注册资金500万。本公司是面向领先的企事业等组织的运营管理、专业领域
        管理提供数智化产品与服务，如DMS经销商系统、TPM营销费用管理系统、SFA移动访销系统、一物一码溯源系统、深度分销系统等众多企业信息化管
        理解决方案，目前已服务的客户包括米老头、海底捞、飞利浦、惠氏、五得利、思念等国内一线企业。公司致力于为中大型企业提供优质的定制方案，
        为小型企业提供快速落地的解决方案，帮助企业创造更多的价值。</p>
      <p>公司拥有一支稳固、拼搏的团队，从商务接洽、需求调研、产品设计、系统开发、测试等多维度提供专业服务，力争每一项工作都做到极致，同时，凭
        借丰富的CRM项目实施经验与产品经验，精准分析企业在管理过程中面临的痛点及需求，为客户带来更专业的服务体验，同时公司将从不同项目上汲取经
        验，不断改善服务和产品，提供更专业的互联网服务，做国内企业数智化管理解决方案的前行者！</p>
      <p style="margin-bottom: 70px">齐道深耕快消品行业CRM，以连接业务，连接人，连接系统，实现以客户为中心，企业内部和上下游业务的高效协作。齐道坚持行业化战略，为快消品
        行业的大中型企业提供深度行业化的产品、方案和服务，助力企业通过营销、销售、服务全业务链一体化实现持续增长。</p>
    </div>
  </div>
</div>
</template>

<script>
export default {
  name: "index"
}
</script>

<style scoped lang="less">
.banner {
  width: 100%;
  height: 360px;
  background: url('../../../public/image/aboutUSbg.jpg') center/cover no-repeat;
}
.wrapper{
  text-align: justify;
  text-indent:2rem;
  line-height: 1.75rem;
  p{
    margin-bottom: 26px;
    font-size: 16px;
  }
}
</style>
