let bannerList = [
    {
        url:require('../../public/image/banner1.jpg'),
    },
    {
        url:require('../../public/image/banner2.jpg'),
    },
    // {
    //     url:require('../../public/image/banner3.jpg'),
    // },
]

export {bannerList}
