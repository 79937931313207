let HomeMentList = [
    {
        icon:require('../../public/image/yingxiao.png'),
        background:require('../../public/image/u142.png'),
        title:'营销费用管理',
        p1:'活动预算、活动方案',
        p2:'活动举证、活动检核',
        p3:'费用核销、ROI分析',
        moreText:'齐道TPM活动费⽤管理融⼊PDCA闭环管理模型，覆盖营销活动全流程，帮助企业精打细算，找到提升价值创造的关键点。',
        index:101,
        list:{
            title:'TPM',
            p:[
                '提升营销费用规划对客户透明度（活动明晰、执行要求、核销条件清楚）',
                '支持费用终端化（核查简便、核销迅速）（核查简便、核积累',
                '积累客户与门店信息；提升日常客户、门店拜访效率',
                '提升行销计划、客户拜访及核查效率',
                '提供品类损益；推进财务核算标准化、信息相关性、及时性'
            ]
        }
    },
    {
        icon:require('../../public/image/kehu.png'),
        background:require('../../public/image/u142.png'),
        title:'客户管理',
        p1:'渠道订货、推荐订货',
        p2:'费用对账、政策下发',
        p3:'进销存管理...',
        index:102,
        list:{
            title:'DMS',
            p:[
                '专注企业服务，通过数据挖掘赋能到企业，透过更专业的分析为企业提供高效准确的全场景解决方案，共同迎接新的挑战！',
                '以消费者体验为中心的数据驱动的零售形态！',
            ]
        }
    },
    {
        icon:require('../../public/image/xiaoshou.png'),
        background:require('../../public/image/u142.png'),
        title:'销售管理',
        p1:'片区制定、线路制定',
        p2:'拜访八步、行为激励',
        p3:'路线拜访、铺货/协防',
        index:103,
        list:{
            title:'SFA',
            p:[
                '通过计划性拜访，强化拜访工作管控，加强终端客情维系，还可规范业务员日常工作流程，防止无效工作；',
            ]
        }
    },
    {
        icon:require('../../public/image/zhiying.png'),
        background:require('../../public/image/u142.png'),
        title:'直营应收管理',
        p1:'直营客户需求难把控',
        // router:'/ductKMS',
        list:{
            title:'KMS',
            p:[
                "目前全国性KA卖场有近20家，拥有近4000家门店，KA渠道有着承载着零售企业提升品牌知名度、提高销售份额及增加市场占有率的重要作用；通过对KA订单 、 验收单 、 销售数据 、库存数据 、结算数据 、退货单 、变价单 、 原始订单等各个维度的管理，提升订单、对账准确率与效率。"
            ]
        }
    },
]
let painPoint = [
    {
        icon:require('../../public/image/u327.png'),
        title:'经销商协同难',
        p:['政策下发效率低；','执⾏情况追踪难；','费⽤对账⽆头绪。']
    },
    {
        icon:require('../../public/image/u326.png'),
        title:'终端表现管控难',
        p:['⽆法掌握精准的终端数量；','⽆法获取商品铺市是否达标；','⽆法知晓竞品铺货情况。']
    },
    {
        icon:require('../../public/image/u328.png'),
        title:'渠道通路数据收集难',
        p:['⽆法了解经销商进销存数量；','⽆法分析经销商经营健康度；','⽆法获取准确的商品铺市率。']
    }

]
let solution = [
    {
        title:'通用解决方案',
        list:[
            {
                icon:require('../../public/image/u193.png'),
                text:'营销费用管理',
            },
            {
                icon:require('../../public/image/u196.png'),
                text:'客户管理',
            },
            {
                icon:require('../../public/image/u199.png'),
                text:'移动访销',
            },
            {
                icon:require('../../public/image/u202.png'),
                text:'直营商超管理',
            },
            {
                icon:require('../../public/image/u205.png'),
                text:'B2b门店管理',
            },
            {
                icon:require('../../public/image/u208.png'),
                text:'促销员管理',
            },
        ]
    },
    {
        title:'乳业解决方案',
        list:[
            {
                icon:require('../../public/image/u216.png'),
                text:'常温奶',
            },
            {
                icon:require('../../public/image/u214.png'),
                text:'低温奶',
            },
            {
                icon:require('../../public/image/u217.png'),
                text:'婴幼儿奶粉',
            },
        ]
    },
    {
        title:'食品解决方案',
        list:[
            {
                icon:require('../../public/image/u224.png'),
                text:'速冻食品',
            },
            {
                icon:require('../../public/image/u225.png'),
                text:'速食品',
            },
            {
                icon:require('../../public/image/u223.png'),
                text:'渠道管理',
            },
        ]
    },
    {
        title:'酒类解决方案',
        list:[
            {
                icon:require('../../public/image/u331.png'),
                text:'白酒方案',
            },
            {
                icon:require('../../public/image/u335.png'),
                text:'啤酒方案',
            },
            {
                icon:require('../../public/image/u338.png'),
                text:'一物一码',
            },
            {
                icon:require('../../public/image/u342.png'),
                text:'数字营销',
            },
            {
                icon:require('../../public/image/u346.png'),
                text:'私域流量',
            },
        ]
    },
]

export {HomeMentList,painPoint,solution}
