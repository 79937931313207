let TPMSystem = [
    {
        title:'预算中心',
        flow:'TPM',
        list:['预算制定&核算','预算调整','预算管控']
    },
    {
        title:'活动中心',
        flow:'TPM',
        list:['预算使用自动核算','活动模板设定','活动申请']
    },
    {
        title:'执行中心',
        flow:'SFA',
        list:['门店数据采集','考勤、总结','活动执行']
    },
    {
        title:'支付中心',
        flow:'TPM',
        list:['核销申请','费用上账']
    },
    {
        title:'销售订单',
        flow:'DMS',
        list:['货补池','奖励池','SAP余额','现金']
    },
]
let TPMData = [
    {
        list:['费用预算管理','期初费用预算','客户预算划分',]
    },
    {
        list:['活动主体','活动形式','费用分配','预算占用']
    },
    {
        list:['实时定位','仅调用相机','AI分析','销量数据采集']
    },
    {
        list:['一活动多核销','活动回顾修正','核销资料汇总','自动上账','发票信息录入']
    },

]

export {TPMSystem,TPMData}