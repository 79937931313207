<template>
  <div>
    <!--    页头-->
    <div>
      <div class="m1240"><div class="grid-cell">
        <div class="home-butbox w1240">
          <div class="home-butleft">
            <img src=/image/log.png style="width: 80px; height: 80px">
            <!--      <span class="home-butbox-logtxt">齐道信息</span>-->
          </div>
          <div class="home-but-text" style="position: relative">
            <div v-for="(item,index) in arr" :key="index">
              <p :class="[index === checkindex ? 'active' : '']" style="width:100px;
           font-size:16px;font-weight: bold;text-align:center; height: 35px;" class="home-but-box"
                 @mouseenter="mouseenter(index)" @click="clickList(index)">{{ item.name }}</p>
            </div>
          </div>
          <div>
            <mu-button color="primary" class="info-right" @click="demoButton">体验DEMO</mu-button>
            <mu-button color="primary">联系我们</mu-button>
          </div>
          <!--    产品弹窗-->
          <transition name="el-zoom-in-top">
            <div class="product1" v-if="product" @mouseleave="mouseleave">
              <div v-for="(item,index) in productData" :key="index" class="product-lin">
                <p class="product-p">{{ item.title }}</p>
                <ul style="display: flex;flex-direction: column;flex-wrap: wrap;height: 204px">
                  <li v-for="(item,index) in item.list" :key="index" class="product-li">
                    <h4 @click="clickList(item.checkindex)">{{ item.link }}</h4>
                    <p>{{ item.text }}</p>
                  </li>
                </ul>
              </div>
            </div>
          </transition>
          <!--    解决方案弹窗-->
          <transition name="el-zoom-in-top">
            <div class="solution1" v-if="solutionBen" @mouseleave="mouseleave">
              <div v-for="(item,index) in solution" :key="index">
                <p class="solution-p">{{ item.title }}</p>
                <ul>
                  <li v-for="(item,index) in item.list" :key="index" class="solution-lin">
                    <h4>{{ item.text }}</h4>
                  </li>
                </ul>
              </div>
            </div>
          </transition>
        </div>
      </div></div>
    </div>
    <!--   正文-->
    <div>
      <!--      <router-view/>-->
      <main-home-page v-if="checkindex===0" @change="change(record,$event)"></main-home-page>
      <customer v-if="checkindex===2"></customer>
      <duct-t-p-m v-if="[101,1].includes(checkindex)"></duct-t-p-m>
      <duct-d-m-s v-if="checkindex===102"></duct-d-m-s>
      <duct-s-f-a v-if="checkindex===103"></duct-s-f-a>
      <duct-k-m-s v-if="checkindex===104"></duct-k-m-s>
      <duct-d-i-s v-if="checkindex===105"></duct-d-i-s>
      <duct-distribution v-if="checkindex===107"></duct-distribution>
      <demo v-if="checkindex===5"></demo>
      <milk v-if="checkindex===301"></milk>
      <food v-if="checkindex===302"></food>
      <alcohol v-if="checkindex===303"></alcohol>
      <aboutUs v-if="checkindex===304"></aboutUs>
      <joinUs v-if="checkindex===305" :index="index"></joinUs>
    </div>
    <!--    页脚-->
    <div>
      <div class="m1240"><div class="grid-cell m1240" ><div>
        <div class="wrapper1 w100">
          <div class="wrapper-footer w1200">
            <div class="left">
              <div class="left-logo" style="font-size: 22px;font-weight: 600">
                成都齐道科技有限公司
                <!--          <img src="../../../public/image/cn-logo.svg">-->
              </div>
              <p class="about">Tel：18180763501</p>
              <p class="about">E-mail：xuduan@qidaokj.cn</p>
              <p class="about">简历投递：xuduan@qidaokj.cn</p>
              <div class="flex">
                <div class="left-QR">
                  <img src="/image/official.png">
                  <p><i class="iconfont icon-weixin"></i><span>微信公众号</span></p>
                </div>
                <div class="left-QR">
                  <div class="left-QR-right">
                    <img src="/image/consulting-service.png">
                  </div>
                  <p><i class="iconfont icon-weixin"></i><span>在线咨询</span></p>
                </div>
              </div>
            </div>
            <div class="right">
              <div class="right-our">
                <dl>
                  <dt>产品</dt>
                  <dd @click="clickList(101)">TPM营销费用管理平台</dd>
                  <dd @click="clickList(102)">DMS经销商服务平台</dd>
                  <dd @click="clickList(103)">SFA移动访销平台</dd>
                  <dd @click="clickList(104)">KMS直营对账平台</dd>
                  <dd @click="clickList(105)">DIS经销商数据管理系统</dd>
                  <dd @click="clickList(107)">深度分销系统</dd>
                </dl>
                <dl>
                  <dt>解决方案</dt>
                  <dd>通用解决方案</dd>
                  <dd @click="clickList(301)">乳业解决方案</dd>
                  <dd @click="clickList(302)">食品解决方案</dd>
                  <dd @click="clickList(303)">酒类解决方案</dd>
                </dl>
                <dl>
                  <dt>用户中心</dt>
                  <dd>产品上线指南</dd>
                  <dd>实施指南</dd>
                  <dd>技术文档</dd>
                </dl>
                <dl>
                  <dt @click="clickList(304)">关于我们</dt>
                  <dd @click="clickList(304)">公司介绍</dd>
                  <dd>公司动态</dd>
                  <dd>法律声明</dd>
                  <dd @click="clickList(305)">加入我们</dd>
                  <dd>联系我们</dd>
                </dl>
                <dl>
                  <dt>市场活动</dt>
                  <dd>主题活动</dd>
                  <dd>宴会活动</dd>
                  <dd>市场陈列</dd>
                </dl>
              </div>
            </div>
          </div>
        </div>
        <div class="footer-bottom w100">© 2021-2026 成都齐道科技有限公司 蜀ICP备2021024431号-1</div>
      </div></div></div>
    </div>
  </div>
</template>

<script>
import Navigat from './homeNavigationbar'
import TabFooter from './TabFooter'
import MainHomePage from "@/views/main/mainHomePage";
import Customer from "@/views/cust";
import milk from "@/views/solve/Milk";
import {productData} from "@/publicData/navListData";
import {solution} from "@/publicData/HomeMentList";
import DuctTPM from "@/views/duct/ductTPM";
import DuctDIS from "@/views/duct/ductDIS";
import DuctDMS from "@/views/duct/ductDMS";
import DuctSFA from "@/views/duct/ductSFA";
import DuctKMS from "@/views/duct/ductKMS";
import DuctDistribution from "@/views/duct/ductDistribution";
import food from "@/views/solve/Food";
import alcohol from "@/views/solve/Alcohol";
import aboutUs from "@/views/aboutUs";
import joinUs from "@/views/JoinUs";
import Demo from "@/views/demo";
export default {
  name: "TabHomePage",
  components:{
    Demo,
    DuctDistribution,
    DuctSFA,
    DuctDMS,
    DuctDIS,
    DuctTPM,
    Customer,
    MainHomePage,
    Navigat,
    milk,
    food,
    alcohol,
    aboutUs,
    joinUs,
    TabFooter,
    DuctKMS
  },
  data(){
    return{
      product:false,
      productData,
      solution,
      index:0,
      solutionBen:false,
      checkindex:0,
      arr:[
        {
          name:"首页",
          url:"",
        },
        {
          name:"产品",
          url:"",
        },
        {
          name:"客户",
          url:"",
        },
        {
          name:"解决方案",
        },
        {
          name:"联系我们",
          url:"",
        }
      ]
    }
  },
  mounted() {
    // console.log(this.productData)
  },
  methods: {
    clickList(index){
      this.checkindex = index
      this.index=0
      document.documentElement.scrollTop = 0;
      // if(this.checkindex === 0){
      //   this.$router.push({ path:'/mainHomePage'})
      // }
      // if(this.checkindex === 2){
      //   this.$router.push({path:'/customer'})
      // }
    },
    change(record,value){
      console.log(record,value)
      this.checkindex = value
      this.index=0
      document.documentElement.scrollTop = 0;
    },
    //鼠标移入
    mouseenter(index){
      if(index === 1){
        this.product = true
      }
      if(index === 3){
        this.solutionBen = true
      }
      if(index !== 1){
        this.product = false
      }
      if(index !== 3){
        this.solutionBen = false
      }
    },
    //鼠标移出
    mouseleave(index){
      this.product = false
      this.solutionBen = false
    },
    //路由跳转
    pathUrl(url){
      this.$router.push({ path:url})
      this.checkindex = 1
      this.product = false
    },
    demoButton(){
      this.checkindex=5
    }
  }
}
</script>

<style scoped lang="less">
.home-butbox {
  width: 100%;
  height: 94px;
  display: flex;
  align-items: center;
  justify-content: space-around;
  box-sizing: border-box;
  background-color: #fff;
  margin: 0 auto;
}

.home-butleft {
  display: flex;
  align-items: center;
}

.home-butbox-logtxt {
  font-size: 28px;
  font-weight: bold;
  color: #333;
  text-align: center;
  //font-family:YuYangT3;
  line-height: 24px;
}

.home-but-text {
  display: flex;
  justify-content: space-between;

}

.active {
  border-bottom: #0170fe 1px solid;
  color: rgba(1, 112, 254, 1);
}

.home-but-box {
  cursor: pointer;
}

.home-but-box:hover {
  color: rgba(1, 112, 254, 1);
  transition: 0.5s;
}

.info-right {
  margin-right: 30px;
}

/* 产品弹窗*/
.product1 {
  width: 650px;
  height: 300px;
  background-color: #fff;
  padding: 48px 51px;
  position: absolute;
  top: 94px;
  z-index: 99999;
  display: flex;
  gap: 40px;
  justify-content: center;
  border-radius: 0 0 4px 4px;
  box-shadow: 0px 2px 6px #eee;

  .product-lin {
    .product-p {
      color: #0D36A8;
      font-weight: bold;
      font-size: 18px;
      margin-bottom: 10px;
    }

  }
}

.product-li {
  margin-bottom: 20px;
  position: relative;
  margin-left: 8px;

  h4 {
    font-size: 14px;
    font-weight: normal;
  }

  p {
    font-size: 12px;
    color: #999;
    line-height: 1.25rem;
  }

  h4:hover {
    cursor: pointer;
    transition: 0.3s;
    color: #0170fe;
  }
}

//.product-li:nth-of-type(4) {
//  margin-left: 48px;
//}

.product-li::before {
  content: "";
  width: 2px;
  height: 28px;
  position: absolute;
  top: 5px;
  left: -8px;
  background-color: #0D36A8;
  border-radius: 4px;
}

//解决方案
.solution1 {
  width: 600px;
  height: 250px;
  background-color: #fff;
  padding: 10px 30px 20px 30px;
  position: absolute;
  top: 94px;
  z-index: 99999;
  display: flex;
  justify-content: space-between;
  border-radius: 0 0 4px 4px;
  box-shadow: 0px 2px 6px #eee;

  .solution-p {
    color: #999;
    font-weight: bold;
    font-size: 12px;
    margin-bottom: 10px;
  }

  .solution-lin {
    h4 {
      cursor: pointer;
      font-size: 12px;
    }

    h4:hover {
      transition: 0.3s;
      color: #0170fe;
    }
  }

  .solution-lin ~ .solution-lin {
    margin-top: 10px;
  }
}
.wrapper1 {
  width: 100%;
  min-width: 1240px;
  background-color:#0D36A8;
  color: #fff;
  font-size: 12px;
  box-sizing: border-box;
  padding: 30px;

  .wrapper-footer {
    display: grid;
    grid-template-columns:300px 1fr;
    gap: 30px;
    box-sizing: border-box;
  }

  .left {
    box-sizing: border-box;
    //padding-left: 50px;
    .about{
      line-height: 2;
      color: #eee;
    }
    .about:last-of-type{
      margin-bottom: 20px;
    }
    .left-logo {
      display: flex;
      align-items: center;
      margin-bottom: 20px;
      p {
        font-size: 28px;
        font-weight: bold;
        //font-family: YuYangT3;
      }
    }
    .left-QR{
      img{
        width: 90px;
      }
      .left-QR-right{
        width: 90px;
        height: 90px;
        padding: 3px;
        background-color: #fff;
        img{
          width: 84px;
        }
      }
      p{
        display: flex;
        align-items: center;
        gap: 4px;
      }
    }

  }
  .right{
    box-sizing: border-box;
    width: 100%;
    .right-our{
      display: flex;
      justify-content: space-around;
      font-size: 13px;
      box-sizing: border-box;
      padding: 20px;
      margin-bottom: 10px;
      dt{
        font-size: 16px;
        color: #fff;
        font-weight: bold;
      }
      dd{
        line-height: 2rem;
        color: #f5f5f5;
        transition: 0.5s;
      }
      dd:hover{

        color: #fff;
        cursor: pointer;
      }
    }
  }
}
.flex{
  display: flex;
  align-items: center;
  gap: 15px;
}
.footer-bottom{
  height: 40px;
  display: flex;
  align-items: center;
  justify-content: center;
  color: #fff;
  background-color: #333;
  font-size: 12px;
}
</style>
