<template>
  <div class="w100" style="background-color: #fff">
    <div style="background-color: #f3f6fb;padding-bottom: 30px">
      <div class="w1100">
        <!--        酒类行业新动向-->
        <h2 class="wrapper-h2">酒类行业新动向</h2>
        <p>2022年，随着疫情的影响，白酒行业营收总额与净利润双指标均有所下滑，受疫情影响的原因消费力明显下降。</p>
        <el-menu mode="horizontal" :default-active="activeIndex" class=" el-menu" background-color="#f3f6fb"
                 text-color="#333" active-text-color="#0D36A8" @select="handleSelect">
          <el-menu-item v-for="(item,index) in dataLIst1" :key="index" :index="index" style="font-size: 16px;">
            {{ item }}
          </el-menu-item>
        </el-menu>
        <div v-if="activeIndex===0">
          <div class="wrapper-box">
            <div>
              <img :src=pinpaiList.url>
            </div>
            <div class="wrapper-lin">
              <p v-for="(item,index) in pinpaiList.text">{{ item }}</p>
            </div>
          </div>
        </div>
        <div v-if="activeIndex===1">
          <div class="wrapper-box">
            <div>
              <img :src=jiegouList.url>
            </div>
            <div class="wrapper-lin">
              <p v-for="(item,index) in jiegouList.text">{{ item }}</p>
            </div>
          </div>
        </div>
        <div v-if="activeIndex===2">
          <div class="wrapper-box">
            <div>
              <img :src=chanpinList.url>
            </div>
            <div class="wrapper-lin">
              <p v-for="(item,index) in chanpinList.text">{{ item }}</p>
            </div>
          </div>
        </div>
      </div>
    </div>
    <div class="w1100">
      <!--白酒企业面临新挑战-->
      <h2 class="wrapper-h2" style="margin-bottom: 30px">白酒企业面临新挑战</h2>
      <div class="flex" style="justify-content: center;gap: 96px;">
        <div class="liquor-box" v-for="(item,index) in liquorList" :key="index">
          <img :src="item.url">
          <p>{{ item.text }}</p>
        </div>
      </div>
      <!--    应对策略-->
      <h2 class="wrapper-h2" style="margin-bottom: 16px">应对策略</h2>
      <div style="line-height: 26px;font-size: 14px">
        <p>白酒行业下一轮竞争利器是“数字化”。</p>
        <p>全行业正在加速数字化转型，白酒头部企业早已实现数字化转型。</p>
        <p>未来酒企将借助数字化，实现货可追踪，渠道可追踪，人可追踪，数据可留存，反复为企业未来的二次营销、渠道的再次优化、寻找新增长点提供支撑。</p>
      </div>
      <div class="flex" style="justify-content: center;gap: 128px;margin-top: 40px">
        <div class="celue" v-for="(item,index) in celueList" :key="index">{{item}}</div>
      </div>
<!--    解决方案  -->
      <h2 class="wrapper-h2" style="margin-bottom: 16px">解决方案</h2>
      <div style="line-height: 26px;font-size: 14px">
        <p>齐道专注为快消企业提供以消费者为核心的全渠道移动管理解决方案，帮助白酒企业以数字化战略为指引，通过数字化团队、数字化渠道、数字化终端、 数字化用户、数字化费用管理等，赋能企业实现竞争力升级。</p>
      </div>
      <div class="wrapper-fa">
        <el-menu mode="horizontal" :default-active="activeIndex2" class=" el-menu"
                 text-color="#333" active-text-color="#0D36A8" @select="handleSelect2">
          <el-menu-item v-for="(item,index) in dataList2" :key="index" :index="index" style="font-size: 16px;">
            {{ item }}
          </el-menu-item>
        </el-menu>
      </div>
    </div>
    <div style="background-color: #f3f6fb;padding: 30px 0">
      <div v-if="activeIndex2===0">
        <div class="w1100">
          <h3 class="fangan-h3">{{fangan.title}}</h3>
          <div class="flex" style="gap: 72px">
            <div class="fangan-box">
              <img :src="fangan.url">
              <div class="fangan-box-text">
                <div>{{fangan.imgtext.title}}</div>
                <p v-for="(item,index) in fangan.imgtext.list">{{item}}</p>
              </div>
            </div>
            <div class="fangan-rbox">
              <div class="fangan-rtext" v-for="(item,index) in fangan.text" :key="index">{{item}}</div>
            </div>
          </div>
        </div>
      </div>
      <div v-if="activeIndex2===1">
        <div class="w1100">
          <h3 class="fangan-h3">{{fangan2.title}}</h3>
          <div class="flex" style="gap: 72px">
            <div class="fangan-box">
              <img :src="fangan2.url">
              <div class="fangan-box-text">
                <div>{{fangan2.imgtext.title}}</div>
                <p v-for="(item,index) in fangan2.imgtext.list">{{item}}</p>
              </div>
            </div>
            <div class="fangan-rbox">
              <div class="fangan-rtext" style="width: 200px;padding: 0 22px" v-for="(item,index) in fangan2.text" :key="index">{{item}}</div>
            </div>
          </div>
        </div>
      </div>
      <div v-if="activeIndex2===2">
        <div class="w1100">
          <h3 class="fangan-h3">{{fangan3.title}}</h3>
          <div class="flex" style="gap: 72px">
            <div class="fangan-box">
              <img :src="fangan3.url">
              <div class="fangan-box-text">
                <div>{{fangan3.imgtext.title}}</div>
                <p v-for="(item,index) in fangan3.imgtext.list">{{item}}</p>
              </div>
            </div>
            <div class="fangan-rbox">
              <div class="fangan-rtext" v-for="(item,index) in fangan3.text" :key="index">{{item}}</div>
            </div>
          </div>
        </div>
      </div>
    </div>
      <div class="button-wrapper">
        <mu-button large color="primary">即刻体验</mu-button>
      </div>
    </div>
</template>

<script>
export default {
  name: "index",
  data() {
    return {
      activeIndex: 0,
      activeIndex2:0,
      dataLIst1: ['现状一  品牌效应', '现状二  消费结构', '现状三  产品渠道'],
      dataList2: ['数字化团队','数字化费用管理','数字渠道'],
      pinpaiList: {
        url: require('../../../../public/image/solve/pinpai.webp'),
        text: ['行业集中度不断提高，企业角力品牌建设，白酒行业市场扩展以“渠道为核心”逐步转向“消费者为核心”。', '当代酒类消费已经从广告时代进入消费者时代，其主要特征就是与消费者建立高效的沟通与连接关系成为。', '企业竞争的关键，以“人”为本，拉近与C端消费者的距离成为各大酒企的战略重点。']
      },
      jiegouList: {
        url: require('../../../../public/image/solve/jiegou.webp'),
        text: ['消费理性环境下体验式场景成为新方向，消费场景进一步多元化、年轻化、社交化、互动化，拓宽饮用场景，开拓增量市场。白酒饮用场景，从功能/社交属性（传统的品鉴会、品酒会、宴席等饮用场景）过渡到娱乐/品味属性。从增量市场角度，需考虑通过更多的场景和娱乐调性上拉动增长，了解消费者画像，寻找细分市场和场景，并利用数字化工具和有趣的话题触达消费者。']
      },
      chanpinList: {
        url: require('../../../../public/image/solve/chanpin.webp'),
        text: ['酒企产品渠道的便捷形态正在发生变化，产品销售渠道与方式在数字化零售的推动下不断贴近消费者，以提高购买便捷性。流通渠道或向直分销模式转变，构建新型厂商关系成为转型刚需；传统酒店渠道仍不可或缺，但向更具私密性、人流量少、环境优雅的宴会场景转变；商超渠道向平台化转型趋势苗头凸显，烟酒店和夫妻店亟待酒企的数字化赋能；团购渠道将与宴席、体验、互动场景深度捆绑，实现动销。']
      },
      liquorList: [
        {
          url: require('../../../../public/image/solve/dj.jpg'),
          text: '搭建消费者为核心的商业模式过程中，缺乏有力的工具贯穿营销各方面，无法实现通盘布局的变革'
        },
        {
          url: require('../../../../public/image/solve/kh.jpg'),
          text: '无法获知客户和消费者画像，数据间缺乏联动，对线上线下渠道融合的营销决策感到吃力'
        },
        {
          url: require('../../../../public/image/solve/gj.jpg'),
          text: '构建新型厂商关系过程中，经销商和终端团队积极性低、绩效产出低，能力待提升，急需厂家赋能'
        },
        {
          url: require('../../../../public/image/solve/ct.jpg'),
          text: '传统经销商渠道运营模式老旧，费用投入有盲区；厂家也无法真正了解终端，费用模糊式投放，难以推动渠道变革'
        },
      ],
      celueList:['货可追踪','渠道可追踪','人可追踪','数据可留存'],
      fangan: {
          title:'SFA移动访销系统',
          url: require('../../../../public/image/solve/jz.webp'),
          imgtext:{
            title:'价值',
            list:['赋能销售团队','实施有效激励','降低管理成本','提高团队效率']
          },
          text:['宴席活动报备','团购提报','市场稽核','陈列检查','绩效考核']
        },
      fangan2:{
        title:'TPM营销费用管理系统',
        url: require('../../../../public/image/solve/tpm.webp'),
        imgtext:{
          title:'价值',
          list:['费用预算自动计算','费用活动可跟踪','费用活动精准投放，避免盲区','可视化数据分析，助力企业营销']
        },
        text:['费用预算规划','费用活动（团购/宴席/ 品酒会/品鉴会等）申请','活动举证核销','费效比分析报表','活动分类细化、便于核销']
      },
      fangan3:{
        title:'DMS经销商管理系统',
        url: require('../../../../public/image/solve/dms.webp'),
        imgtext:{
          title:'价值',
          list:['提高订单流转效率',' 跟踪货物流向，防止窜货','提高与渠道间的信息流转','实时掌握销售数量，实现营销数据可视化']
        },
        text:['订单进销存','经销商对账','要货计划','促销政策直达','销售数据分析']
      }
    }
  },
  methods: {
    handleSelect(key) {
      console.log(key);
      this.activeIndex = key
    },
    handleSelect2(key) {
      this.activeIndex2 = key
    }
  }
}
</script>

<style scoped lang="less">
@import "./Alcohol";
</style>
