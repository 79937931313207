let productData = [
    {
        title:'产品方案',
        list:[
            {
                link:'TPM营销费用管理平台',
                text:'赋能渠道的全场景营促销政策',
                url:'/ductTPM',
                checkindex:101,
            },
            {
                link:'DMS经销商服务平台',
                text:'一体化在线服务与现场服务管理',
                url:'/ductDMS',
                checkindex:102,
            },
            {
                link:'SFA移动访销平台',
                text:'精细化的业务管理与提升，助力企业业务增长',
                url:'/ductSFA',
                checkindex:103,
            },
        ]
    },
    {
        title:'特色产品',
        list:[
            {
                link:'KMS直营对账平台',
                text:'数字化提升直营应收管理',
                url:'',
                checkindex:104,
            },
            {
                link:'DIS经销商数据管理系统',
                text:'提升经销商与企业的信息交换',
                url:'/ductDIS',
                checkindex:105,
            },
            // {
            //     link:'B1B门店管理平台',
            //     text:'赋能渠道的全场景营促销政策',
            //     url:'',
            //     checkindex:106,
            // },
            {
                link:'渠道分销系统',
                text:'赋能渠道的全场景营促销政策',
                url:'/ductDistribution',
                checkindex:107,
            },
        ]
    }
]
export {productData}
