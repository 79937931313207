<template>
  <div>
    <div class="wrapper w100">
      <div class="w1240">
        <h1 style="text-align: center;margin: 0">DIS经销商数据管理系统</h1>
        <div class="wrapper-lin flex-fr">
          <div class="wrapper-dis-lin" v-for="(item,index) in DISsystem">
            <p>{{ item.listTitle }}</p>
            <div class="wrapper-dis-list">
              <div v-for="(item,index) in item.list">{{ item }}</div>
            </div>
          </div>
          <div class="dis-linList">
            <h2 class="dis-h2">数据全生命周期</h2>
            <div class="dis-listTitle" v-for="(item,index) in DISsystem">{{ item.title }}
              <!-- 线条-->
              <div class="Line-lin">
                <div class="Line line-top"></div>
                <div class="Line line-bottom"></div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
    <div class="w1200" style="margin: 30px auto">
      <h2 style="text-align: center;font-size: 20px;font-weight: bold">
        经销商数据收集现状</h2>
      <p style="font-weight: bold;line-height: 20px;text-align: center">
        齐道TPM活动费⽤管理融⼊PDCA闭环管理模型，覆盖营销活动全流程，帮助企业精打细算，找到提升价值创造的关键点。</p>
      <div style="display: flex;gap:30px;font-weight: bold;justify-content: center;color: #0D36A8;line-height: 20px">
        <p>预算财年、预算科目、预算费用</p>
        <p>活动</p>
        <p>管理可视化</p>
        <p>赋能价值提升</p>
      </div>
      <div style="display: flex;justify-content: center;gap: 4px;margin-top: 20px">
        <div v-for="(item,index) in collect">
          <div style="display: flex;flex-direction: column;gap: 4px">
            <div class="collect-list" v-for="(item,index) in item.list">{{ item }}</div>
          </div>
        </div>
      </div>
    </div>
    <div class="wrapper w100">
      <div class="w1240">
        <div>
          <el-menu mode="horizontal" :default-active="activeIndex" class=" el-menu" background-color="#F3F6FB" text-color="#333"  active-text-color="#0D36A8" @select="handleSelect">
            <el-menu-item  v-for="(item,index) in disAdvantage" :key="index" :index="index" style="font-size: 16px" class="mu-tab">
              {{ item.title }}
            </el-menu-item>
          </el-menu>
          <div v-if="activeIndex === 0">
            <p style="font-size: 18px;font-weight: bold;margin: 20px 0px 0 150px">DIS系统对比其他服务商优势</p>
            <div class="demo-text1">
              <div style="width: 50%;padding-left: 100px" v-for="(item,index) in disAdvantage[0].list" :key="index">
                <img :src="item.url">
              </div>
            </div>
          </div>
          <div v-if="activeIndex === 1" style="padding: 40px 80px">
            <p style="font-size: 18px;font-weight: bold;margin: 0 0 20px 0">DIS系统对比其他服务商优势</p>
            <div class="demandList" v-for="(item,index) in demandList.list" :key="index">
              <div class="demandList-left">{{ item.leftTitle }}</div>
              <div class="demandList-right" v-for="(item,index) in item.rightLst">
                <p class="demandList-right-p1">{{ item.title }}</p>
                <p v-for="(item,index) in item.list">{{ item }}</p>
              </div>
            </div>
          </div>
          <div v-if="activeIndex === 2" style="padding: 40px 80px">
            <div style="padding: 50px">
              <div class="Template-box">
                <div class="Template-box-center">
                  <p>主数据管理</p>
                  <span>组织/产品/门店/经销商等</span>
                </div>
                <div v-for="(item,index) in Template" :key="index" class="Template-box-list">
                  <div>{{ item.title }}</div>
                  <p v-for="(item,index) in item.list" :key="index">{{ item }}</p>
                </div>
              </div>
            </div>
          </div>
          <div v-if="activeIndex === 3" style="padding: 40px 80px">
            <div class="processList">
              <div class="processList-box" v-for="(item,index) in processList" :key="index">
                <div class="processList-box-title">{{ item.title }}</div>
                <div class="processList-box-p">
                  <p v-for="(item,index) in item.list" :key="index">{{ item }}</p>
                </div>
              </div>
            </div>
          </div>
          <div v-if="activeIndex === 4" style="padding: 40px 80px">
            <p style="font-size: 18px;font-weight: bold;text-align: center;margin: 0 0 20px 0">经销商安装DIS现场步骤</p>
            <div>
              <div style="display: flex;align-items: center;justify-content: center;gap: 10px">
                <div class="Installation-title" v-for="(item,index) in InstallationTitle" :key="index">{{ item }}</div>
              </div>
              <div class="Installation-list" v-for="(item,index) in InstallationList">
                <div class="Installation-list-title">{{ item.title }}</div>
                <div class="Installation-list-box" v-for="(item,index) in item.list" :key="index">{{ item }}</div>
              </div>

            </div>
          </div>
          <div v-if="activeIndex === 5" style="padding: 40px 80px">
            <p style="font-size: 18px;font-weight: bold;text-align: center;margin: 0 0 20px 0">数据处理流程</p>
            <div style="width: 706px;margin: 0 auto">
              <div class="processing-box" style="width: 100px;background-color: #0D36A8">{{
                  processingData.start
                }}
              </div>
              <div>
                <div class="processingData-box" v-for="(item,index) in processingData.listData">
                  <div class="processing-box" v-for="(item,index) in item.list" :key="index">{{ item }}</div>
                </div>
              </div>
              <div class="processing-box fr" style="background-color: #D43030">{{ processingData.end }}</div>
            </div>
          </div>
          <div v-if="activeIndex === 6" style="padding: 40px 80px;display: flex;align-items: center;flex-direction: column">
            <p style="font-size: 18px;font-weight: bold;text-align: center">自定义可视化报表</p>
            <img src="../../../../public/image/DIS/baobiao.png" style="width: 650px">
          </div>
        </div>
      </div>
    </div>
    <div class="w100" style="background-color:#F3F6FB ">
      <div class="button-wrapper">
        <mu-button large color="primary">即刻体验</mu-button>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  name: "ductDIS",
  data() {
    return {
      activeIndex: 0,
      DISsystem: [
        {
          title: '经销商',
          listTitle: '数据源',
          list: ['进货数据', '其他出库', '调拨数据', '销售数据', '产品数据', '流向价格', '库存数据', '门店数据', '其他入库', '退货数据']
        },
        {
          title: '数据采集',
          listTitle: '数据处理',
          list: ['数据整合', '数据匹配', '数据确认', '数据结存', '异常处理']
        },
        {
          title: '数据采集',
          listTitle: '采集方式',
          list: ['插件直连', 'SFTP上传', 'RPA抓取', 'Portal上传', '接口']
        },
        {
          title: '数据分析',
          listTitle: '数据分析',
          list: ['分析大屏', '报表', '标签分析', '生意机会', '异常分析']
        },
      ],
      collect: [
        {
          list: ['', '手工上报', '标准DMS', 'DIS']
        },
        {
          list: ['数据频率', '月', '日', '日']
        },
        {
          list: ['准确性', '数据不全，质量差', '数据可控，质量好', '数据可控，质量好']
        },
        {
          list: ['实施周期', '较短', '非常长', '3天/经销商']
        },
        {
          list: ['实施成本', '小', '非常高', '中等']
        },
        {
          list: ['总结', '频率低，质量差，不可用', '周期长，成本高，推行困难', '周期短，成本适中， 质量可控']
        },
      ],
      disAdvantage: [
        {
          title: 'DIS优势',
          listTitle: 'DIS系统对比其他服务商优势',
          list: [
            {
              url: require('../../../../public/image/DIS/wfw.svg')
            },
            {
              url: require('../../../../public/image/DIS/pipei.svg')
            },
            {
              url: require('../../../../public/image/DIS/yj.svg')
            },
            {
              url: require('../../../../public/image/DIS/ops.svg')
            },
            {
              url: require('../../../../public/image/DIS/linghuo.svg')
            },
            {
              url: require('../../../../public/image/DIS/ERP.svg')
            },
            {
              url: require('../../../../public/image/DIS/baobiao.svg')
            },
            {
              url: require('../../../../public/image/DIS/tuandui.svg')
            },
          ]
        },
        {
          title: '需求理解',
        },
        {
          title: '功能模板'
        },
        {
          title: '实施流程'
        },
        {
          title: '安装流程'
        },
        {
          title: '数据处理'
        },
        {
          title: '可视化报表'
        },
      ],
      demandList: {
        title: 'DIS需求理解',
        list: [
          {
            leftTitle: '现状分析',
            rightLst: [
              {
                title: '经销商：',
                list: ['经销商进销存数据无法获取']
              },
              {
                title: '品牌方：',
                list: ['品牌方无法得知经销商的流向，流量和流速。']
              },
              {
                title: '问题：',
                list: ['经销商数据无从获取，或者提供不及时，无法获得最新的市场动态']
              },
            ]
          },
          {
            leftTitle: '项目目标',
            rightLst: [
              {
                title: '实时性：',
                list: ['随时随地可查看最新的进销存数据，掌握最新情况']
              },
              {
                title: '真实性：',
                list: ['从经销商数据库中直接获取数据的方式取代文件上传等方式， 获取最真实的第一手数据']
              },
              {
                title: '真实性：',
                list: ['通过DIS系统的匹配清理数据， 转换成品牌方的主数据格式，输出给品牌方BI系统']
              },
            ]
          },
          {
            leftTitle: '系统要求',
            rightLst: [
              {
                title: '经销商：',
                list: ['服务器独立部署', '数据全程加密']
              },
              {
                title: '稳定性：',
                list: ['微服务架构', '高可用性', '高并发性']
              },
              {
                title: '稳定性：',
                list: ['经销商上传监测', '服务器监测', '定期备份']
              },
            ]
          },
        ]
      },
      Template: [
        {
          title: '客户端管理',
          list: ['客户配置 ', '客户端配置']
        },
        {
          title: '学习成长',
          list: ['安装工具 ', ' 采集经销商数据']
        },
        {
          title: '数据检查',
          list: ['进销存平衡 ', ' 产品检查等']
        },
        {
          title: '报表管理',
          list: ['经销商进销存 ', ' 经销商库存', ' 经销商销售', '经销商终端等']
        },
        {
          title: 'DMS/POS数据查看',
          list: ['原始/匹配/ ', ' 转化/结存']
        },
        {
          title: '数据匹配',
          list: ['智能匹配/实施确认']
        },
      ],
      processList: [
        {
          title: '商务交流',
          list: ['经销商宣导材料', '三方保密协议']
        },
        {
          title: '经销商调研',
          list: ['RP软件及型号', '经销商联系人信息', '网络情况', '数据情况']
        },
        {
          title: '客户端安装',
          list: ['参与经销商实施流程']
        },
        {
          title: '数据验证',
          list: ['经销商ERP1个月数据与DIS 抽取数据比较', '产品，产品单位，门店等匹 配及确认', '智能匹配检查', '数据结存']
        },
      ],
      InstallationTitle: ['建立远程连接', '安装前检查', '客户端下载及配置', '数据抽取', '数据检查', '安装完成'],
      InstallationList: [
        {
          title: '实施方',
          list: ['建立远程连接', '安装前环境、 数据检查', '下载客户端并进行系统配置', '根据要求抽取门店列表、产品列表、进销存数量', '抽取数据与进销存数据进行检验', '客户端操作培训和问题解答']
        },
        {
          title: '经销商',
          list: ['建立远程连接', '登录进销存软件', '根据提示输入数据库的用户名账号及密码，非专销客户需配合修改', '不影响日常操作', '配合处理检验中的问题', '每天9：00至11:00保证安装电脑开机、连网，实现数据自动抽取']
        },
      ],
      processingData: {
        start: '开始处理',
        end: '结束处理',
        listData: [
          {
            list: ['数据监控', '经销商上传监控', '销售数据监控', '上传达成率监控']
          },
          {
            list: ['数据加载', '65天数据对比', '删改单据冲销', '清洗逻辑执行']
          },
          {
            list: ['数据匹配', '历史匹配自动执行', '新增匹配按流程执行', '匹配变更生成冲销']
          },
          {
            list: ['数据检查', '进销存平衡天数检查', '加价率检查', '负库存检查']
          },
        ]
      }
    }
  },
  methods:{
    handleSelect(key) {
      console.log(key);
      this.activeIndex = key
    }
  }
}
</script>

<style scoped lang="less">
.wrapper {
  background-color: #f3f6fb;
  padding: 20px 0;
}

.Line-lin {
  display: flex;
  position: relative;

  .Line {
    width: 100px;
    height: 25px;
    box-sizing: border-box;
  }

  .line-top {
    border-bottom: 5px solid #0d36a8;
    border-left: 5px solid #0d36a8;
    border-bottom-left-radius: 8px;
  }

  .line-bottom {
    position: absolute;
    top: 20.5px;
    left: 95px;
    border-top: 5px solid #0d36a8;
    border-right: 5px solid #0d36a8;
    border-top-right-radius: 8px;
  }
}

.flex-fr {
  display: flex;
  flex-wrap: wrap;
}

.wrapper-dis-lin {
  width: 50%;
  box-sizing: border-box;
  padding-left: 200px;

  p {
    font-weight: bold;
    line-height: 20px;
    color: #0D36A8;
    margin-bottom: 10px;
  }

  .wrapper-dis-list {
    display: flex;
    flex-wrap: wrap;
    gap: 15px;
    font-weight: bold;
  }
}

.wrapper-dis-lin:nth-of-type(1), .wrapper-dis-lin:nth-of-type(2) {
  margin-bottom: 300px;
}

.wrapper-lin {
  position: relative;

  .dis-linList {
    position: absolute;
    top: calc(219px - 32px);
    left: calc(620px - 100px);

    .dis-h2 {
      padding: 16px 24px;
      background-color: #d4edff;
      color: #0D36A8;
      border-radius: 50%;
    }

    .dis-listTitle {
      font-size: 16px;
      font-weight: bold;
      position: absolute;
    }

    .dis-listTitle:nth-child(2) {
      top: -50px;

      .Line-lin {
        top: -70px;
        left: -180px;
      }
    }

    .dis-listTitle:nth-child(3) {
      top: -50px;
      right: -30px;

      .Line-lin {
        top: -50px;
        left: 40px;
        transform: rotateX(180deg);
      }
    }

    .dis-listTitle:nth-child(4) {
      bottom: -70px;

      .Line-lin {
        top: 40px;
        left: -180px;
        transform: rotateX(180deg);
      }
    }

    .dis-listTitle:nth-child(5) {
      bottom: -70px;
      right: -30px;
    }

    .Line-lin {
      top: 19px;
      right: -20px;
    }
  }
}

//数据收集现状
.collect-list {
  text-align: center;
  background-color: #78C7FF;
  width: 100%;
  padding: 10px 20px;
  color: #fff;
  height: 41px;
  box-sizing: border-box;
}

.demo-text1 {
  padding: 20px;
  display: flex;
  flex-wrap: wrap;
  justify-content: space-around;
}

//需求理解
.demandList {
  display: flex;
  align-items: center;
  gap: 80px;
  margin-bottom: 20px;
}

.demandList-left {
  display: flex;
  align-items: center;;
  justify-content: center;
  width: 100px;
  height: 100px;
  box-sizing: border-box;
  background-color: #0D36A8;
  border: 5px solid #78C7FF;
  border-radius: 50%;
  font-weight: bold;
  color: #fff;
}

.demandList-right {
  width: 220px;

  .demandList-right-p1 {
    color: #0D36A8;
    font-weight: bold;
    line-height: 21px;
  }
}

//功能模板
.Template-box {
  width: 323px;
  height: 323px;
  box-sizing: border-box;
  border-radius: 50%;
  border: 4px #D4EDFF solid;
  margin: 0 auto;
  position: relative;
  display: flex;
  align-items: center;
  justify-content: center;

  .Template-box-center {
    width: 170px;
    height: 120px;
    box-sizing: border-box;
    border-radius: 50%;
    border: 4px solid #D4EDFF;
    display: flex;
    align-items: center;
    justify-content: center;
    flex-direction: column;

    p {
      font-size: 16px;
      font-weight: bold;
      color: #0D36A8;
      line-height: 20px;
    }

    span {
      font-size: 12px;
      font-weight: bold;
    }
  }

  .Template-box-list {
    width: 112px;
    height: 112px;
    border-radius: 50%;
    background-color: #0D36A8;
    color: #fff;
    position: absolute;
    display: flex;
    align-items: center;
    justify-content: center;
    flex-direction: column;

    div {
      font-weight: bold;
      line-height: 20px;
      width: 70px;
      text-align: center;
    }

    p {
      font-size: 12px;
    }
  }

  .Template-box-list:nth-of-type(2) {
    top: -56px;
  }

  .Template-box-list:nth-of-type(3) {
    top: 30px;
    right: -36px;
  }

  .Template-box-list:nth-of-type(4) {
    bottom: 30px;
    right: -36px;
  }

  .Template-box-list:nth-of-type(5) {
    bottom: -56px;
  }

  .Template-box-list:nth-of-type(6) {
    bottom: 30px;
    left: -36px;
  }

  .Template-box-list:nth-of-type(7) {
    top: 30px;
    left: -36px;
  }
}

//实施流程
.processList {
  display: flex;
  justify-content: space-between;

  .processList-box {
    width: 208px;
    height: 232px;
    box-sizing: border-box;
    background-color: #fff;

    .processList-box-title {
      background-color: #0D36A8;
      color: #fff;
      font-size: 16px;
      font-weight: bold;
      box-sizing: border-box;
      text-align: center;
      height: 56px;
      line-height: 56px;
      border-radius: 4px 4px 0 0;
    }

    .processList-box-p {
      padding: 16px 24px;
      font-weight: bold;
      line-height: 1.75em;
    }
  }
}

//安装流程
.Installation-title {
  background-color: #0D36A8;
  width: 130px;
  height: 44px;
  line-height: 44px;
  color: #fff;
  text-align: center;
  border-radius: 4px;
  font-weight: bold;
}

.Installation-list {
  margin-top: 10px;
  display: flex;
  align-items: center;
  justify-content: center;
  gap: 10px;
  font-weight: bold;
  position: relative;

  .Installation-list-title {
    position: absolute;
    top: 0;
    left: 75px;
    width: 38px;
    height: 108px;
    border-radius: 4px;
    background-color: #0D36A8;
    padding: 19px 9px;
    color: #fff;
    font-size: 16px;
  }

  .Installation-list-box {
    background-color: #fff;
    width: 130px;
    height: 108px;
    border-radius: 4px;
    display: flex;
    align-items: center;
    justify-content: center;
    padding: 0 10px;
  }
}

//数据处理
.processingData-box {
  display: flex;
  gap: 50px;
}

.processingData-box > .processing-box:first-child {
  width: 100px;
  background-color: #00BAAD;
}

.processing-box {
  padding: 7px 0;
  width: 152px;
  color: #fff;
  background-color: #78C7FF;
  text-align: center;
  font-size: 14px;
  border-radius: 4px;
  margin-bottom: 23px;
}
.el-menu{
  width: 1050px;
  margin:0 auto;
  margin-bottom: 23px;
  display: flex;
  align-items: center;
  justify-content: center;
  .mu-tab {
    font-size: 16px;
    text-align: center;
  }
  /deep/ .mu-tab:hover{
    background-color: #F3F6FB !important;
  }
}
</style>
