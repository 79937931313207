<template>
  <div class="content">
    <div v-for="(item,index) in auditTrail" :key="index" class="wrapper">
      <div class="wrapper-box">
        <p class="wrapper-box-p">{{ item.title }}</p>
      </div>
      <div v-for="(item,index) in item.list" class="wrapper-lin">
        <p>{{ item }}</p>
      </div>
    </div>

  </div>
</template>

<script>
export default {
  name: "DMSDDGengzong",
  props: {
    auditTrail: {
      type: Array,
    },
  },
  data() {
    return {
    }
  },
}
</script>

<style scoped lang="less">
.content{
  width: 300px;
  height: 300px;
  display: flex;
  align-items: center;
  justify-content: center;
}
.wrapper {
  position: relative;
  .wrapper-box {
    width: 92px;
    height: 92px;
    display: flex;
    align-items: center;
    justify-content: center;
    border: 4px solid #D1ECFF;
    position: relative;
    border-radius: 50%;
    background-color:#78C7FF ;
    .wrapper-box-p {
      width: 88px;
      height: 88px;
      display: flex;
      align-items: center;
      justify-content: center;
      font-size: 16px;
      font-weight: bold;
      color: #fff;
    }
  }
}

.wrapper-lin {
  width: 72px;
  height: 72px;
  position: absolute;
  top:112px;
  left: 8px;
  display: flex;
  align-items: center;
  justify-content: center;
  background-color: #78C7FF;
  font-size: 14px;
  color: #fff;
  border-radius: 50%;
}
.wrapper-lin:nth-of-type(2){
  //position: absolute;
  top:-90px;
  left: 8px;

}
.wrapper-lin:nth-of-type(3) {
  //position: absolute;
  top: -41px;
  left: 104px;
}
.wrapper-lin:nth-of-type(4) {
  //position: absolute;
  top: -41px;
  left: -83px;
}
.wrapper-lin:nth-of-type(5) {
  //position: absolute;
  top: 61px;
  left: 102px;
}
.wrapper-lin:nth-of-type(6) {
  //position: absolute;
  top: 61px;
  left: -83px;
}
</style>
