<template>
  <div class="lin">
    <div class="wrapper">
      <div class="wrapper-box" v-for="(item,index) in inspectList.inspectVo" :key="index">
        <p class="wrapper-num">0{{ index + 1 }}</p>
        <div class="wrapper-num-text">
          <p class="wrapper-num-title">{{ item.title }}</p>
          <div>
            <p class="wrapper-text" v-for="(linitem,linindex) in item.text" :key="linindex">{{ linitem }}</p>
          </div>
        </div>
        <div class="icon"><i class="iconfont icon-zhijiao"></i></div>
      </div>
      <p class="wrapper-title">{{ inspectList.inspect }}</p>
    </div>
  </div>
</template>

<script>
export default {
  name: "KMSmodule",
  props: {
    inspectList: {
      type: Object,
    },
  }
}
</script>

<style scoped lang="less">
.lin{
  display: flex;
  align-items: center;
  justify-content: center;
  padding: 60px;
}
.wrapper {
  width: 266px;
  height: 266px;
  box-sizing: border-box;
  border: 8px solid #78c7ff;
  border-radius: 50%;
  display: flex;
  align-items: center;
  justify-content: center;
  position: relative;

  .wrapper-box {
    position: absolute;
    //width: 100%;
    .wrapper-num {
      width: 56px;
      height: 56px;
      background: #78c7ff;
      border-radius: 50%;
      color: #fff;
      font-size: 16px;
      font-weight: 700;
      text-align: center;
      line-height: 56px;
    }
  }

  .wrapper-box:nth-of-type(1) {
    top: -32px;

    .icon {
      transform: rotateZ(13deg);
      top: 13px;
      left: -67px;
    }
  }

  .wrapper-box:nth-of-type(2) {
    top: 50px;
    left: -20px;

    .icon {
      transform: rotateZ(303deg);
      top: 61px;
      left: -16px;
    }

    .wrapper-num-text {
      text-align: right;
      left: -335px;
    }
  }

  .wrapper-box:nth-of-type(3) {
    top: 190px;
    left: 10px;

    .icon {
      transform: rotateZ(233deg);
      top: 31px;
      left: 70px;
    }

    .wrapper-num-text {
      text-align: right;
      left: -335px;
    }
  }

  .wrapper-box:nth-of-type(4) {
    .icon {
      transform: rotateZ(155deg);
      top: -54px;
      left: 28px;
    }

    top: 190px;
    right: 10px;
  }

  .wrapper-box:nth-of-type(5) {
    .icon {
      transform: rotateZ(90deg);
      top: -53px;
      left: -41px;
    }

    top: 50px;
    right: -20px;
  }

  .wrapper-title {
    font-size: 16px;
    font-weight: 700;
  }
}

.wrapper-num-text {
  position: absolute;
  width: 308px;
  color: #333;
  top: -10px;
  left: 80px;
}

.wrapper-num-title {
  font-size: 14px;
  font-weight: 700;
}

.wrapper-text {
  font-size: 15px;
  font-weight: 400;
}

.icon {
  position: absolute;
  top: 0;
  left: 0;
}

.icon-zhijiao {
  font-size: 40px;
  font-weight: bold;
}
</style>