<template>
  <div>
    <div class="wrapper">
      <div v-for="(item,index) in auditTrail" :key="index" class="lin-wrapper">
        <div>
          <img :src="item.icon" class="lin-icon" style="width: 52px">
        </div>
        <div>
          <p class="lin-title">{{ item.title }}</p>
          <p>{{ item.p }}</p>
        </div>
      </div>
    </div>
    <div style="display: flex;justify-content: center;margin-top: 40px;gap: 30px">
      <div v-for="(item,index) in sfaSaleTwoImg">
        <img :src="item" style="width: 240px">
      </div>
    </div>
  </div>
</template>

<script>
export default {
  name: "sfaROI",
  props: {
    auditTrail: {
      type: Array,
    },
    sfaSaleTwoImg: {
      type: Array,
    }
  },
}
</script>

<style scoped lang="less">
.wrapper {
  display: flex;
  gap: 30px;
  justify-content: center;

  .lin-wrapper {
    display: flex;
    gap: 10px;
    width: 210px;
  }

  .lin-title {
    font-size: 18px;
    color: #0D36A8;
    margin-bottom: 4px;
  }
  .lin-title~p{
    font-size: 13px;
    color: #666;
  }
}
</style>