<template>
  <div class="wrapper w100">
    <div class="w1100">
      <!--      现有痛点-->
      <h3 style="text-align: center;font-size: 18px;margin-bottom: 28px">KMS商超管理系统 - 现有痛点</h3>
      <div class="pain-lin">
        <div class="pain-lin-box" v-for="(item,index) in painList" :key="index">
          <div class="pain-lin-title">
            <div v-for="(i,j) in item.title">{{ i }}</div>
          </div>
          <p style="line-height: 20px;font-size: 14px;font-weight: 549">{{ item.p }}</p>
        </div>
      </div>
      <div style="margin: 0 auto 35px auto;width: 1000px; border-bottom: 2px dashed #bbb"></div>
      <!--      系统目标-->
      <h3 style="text-align: center;font-size: 18px;margin-bottom: 15px">系统目标</h3>
      <div class="pain-lin" style="justify-content: center;gap:27px ">
        <div class="target-box" v-for="(item,index) in targetList" :key="index">
          <div class="target-title">{{ item.title }}</div>
          <div class="target-text">
            <div v-for="(i,index) in item.p" :key="index">
              <p>{{ i }}</p>
            </div>
          </div>
        </div>
      </div>
    </div>
    <div style="background: #f3f6fb">
      <!--查看-->
      <div style="width: 1020px;margin: 0 auto;padding-top: 74px">
        <div class="lin-see">
          <div class="lin-see-box" v-for="(item,index) in seeList" :key="index">
            <p class="lin-see-title">{{ item.title }}</p>
            <div v-for="(i,dx) in item.p" :key="dx">
              <p class="lin-see-text">{{ i }}</p>
            </div>
          </div>
        </div>
        <img src="../../../../public/image/KMS/1.png" width="100%">
      </div>
      <!--      订单检查-->
      <div style="width: 1020px;margin: 0 auto;padding-top: 32px">
        <h3 style="text-align: center;font-size: 18px;padding-bottom: 10px">订单检查</h3>
        <p style="text-align: center;font-size: 14px">订单价格，库存，最小起订量，配额等检查</p>
        <k-m-smodule :inspectList="inspectList"></k-m-smodule>
      </div>
      <!--      KMS直营管理系统业务流程-->
      <div style="width: 1020px;margin: 0 auto;padding:32px 0 160px 0">
        <h3 style="text-align: center;font-size: 18px;padding-bottom: 105px">KMS直营管理系统业务流程</h3>
        <div class="flex" style="justify-content: center;gap:34px">
          <div class="process-box" v-for="(item,index) in processList" :key="index">
            <div>
              <p class="process-title">{{ item.title }}</p>
              <i class="iconfont jiantou"
                 :class="[item.icon == 1 ? 'icon-xiashuangjiantou' : (item.icon == 2 ? 'icon-shangshuangjiantou' : '')]"></i>
              <div class="process-text">
                <div v-for="(dxitem,dxindex) in item.text" :key="dxindex">
                  <p>{{ dxitem }}</p>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      <!--      商超应收付费自动对账-->
      <div style="width: 1020px;margin: 0 auto;padding-top: 40px;padding-bottom: 74px">
        <h3 style="text-align: center;font-size: 18px;padding-bottom: 20px">商超应收付费自动对账</h3>
        <div class="flex" style="margin-bottom: 20px">
          <h4 style="margin: 0px 33px">外部系统</h4>
          <h4 style="margin: 0px 184px">对账系统</h4>
          <h4 style="margin: 0px 160px">企业ERP</h4>
        </div>
        <!--        外部系统-->
        <div class="flex" style="height: 428px;gap: 66px;justify-content: center">
          <div class="fdc" style="position: relative">
            <div class="payBox" style="width: 80px" v-for="(item,index) in payList.external" :key="index">{{
                item
              }}
            </div>
            <div class="fenzhi">
              <div class="hd-branch">
                <div class="branch-lin" style="height: 396px">
                  <div class="branch-lin-left"></div>
                </div>
              </div>
            </div>
          </div>
          <!--          对账系统-->
          <div class="flex" style="gap: 58px">
            <div class="fdc">
              <div class="payBox" style="flex-direction: column;width: 155px;font-size: 12px"
                   v-for="(item,index) in payList.reconc.left" :key="index">
                <div v-for="(dxitem,dxindex) in item.text" :key="dxindex">{{ dxitem }}</div>
              </div>
            </div>
            <div class="fdc" style="position: relative">
              <div class="payBox fahuo" style="width: 80px" v-for="(dxitem,dxindex) in payList.reconc.right"
                   :key="dxindex">{{ dxitem }}
                <i class="iconfont icon-info"
                   :class="[dxindex != 0 ? (dxindex%2 == 0 ? 'icon-arrowRight' : 'icon-arrowLeft-fill') : '']"></i>
              </div>
              <div class="fenzhi" style="transform: rotateY(0deg);left: 94px">
                <div class="hd-branch">
                  <div class="branch-lin" style="height: 396px">
                    <div class="branch-lin-left"></div>
                  </div>
                </div>
              </div>
            </div>
          </div>
          <!--        企业ERP-->
          <div class="flex" style="gap: 20px">
            <div class="fdc">
              <div class="payBox" style="width:120px" v-for="(item,index) in payList.ERP.bill" :key="index">
                {{ item }}
              </div>
            </div>
            <div class="qierp" style="height:428px">
              <p>{{ payList.ERP.general }}</p>
            </div>
            <div class="qierp" style="height:428px">
              <p>{{ payList.ERP.capital }}</p>
            </div>
            <div>
              <div class="qierp" style="width: 155px;height: 44px;background: #0d36a8;">
                <div>{{ payList.ERP.value.title }}</div>
              </div>
              <div class="qierp"
                   style="width: 155px;height:384px;padding:0 14px 0 14px;flex-direction: column;gap: 24px">
                <div v-for="(item,index) in payList.ERP.value.text" :key="index">{{ item }}</div>
              </div>
            </div>
          </div>
        </div>
        <!--         订单自动整合处理 -->
        <div style="width: 1020px;margin: 0 auto;padding-top: 57px">
          <h3 style="text-align: center;font-size: 18px;margin-bottom: 15px">订单自动整合处理</h3>
          <handle-k-m-s :dataList="handleList"></handle-k-m-s>
        </div>
        <div style="margin: 0  auto;width: 1000px; padding-top: 54px;border-bottom: 2px dashed #bbb"></div>
        <!--         收发货核对及差异核销 -->
        <div style="width: 1020px;margin: 0 auto;padding-top: 28px">
          <h3 style="text-align: center;font-size: 18px;margin-bottom: 15px">收发货核对及差异核销</h3>
          <handle-k-m-s :dataList="writeOffList"></handle-k-m-s>
        </div>
      </div>
    </div>
    <div class="w1100">
      <!--      KMS-->
      <div class="w1100">
        <h3 style="text-align: center;font-size: 18px;padding-top: 34px;margin-bottom: 15px">KMS</h3>
        <el-menu mode="horizontal" :default-active="activeIndex" class=" el-menu"  text-color="#333"  active-text-color="#0D36A8" @select="handleSelect">
          <el-menu-item  v-for="(item,index) in KMSlist" :key="index" :index="index" style="font-size: 16px;" >
            {{ item }}
          </el-menu-item>
        </el-menu>
        <div v-if="activeIndex===0">
          <div class="pain-lin" style="justify-content: center;gap:27px ">
            <div class="target-box" v-for="(item,index) in KMSBasics" :key="index">
              <div class="target-title">{{ item.title }}</div>
              <div class="target-text" style="height: 102px">
                <div v-for="(i,index) in item.text" :key="index">
                  <p>{{ i }}</p>
                </div>
              </div>
            </div>
          </div>
        </div>
        <div v-if="activeIndex===1">
          <div class="flex" style="gap: 31px;align-items: center">
            <div class="orderBox">订单功能</div>
            <div>
              <div class="handleBox" v-for="(item,index) in KMShandle" :key="index">
                <div class="handle-top">{{item.title}}</div>
                <div class="handle-bot">{{item.text}}</div>
              </div>
            </div>
            <div>
              <img src="../../../../public/image/KMS/箭头.jpg">
            </div>
            <div class="orderBox">处理收益</div>
            <div>
              <div class="textBox" v-for="(item,index) in KMStext" :key="index">
                <div class="text-top">
                  <p v-for="(dxitem,dxindex) in item.list">{{dxitem}}</p>
                </div>
                <div class="text-bot">
                  <p>{{item.text}}</p>
                </div>
              </div>
            </div>
            <div class="voBox">
              <div class="voBox-top">
                <p>{{KMSVo.title}}</p>
              </div>
              <div class="voBox-bot">
                <div class="voBox-bot-p" v-for="(item,index) in KMSVo.list">
                  <p>{{item}}</p>
                </div>
              </div>
            </div>
          </div>
        </div>
        <div v-if="activeIndex===2">
          <div class="flex" style="justify-content: center;gap: 65px">
            <div class="pos-left">{{POSList.leftTitle}}</div>
            <div>
              <div class="pos-right" v-for="(item,index) in POSList.textVo" :key="index">
                <div class="pos-right-top">{{item.title}}</div>
                <div class="pos-right-bot">
                  <div v-for="(dxitem,dxindex) in item.text" :key="dxindex">
                    <p>{{dxitem}}</p>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
        <div v-if="activeIndex===3">
          <div class="flex" style="justify-content: center;gap: 65px">
            <div class="pos-left">{{accountList.leftTitle}}</div>
            <div>
              <div class="pos-right account" v-for="(item,index) in accountList.textVo" :key="index">
                <div class="pos-right-top">{{item.title}}</div>
                <div class="pos-right-bot">
                  <div v-for="(dxitem,dxindex) in item.text" :key="dxindex">
                    <p>{{dxitem}}</p>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
        <div v-if="activeIndex===4">
          <div class="flex" style="flex-wrap: wrap;justify-content: center;row-gap: 60px">
            <div class="K-l-j" v-for="(item,index) in profitList" :key="index">
              <img style="margin-bottom: 10px" :src="item.url">
              <div  v-for="(dxitem,dxindex) in item.text">
                <p style="line-height: 1.75rem">{{dxitem}}</p>
              </div>
            </div>
          </div>
        </div>
        <div v-if="activeIndex===5">
          <div class="bigData">
            <div class="bigData-wrapper" v-for="(item,index) in bigDataList" :key="index">
              <div class="bigData-sBox">{{item.title}}</div>
              <div class="bigData-lin">{{item.text}}</div>
            </div>
            <div class="bigData-bg">
              <img src="../../../../public/image/KMS/shujuBG.jpg">
            </div>
            <div class="bigData-bg" style="top: 93px">
              <img src="../../../../public/image/KMS/banyuan.jpg">
            </div>
            <div class="bigData-bg">
              <img src="../../../../public/image/KMS/centerBG.jpg">
            </div>
            <p class="bigData-bg bigData-cn">大数据</p>
            <p class="bigData-bg bigData-bt" style="top: 192px">分析服务</p>

          </div>
        </div>
        <div v-if="activeIndex===6">
          <div class="flex" style="flex-wrap: wrap;justify-content: center;row-gap: 60px">
            <div class="K-l-j" v-for="(item,index) in significanceList" :key="index">
              <img style="margin-bottom: 10px" :src="item.url">
              <div  v-for="(dxitem,dxindex) in item.text">
                <p style="line-height: 1.75rem">{{dxitem}}</p>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
    <div class="w100" style="margin-top: 250px">
      <div class="button-wrapper" style="margin-bottom: 105px">
        <mu-button large color="primary">即刻体验</mu-button>
      </div>
    </div>
  </div>
</template>

<script>
import KMSmodule from './modules/KMSmodule'
import handleKMS from "@/views/duct/ductKMS/modules/handleKMS";

export default {
  name: "index",
  components: {
    KMSmodule,
    handleKMS
  },
  data() {
    return {
      activeIndex: 0,
      //痛点
      painList: [
        {
          title: ['工作量大', '效率低'],
          p: '目前全国性KA卖场有近20家，拥有近4000家门店；无法实时发现新订单手工工作量大、效率低'
        },
        {
          title: ['人工录入', '风险高'],
          p: '单据处理工作人员手工将单据录入企业ERP系统。人工录入数据错误风险较高'
        },
        {
          title: ['操作繁琐', '易遗漏'],
          p: '单据处理工作人员手工一张张下载单据，并进行汇总处理。操作繁琐，容易遗漏'
        },
        {
          title: ['耗费人工', '成本高'],
          p: '异常单据需要人工进行核对整理。汇总沟通成本高，耗费人工且处理效率低'
        },
      ],
      //  系统目标
      targetList: [
        {
          title: '打造KMS管理系统',
          p: ['将直营业务固化，工作流程放在平台统一进行管理。例如主数据维护，避免后期新增、变更操作失误，降低工作交接风险，减少人员工作量']
        },
        {
          title: '业务在线处理',
          p: ['1、实现单据在线处理，系统自动下载单据及数据转换，减少人工操作', '2、自动标记单据差异，提高单据处理效率 ', '3、自动出具对账差异表，提高对账效率', ' 4、实现单据在线批审，提高单据审核效率']
        },
        {
          title: '整合数据资源',
          p: ['将各系统数据整合，及时提供销售数据、促销活动费用、预估损益、库存预警等数据分析报表']
        },
      ],
      //  查看
      seeList: [
        {
          title: '业务数据随时随地查看',
          p: ['小程序将把KMS系统内的业务数据同步到移动端 包括订货单、销售数据、结算数据等单据及业务报表展示，随时掌握您关注的数据情况']
        },
        {
          title: '更加及时的通知',
          p: ['推送新增单据及审批其他消息通知 更⽅便的通知查看，不会遗漏重要的信息']
        },
        {
          title: '全面的报表查看',
          p: ['·将KMS上所有的报表全部适配移动端', '·⽅便随时随地查看运营指标及趋势等可视化图表']
        },
        {
          title: '单据查看',
          p: ['包含订货单、验收单、销售数据、库存数据、退货数据等']
        },
        {
          title: '业务报表查看',
          p: ['包含综合信息、订货数据报表、销售数据报表等']
        },
      ],
      //  订单检查
      inspectList: {
        inspect: '订单闭环管理',
        inspectVo: [
          {
            title: '订单检查',
            text: ['订单价格，库存，最小起订量，配额等检查']
          },
          {
            title: '订单审批',
            text: ['订单内部审批在EDI平台上完成']
          },
          {
            title: '订单对接',
            text: ['订单按照ERP导入格式下载后，导入ERP', '订单与ERP通过接口自动对接订单']
          },
          {
            title: '订单状态跟踪',
            text: ['持续更新订单状态，', '例如审批中， 已确认， 已发货，已收货等']
          },
          {
            title: '收发货自动比对',
            text: ['自动比对订单发货与收货的差异， 给出提示']
          },
        ]
      },
      //KMS直营管理系统业务流程
      processList: [
        {
          title: '商超下单',
        },
        {
          title: '直营订单确认',
          text: ['下单形式', '确认订单过程', '订单促销政策的维护、审核、管控'],
          icon: 1
        },
        {
          title: '物流发货',
          text: ['订单发货', '订单满足率'],
          icon: 2
        },
        {
          title: '销售额确认',
          text: ['发货与验收差异', '销售额的确认、核对', '退货单的处理', '折让单、票扣等费用处理', '各类销售分析'],
          icon: 1
        },
        {
          title: '对账开票',
          text: ['按账期对账并开票'],
          icon: 2
        },
      ],
      //商超应收付费自动对账
      payList: {
        external: ['订单', '验收单', '结算单', '费用', '付款'],
        reconc: {
          left: [
            {
              text: ['下载商城单据']
            },
            {
              text: ['比对商超收货', '（原订单号、数量、金额）']
            },
            {
              text: ['差异提示']
            },
            {
              text: ['对比商超票扣 ', '（促销折让、金额）']
            },
            {
              text: ['差异提示']
            },
            {
              text: ['比对商超账扣', '（账扣费用明细、金额）']
            },
            {
              text: ['差异提示']
            },
          ],
          right: [' ', '发货', '核销差异', '票折', '核销差异', '账扣', '核销差异']
        },
        ERP: {
          bill: ['发货单', '结算票扣', '结算账扣', '发票回款'],
          general: '总账管理',
          capital: '资金池管理',
          value: {
            title: '应用价值',
            text: ['1、实现线下商超收发货商品应收对账', '2、实现线下商超超结算费用的应付对账', '3、实现线下商超结算的实收对账及回款进度跟进']
          }
        }

      },
      handleList: [
        {
          title: "自动",
          lin: '订单自动采集入ERP',
          p: '从内部优化订单审核流程，将各商超原订单入ERP后的审单形成标准、规范的审核处理流程，为后续的供货、财务对账建立数据基础。'
        },
        {
          title: "映射",
          lin: '产品映射是核单的关键',
          p: '订单整单核对关键字为商超原订单号，单品核对关键字为单品SKU编码的映射关系。商超与企业的产品主数据之间的映射关系是转化处理的关键。'
        },
        {
          title: "审单",
          lin: '商超订单是财务对账结算的数据源',
          p: '从内部优化订单审核流程，将各商超原订单入ERP后的审单形成标准、规范的审核处理流程，为后续的供货、财务对账建立数据基础。'
        },
        {
          title: "输出",
          lin: '订单处理状态',
          p: '订单后续处理的发货作为订单处理关闭的第一状态，在发货单产生时，即可认为订单已经进入处理，不再自动更新ERP中订单数据。'
        },
      ],
      writeOffList: [
        {
          title: "自动",
          lin: '收发货单据自动采集和对接',
          p: '从商超系统中自动采集收货单，同时对接企业的发货单，当来源单据有变化时，重新采集并更新触发后续自动对账。'
        },
        {
          title: "映射",
          lin: '差异报告及调账重新核对',
          p: '自动核对发现差异，作差异标记，支持差异报告生成。当ERP发货调账后，系统自动同步发货调账数据，重新触发自动核对。系统对确认的差异单据不再进行自动核对。'
        },
        {
          title: "审单",
          lin: '系统自动核对',
          p: '以商超验收单中的订单号，以及企业发货单中的原订单号，作为自动核对关键字，通过产品映射关系，核对整单及单品SKU的金额和数量是否一致，允许产品单价存在尾差差异。'
        },
        {
          title: "输出",
          lin: '对账报告及单据状态更新',
          p: '自动核对并确认后，将订单标记为已对账，同时获取结算单中存在的验单标记为已结算，不再触发自动核对。'
        },
      ],
      KMSlist: ['基础功能', '订单处理功能', 'POS/库存处理', '对账功能', '系统收益', '大数据服务', '实施意义'],
      KMSBasics:[
        {
          title:'角色、权限管理',
          text:['自行新建多个角色，灵活分配模板权限，并能按部门组织分配数据权限'],
        },
        {
          title:'主数据管理',
          text:['1.组织、人员、产品、门店主数据',' 2.页面维护、批量导入。多组织扩展，可支持经销商体系使用。'],
        },
        {
          title:'特殊配置',
          text:['商品单位描述不一致或非等量关系等特殊转换配置，售达方和送达方关系区分。'],
        },
      ],
      KMShandle:[
        {
          title:"网站单据展现",
          text: "自行新建多个角色，灵活分配模板权限，并能按部门组织分配数据权限"
        },
        {
          title:"订单处理机制",
          text: "订单内容检查（编号、规格、价格….）重复订单过滤（对比过滤，可设置规则）特殊业务订单处理（赠品、紧急订单等）订单合并/拆分（门店下单，送统仓订单等）捆绑装拆分（捆绑规格与单品的关系建立）单价价格差异标记、快速处理差异单据"
        },
        {
          title:"对接企业ERP",
          text: "将订单转换成企业格式，直接进入ERP生成预订单，审核后成正式交货订单"
        },
      ],
      KMStext:[
        {
          list:['人工下载、录入，','费时费力，出错率高'],
          text: 'KMS系统下载订单自动转入SAP，订单员仅需在SAP审核过账'
        },
        {
          list:['原始订单需多方人员登录打印','（业务、订单员、物流）'],
          text: 'KMS不需到客户网站下载，通过平台打印原始订单，随货同行'
        },
        {
          list:['纯属人工熟悉度的判断处理，工作量大，费时费力，易出错，影响订单满足率'],
          text: 'KMS系统代替人工对订单进行检查、拆分、分配与转换，将转换异常的订单进行异常分类，方便订单员或业务有针对性、快速处理'
        },
      ],
      KMSVo:{
        title:"每月月以20个 NKA为列",
        list:['每月节省60~80%订单下载与录入时间','订单录入准确率达100%','每月节省60~80%订单检查处理时间','提升订单满足率25%'],
      },
      POSList:{
        leftTitle:"POS实销/库存",
        textVo:[
          {
            title:'数据整理',
            text:['数据验证、数据过滤、数据映射、数据计算真实时间呈现','每日、每店、每个单品的实际销量及库存']
          },
          {
            title:'数据仓储的搭建',
            text:['统一数据源格式','分类汇整，建立相关资料库','搭建多维度动态数据库']
          },
          {
            title:'统计、分析报表的生成',
            text:['各数据明细列表的筛选查看','固定报表、分析报告的呈现与推送','多维度的动态报表呈现，可自由组合和分析',' 图表的展示']
          },
        ]
      },
      accountList:{
        leftTitle:"对账功能",
        textVo:[
          {
            title:'网站单据展现',
            text:['收货单、退货单、对账单、结算单等原始单据存储、查询']
          },
          {
            title:'货款核对',
            text:['根据订单、送货单、收货单相关单据关连',' 进行一一单据匹配、品项匹配、数量对比、价格对比、金额对比','及时处理货款差异']
          },
          {
            title:'月度对账',
            text:['货款+费用（零售网站下载或用户自行录入）',' 每月已结算、未结算单据明细查询、导出']
          },
        ]
      },
      profitList:[
        {
          url: require('../../../../public/image/KMS/dz.jpg'),
          text:['对账处理自动化，取代大量人工作业，','节省人力成本']
        },
        {
          url: require('../../../../public/image/KMS/xl.jpg'),
          text:['确保货款核对及时、准确、完整，','提升作业质量与效率']
        },
        {
          url: require('../../../../public/image/KMS/sd.jpg'),
          text:['缩短开票作业流程，提高回款速度']
        },
        {
          url: require('../../../../public/image/KMS/ls.jpg'),
          text:['多个零售客户对账作业同时处理，','且作为对账依据']
        },
        {
          url: require('../../../../public/image/KMS/mq.jpg'),
          text:['明确核对差异，追踪处理，减少',' 呆账产生']
        },
        {
          url: require('../../../../public/image/KMS/gk.jpg'),
          text:['进一步管控卖场业务费用']
        },
      ],
      bigDataList:[
        {
          title:"销售力",
          text:'掌控各品项在全国、区域、业态、渠道、终端等的销售表现，进而调整产品策略'
        },
        {
          title:"销售预估",
          text:'更贴近市场的制定销售预估，让整个供应链达到最大效益'
        },
        {
          title:"经营回顾",
          text:'更精准的经营分析与回顾，提升对零售商的管理及服务水平'
        },
        {
          title:"消费行为",
          text:'及时掌握终端门店的数据，就等于掌握了全国市场的状况，及消费者的行为'
        },
        {
          title:"促销效率",
          text:'为各种促销活动方案制订与效益分析提供有力数据支持'
        },
        {
          title:"铺市率",
          text:'清楚了解新品、重点品项的实销与铺市情况，进行相应调整改善。'
        },
        {
          title:"订单满足",
          text:'呈现真实的订单满足率，并可制定KPI考核、分析总结'
        },
        {
          title:"现场表现",
          text:'掌控零售商终端门店库存状况（正常/缺货/滞销），立刻采取行动'
        },

      ],
      significanceList:[
        {
          url: require('../../../../public/image/KMS/yw.jpg'),
          text:['直营业务流程标准化']
        },
        {
          url: require('../../../../public/image/KMS/jy.jpg'),
          text:['为经营分析提供数据支撑']
        },
        {
          url: require('../../../../public/image/KMS/md.jpg'),
          text:['提升门店价格管控力度']
        },
        {
          url: require('../../../../public/image/KMS/kc.jpg'),
          text:['保障门店安全库存']
        },
        {
          url: require('../../../../public/image/KMS/tg.jpg'),
          text:['减少人工处理，降低错误率，提高效率']
        },
        {
          url: require('../../../../public/image/KMS/xl.jpg'),
          text:['对账更清晰，更及时']
        },
      ]
    }
  },
  methods:{
    handleSelect(key) {
      console.log(key);
      this.activeIndex = key
    }
  }
}
</script>

<style scoped lang="less">
@import "./ductKMS.less";
</style>
