<template>
  <div>
<div class="w100" v-if="index===0">
  <div class="banner"></div>
  <div class="w1100">
    <div class="join-title"><i class="iconfont icon-baojiaquotation2"></i><span>招聘职位</span></div>
    <p style="font-size: 20px;margin-bottom: 60px">Recruitment Position</p>
    <div style="padding-bottom: 50px">
      <div class="flex" style="gap: 60px" v-for="(item,index) in workList" :key="index">
        <div class="flex wrapper-job-box">
          <img style="width: 280px;height: 160px" :src="item.url">
          <div class="wrapper-job-text">
            <p class="w-j-t-p">{{item.job}}</p>
            <div class="flex" style="align-items: center">
              <p>共2个职位</p>
              <img style="width: 16px;height: 16px" src="../../../public/image/join/rt.webp">
            </div>
          </div>
        </div>
        <div style="margin-top: 12px">
          <div v-for="(dxitem,dxindex) in item.list">
            <div class="wrapper-work-title" @click="jobTo(1)">{{dxitem}}</div>
            <div class="wrapper-work">
              <p>社会招聘</p>
              <p>全职</p>
              <div>
                <img src="../../../public/image/join/dw.webp">
                <span style="vertical-align: middle;margin-left: 4px">成都</span>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>


</div>
  <details-work v-if="index===1"></details-work>
  </div>
</template>

<script>
import detailsWork from "@/views/JoinUs/detailsWork";

export default {
  name: "index.vue",
  components:{
    detailsWork
  },
  props:{
    index:Number,
  },
  data(){
    return{
      index:0,
      workList:[
        {
          url:require('../../../public/image/join/cp.webp'),
          job:"产品",
          list:['产品经理-成都','产品实习-成都']
        },
        {
          url:require('../../../public/image/join/yf.webp'),
          job:"研发",
          list:['JAVA开发工程师-成都','Web前段工程师-成都']
        },
        {
          url:require('../../../public/image/join/ui.webp'),
          job:"UI设计",
          list:['UI设计师-成都','无职位']
        },
      ]
    }
  },
  methods:{
    jobTo(index){
      this.index=index
      // this.$router.push({path: '/detailsWork'})
    }
  },
}
</script>

<style scoped lang="less">
@import "JoinUs";
</style>
